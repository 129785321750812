import React from "react";

import { IElement } from "../../utils/interfaces";
import AdvanceOptionsDrawer from "./ElementPreview/AdvanceOptionsDrawer";

interface IProps {
  value?: string;
  placeholder?: string;
  style?: object;
  max_length?: number;
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  dispatch: ({ type, payload }: { type: string; payload: object }) => void;
  state: IElement;
  componentDisabled?: boolean;
  updateFieldValue: (fieldValue: object) => void;
  deleteButton: React.ReactNode;
  extraProps: React.ReactNode;
}

export default function CTVideo(props: IProps) {
  const advancedOptions = <></>;
  if (!props.state.plugin_data) return null;
  return (
    <AdvanceOptionsDrawer
      componentDisabled={props.componentDisabled}
      deleteButton={props.deleteButton}
      advancedOptions={advancedOptions}
      state={props.state}
      extraProps={props.extraProps}
    />
  );
}
