import React, { useEffect, useState } from "react";

import { Button, Drawer, Layout, message, Space, Typography } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import axios from "axios";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

import AntSpinner from "../../../shared/customComponents/spinner/AntSpinner";
import FormMailSettings from "./FormMailSettings";
import FormMailSubmissionSettings from "./FormMailSubmissionSettings";
import FormProofs from "./FormProofs";
import { StateType } from "../../core/redux/types";
import FormVisibility from "../forms/FormVisibility";
import FormLayoutSettings from "./FormLayoutSettings";
import WebhookSettings from "./WebhookSettings";

const FormSettings = (props: any) => {
  const [loading, setLoading] = useState(true);

  const [showMenu, setShowMenu] = useState(false);

  const [currentIndex, setCurrentIndex] = useState(0);

  const [emailFields, setEmailFields] = useState();

  const [handlerData, setHandlerData] = useState();

  const [formData, setFormData] = useState<any>(null);
  const [layout, setLayout] = React.useState(
    formData?.metadata?.layout || "classic"
  );

  const params = useParams<any>();

  const { uuid } = params;

  const getFormByUuid = async (setLoading: any) => {
    setLoading(true);

    const url = props.state.auth.userData.is_staff
      ? `${process.env.REACT_APP_SERVER_URL}/api/templates/${uuid}/`
      : `${process.env.REACT_APP_SERVER_URL}/api/custom_forms/${uuid}/`;

    await axios
      .get(url)
      .then(async (res) => {
        console.log("---- res get form by uuid ---- ", res);
        setFormData(res.data);
        setLayout(res.data.metadata?.layout || "classic");
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Could not get Form data.");
        setLoading(false);
      });
  };

  const getFormHandlers = async () => {
    setLoading(true);

    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/${uuid}/handlers/`)
      .then((res) => {
        setHandlerData(res.data);
        getEmailElementsFromApi();
        setLoading(false);
      })
      .catch((err: any) => {
        console.log("Could not get handler data.", err);
        setLoading(false);
        message.error("Could not get handler data.");
      });
  };

  const getEmailElementsFromApi = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/api/${uuid}/elements/?plugin_uid=email`
      )
      .then((res) => {
        setEmailFields(res.data.data);
        setLoading(false);
      })
      .catch((err: any) => {
        console.log("Could not get email elements.", err);
        setLoading(false);
        message.error("Could not get email elements.");
      });
  };

  const createFormHandlers = async (handlerData: any) => {
    setLoading(true);

    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/${uuid}/handlers/`,
        handlerData
      )
      .then((res) => {
        console.log("---- res ---- ", res);
        setLoading(false);
        getFormHandlers();
        message.success("Form handlers updated successfully.");
      })
      .catch((err: any) => {
        console.log("Form handler update failed", err);
        setLoading(false);
        message.error("Form handlers update failed.");
      });
  };

  useEffect(() => {
    getFormHandlers();
    getFormByUuid(setLoading);
  }, []);

  if (loading || !formData) {
    return <AntSpinner />;
  }
  const menuContents = [
    <FormVisibility />,
    <FormMailSettings
      createFormHandlers={createFormHandlers}
      handlerData={handlerData}
    />,
    <FormMailSubmissionSettings
      emailFields={emailFields}
      createFormHandlers={createFormHandlers}
      handlerData={handlerData}
    />,
    formData && <FormProofs addProofs={formData.add_proofs} />,
    <FormLayoutSettings uuid={uuid} layout={layout} setLayout={setLayout} />,
    <WebhookSettings
      createFormHandlers={createFormHandlers} handlerData={handlerData}
    />
  ];
  const isMobileView = window.innerWidth < 1200;

  const menu = (
    <Space direction="vertical" size={1}>
      {[
        {
          title: "Visibility",
          subtitle: "Control who can see the data",
        },
        {
          title: "Receive Email",
          subtitle: "Setup email notifications to receive response in mail",
        },
        {
          title: "Send Email",
          subtitle: "Setup email notifications to send response in mail",
        },
        {
          title: "Data Privacy",
          subtitle: "Add your own terms and condition and privacy policy",
        },
        {
          title: "Layout",
          subtitle: "Choose layout  style ",
        },
        {
          title: "Webhook",
          subtitle: "Add your own custom callback function",
        },
      ].map(({ title, subtitle }, index: number) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: 20,
              marginTop: index === 0 ? 25 : 0,
              marginLeft: showMenu ? 0 : 20,
            }}
            onClick={() => {
              setCurrentIndex(index);
              getFormByUuid(setLoading);
              setLoading(false);
            }}
          >
            <Typography.Text
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "30px",
                marginBottom: 0,
                paddingBottom: 0,
                lineHeight: "30px",
                color: index === currentIndex ? "#FF5530" : "#FFFFFF",
              }}
            >
              {title}
            </Typography.Text>
            <div
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontSize: "20px",
                color: "#FFFFFF",
              }}
            >
              {subtitle}
            </div>
          </div>
        );
      })}
    </Space>
  );

  return (
    <Layout>
      {isMobileView ? (
        <>
          <Button
            size="small"
            type="primary"
            style={{ margin: "0px 10px" }}
            onClick={() => setShowMenu(!showMenu)}
          >
            <MenuOutlined />
          </Button>
          <Drawer
            drawerStyle={{
              background:
                "linear-gradient(180deg, rgba(32, 38, 43, 0.8) 0%, #20262B 100%)",
              border: "1px solid rgba(255, 255, 255, 0.1)",
              boxSizing: "border-box",
              backdropFilter: "blur(16px)",
              padding: 10,
              height: "100%",
            }}
            placement="left"
            visible={showMenu}
            onClose={() => setShowMenu(false)}
          >
            {menu}
          </Drawer>
        </>
      ) : (
        <Layout.Sider
          style={{
            textAlign: "left",
            background:
              "linear-gradient(180deg, rgba(32, 38, 43, 0.8) 0%, #20262B 100%)",
            border: "1px solid rgba(255, 255, 255, 0.1)",
            boxSizing: "border-box",
            backdropFilter: "blur(16px)",
            borderRadius: "12px",
            padding: 10,
            height: "100%",
          }}
          width={300}
        >
          {menu}
        </Layout.Sider>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div style={{ width: "700px" }}>{menuContents[currentIndex]}</div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(FormSettings);
