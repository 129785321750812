import React, { useState, useEffect } from "react";

import { Input, message, Spin, Button, Space, Image } from "antd";
import axios from "axios";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

import { connect } from "react-redux";

import { StateType } from "../../core/redux/types";
import { GetUserData } from "../../../auth/core/redux/actions";
import { FALLBACK_IMAGE } from "../../../shared/constants/constants";

const UploadLogo = (props: any) => {
  const { getUserData } = props;

  const userData = props.state?.auth?.userData;

  const [logoUrl, setLogoUrl] = useState<null | string>(null);

  useEffect(() => {
    if (
      userData &&
      userData.profile_picture &&
      userData.profile_picture.thumbnail_medium
    ) {
      setLogoUrl(userData.profile_picture.thumbnail_medium);
    }
  }, [props]);

  const [loading, setLoading] = useState(false);

  const [selectedFile, setSelectedFile] = useState<any>(null);

  const onFileChange = (e: any) => {
    if (e.target.files.length > 0) {
      if (e.target.files[0].type.match("image")) {
        const fileSize = e.target.files[0].size;

        if (fileSize > 5000000) {
          message.error("Max Image size 5MB allowed.");
        } else {
          setSelectedFile(e.target.files[0]);
          onFileUpload(e.target.files[0]);
          setLogoUrl(URL.createObjectURL(e.target.files[0]));
        }
      } else {
        message.error("This file type is not supported.");
      }
    } else {
      message.error("Please select a file to upload");
    }
  };

  const attachHeaders = async (data: any) => {
    const formData = new FormData();

    Object.entries(data).forEach((dataItem: any) => {
      formData.append(dataItem[0], dataItem[1]);
    });

    return formData;
  };

  const onFileUpload = async (file: any) => {
    const selectedFile = file;

    if (selectedFile) {
      const file = selectedFile.name !== "" ? selectedFile : "  ";
      const fileName = selectedFile.name !== "" ? selectedFile.name : "  ";

      const extenstion = selectedFile.name
        .substr(selectedFile.name.lastIndexOf(".") + 1)
        .split(".")[0];

      const keys = await getAWSTokenForLogoUpload(extenstion);

      const data = await keys.fields;

      const key = data.key;

      const formData: FormData = await attachHeaders(data);
      formData.append("file", file);

      if (formData) {
        const result = await uploadFileToAWS(keys.url, await formData, key);

        console.log("Result from AWS -----> ", result);
      }

      const data2 = await updateServerWithLogoUploadData(key);

      console.log("data 2 --> ", data2);
    } else {
      message.error("No image selected.");
    }
  };

  const getAWSTokenForLogoUpload = async (extenstion: string) => {
    setLoading(true);

    let result;

    try {
      result = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/base/token/?file_extension=${extenstion}`
      );
    } catch (err) {
      console.log("errr", err);
      message.error("Logo upload failed.");
      setLoading(false);
    }

    console.log("result in aws token --> ", result?.data);
    return result?.data?.data;
  };

  const uploadFileToAWS = async (url: string, formData: any, key: string) => {
    var instance = axios.create();
    delete instance.defaults.headers.common["Authorization"];

    return instance
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("after file upload", response.data);
        return key;
      })
      .catch((err) => {
        console.log(err);
        message.error("Logo upload failed.");
        setLoading(false);
      });
  };

  const updateServerWithLogoUploadData = async (logo_key: string) => {
    let result;

    try {
      result = await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/auth/user/`,
        {
          profile_picture_key: logo_key,
        }
      );
      message.success("Logo upload sucessful.");
      setLoading(false);
      setSelectedFile(null);

      setTimeout(function () {
        getUserData();
      }, 5000);
    } catch (err) {
      console.log("errr", err);
      message.success("Logo upload failed.");
    }

    return result?.data;
  };

  const deleteProfilePicture = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/users/logo`);
      setTimeout(function () {
        getUserData();
      }, 2000);
      message.success("Logo deleted successfully");
    } catch (err) {
      console.log("errr", err);
      message.error("Logo delete failed");
    }
  };

  const handleFileDelete = async (awsKey: string) => {
    try {
      const deleteResult = await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/api/base/token/?key=${awsKey}`
      );
      console.log(`File delete Result`, deleteResult);
      setLogoUrl(null);
      deleteProfilePicture();
    } catch (err) {
      console.log("errr", err);
      message.error("File delete failed");
    }
  };

  return (
    <div>
      {logoUrl && (
        <div>
          <Space>
            <Image
              src={logoUrl}
              style={{ maxHeight: 100, maxWidth: 100 }}
              alt="company logo"
              fallback={FALLBACK_IMAGE}
            />
          </Space>
        </div>
      )}
      <div style={{ display: "flex" }}>
        <label htmlFor="Upload Logo">
          {logoUrl ? (
            !loading && (
              <Button onClick={() => handleFileDelete(logoUrl)}>Remove</Button>
            )
          ) : (
            <Input
              type="file"
              onChange={(e) => onFileChange(e)}
              style={{ width: "220px", marginRight: 5 }}
            ></Input>
          )}
          {loading && (
            <div>
              Uploading <Spin />
            </div>
          )}
        </label>
      </div>
    </div>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getUserData: () => dispatch(GetUserData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadLogo);
