import React, { useState } from "react";

import { Button, Input, message, Modal, Tag, Typography } from "antd";
import axios from "axios";

import { emailRegex } from "../../../../shared/constants/regex";

const { Text } = Typography;

const InviteModal = (props: any) => {
  const { isInviteModalOpen, setIsInviteModalOpen, currentUuid } = props;

  const [emailList, setEmailList] = useState<string[]>([]);

  const [currentEmail, setCurrentEmail] = useState("");

  const [error, setError] = useState("");

  const handleOk = () => {
    sendInvites();
    setIsInviteModalOpen(false);
  };

  const handleCancel = () => {
    setIsInviteModalOpen(false);
  };

  const handleTagClose = (e: any) => {
    const newList: string[] = emailList.filter((email) => email !== e);

    setEmailList(newList);
  };

  const handleAddEmailToList = () => {
    const isEmailValid = emailRegex.test(currentEmail);

    if (isEmailValid) {
      const doesEmailExists = emailList.filter(
        (email) => currentEmail === email
      );

      if (doesEmailExists.length === 0) {
        const newList = emailList;
        newList.push(currentEmail);
        setEmailList(newList);

        setError("");
        setCurrentEmail("");
      } else {
        setError("This email is already added.");
        message.error("This email is already added.");
      }
    } else {
      setError("Please enter valid email");
      message.error("Please enter valid email");
    }
  };

  const sendInvites = async () => {
    await axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/${currentUuid}/email/`, {
        to_emails: emailList,
      })
      .then((res) => {
        message.success("Invites were sent successfully.");
        handleCancel();
      })
      .catch((err: any) => {
        console.log("errors ", err);
        message.error("Invites could not be sent");

        handleCancel();
      });
  };

  return (
    <Modal
      title="Send Invite"
      visible={isInviteModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Send Invites"
    >
      <div style={{ minHeight: "200px" }}>
        <div
          style={{
            padding: "10px",
            maxHeight: "60vh",
            overflowY: "auto",
            backgroundColor: "whitesmoke",
          }}
        >
          {emailList.length > 0
            ? emailList.map((email) => (
                <Tag
                  key={email}
                  closable
                  onClose={(e) => handleTagClose(email)}
                >
                  {email}
                </Tag>
              ))
            : "Add emails to start sending invites"}
        </div>
        <div style={{ padding: "20px" }}>
          <div style={{ display: "flex" }}>
            <Input
              placeholder="Enter email"
              value={currentEmail}
              onChange={(e) => setCurrentEmail(e.target.value)}
              maxLength={50}
              style={{ marginRight: "10px" }}
              onPressEnter={() => handleAddEmailToList()}
            ></Input>

            <Button onClick={handleAddEmailToList} type="primary">
              Add
            </Button>
          </div>
          {error && <Text type="danger">{error}</Text>}
        </div>
      </div>
    </Modal>
  );
};

export default InviteModal;
