import React from "react";
import { Button, Checkbox } from "antd";
import Choice from "./Choice";
import { IElement } from "../../utils/interfaces";

interface IProps {
  componentDisabled: boolean;
  updateFieldValue: (choices: object) => void;
  handleImageDelete: (imageKey: string) => number;
  handleImageUpload: (element: IElement) => IElement;
  updateElement: (element: IElement) => void;
  state: IElement;
  deleteButton: React.ReactNode;
  extraProps: React.ReactNode;
}

export default function CTCheckbox(props: IProps) {
  return (
    <>
      <Choice {...props} componentDisabled={props.componentDisabled}>
        <Checkbox checked={false} disabled />
      </Choice>
    </>
  );
}
