import React, { useState, useEffect } from "react";

import { Button, Card, Divider, Form, Input, Switch, Tooltip } from "antd";

const FormMailSettings = (props: any) => {
  const [toName, setToName] = useState("");
  const [toEmail, setToEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [isActive, setIsActive] = useState(true);

  const { createFormHandlers, handlerData } = props;

  const onSave = async () => {
    const mailSender = {
      to_name: toName,
      to_email: toEmail,
      subject,
      body: emailBody,
      is_active: isActive,
    };

    await createFormHandlers({
      plugin_uid: "mail",
      plugin_data: mailSender,
    });
  };

  const isEmailValid =
    toEmail !== "" &&
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
      toEmail
    );

  useEffect(() => {
    if (handlerData) {
      const mailSenderHandlerData = handlerData.filter(
        (handler: { plugin_uid: string }) => handler.plugin_uid === "mail"
      );

      if (mailSenderHandlerData.length > 0) {
        const pluginData = mailSenderHandlerData[0].plugin_data;
        setToEmail(pluginData.to_email);
        setToName(pluginData.to_name);
        setSubject(pluginData.subject);
        setEmailBody(pluginData.body);
        setIsActive(pluginData.is_active);
      }
    }
  }, []);

  console.log("hello", isEmailValid);

  return (
    <Card
      title={
        <Tooltip title="Settings for mail which will be send to form creater when a new form submission happens">
          <span>Creator Email Settings </span>
        </Tooltip>
      }
      style={{ margin: "10px" }}
    >
      <p>
        Settings for mail which will be send to form creater when a new form
        submission happens
      </p>
      <Divider />
      <Form layout="vertical">
        <Form.Item label="To Name: ">
          <Input
            value={toName}
            onChange={(e: any) => setToName(e.target.value)}
            maxLength={100}
          />
        </Form.Item>
        <Form.Item
          label="Email: "
          help={toEmail && !isEmailValid ? "Email is Invalid" : ""}
        >
          <Input
            value={toEmail}
            onChange={(e: any) => setToEmail(e.target.value)}
            maxLength={200}
          />
        </Form.Item>
        <Form.Item label="Email Subject: ">
          <Input
            value={subject}
            onChange={(e: any) => setSubject(e.target.value)}
            maxLength={200}
          />
        </Form.Item>
        <Form.Item label="Email Body: ">
          <Input.TextArea
            value={emailBody}
            onChange={(e: any) => setEmailBody(e.target.value)}
            maxLength={2000}
          />
        </Form.Item>
        <Form.Item
          label="Send Mail Alerts: "
          help="When disabled, no email alerts will be sent on new form submissions"
          style={{ textAlign: "left" }}
        >
          <Switch
            checked={isActive}
            onChange={(value: any) => setIsActive(value)}
            style={{ textAlign: "left" }}
          />
        </Form.Item>
      </Form>
      <Divider />
      <Button type="primary" onClick={() => onSave()} disabled={!isEmailValid}>
        Save Data
      </Button>
    </Card>
  );
};

export default FormMailSettings;
