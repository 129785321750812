import React, { useEffect, useState } from "react";

import { message } from "antd";
import axios from "axios";

import FormIndividualStats from "./FormIndividualStats";

const FormSubmissionStats = (props: { formData: any }) => {
  const { formData } = props;
  const { value } = formData;

  const [loading, setLoading] = useState<boolean>(false);

  const [formSubmissionData, setFormSubmissionData] = useState<any>();

  const [formMetadata, setFormMetadata] = useState<any>();

  const getAllFormSubmissionsByUuid = async (uuid: string) => {
    setLoading(true);

    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/api/${uuid}/saved-data/?size=1000`
      )
      .then(async (result1) => {
        setFormSubmissionData(result1.data.data);
        formatStats(result1.data.data);
        setLoading(false);
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Could not get form response data.");
        setLoading(false);
      });
  };

  const formatStats = (submissionData: any) => {
    const submissionMetadata: any = [];

    if (submissionData && submissionData.length >= 1) {
      submissionData.map((entry: any) => {
        if (entry.metadata) {
          const entryMetadata = {
            id: entry.id,
            ip: entry.metadata.ip ? entry.metadata.ip : "-",
            os_info: entry.metadata.os_info ? entry.metadata.os_info : "-",
            city: entry.metadata.city ? entry.metadata.city : "-",
            flag: entry.metadata.flag ? entry.metadata.flag : "-",
            country: entry.metadata.country ? entry.metadata.country : "-",
            device: entry.metadata.device_info
              ? entry.metadata.device_info
              : "-",
            created: Date.parse(entry.created),
            browser_info: entry.metadata.browser_info
              ? entry.metadata.browser_info
              : "-",
          };
          submissionMetadata.push(entryMetadata);
        }
      });
      setFormMetadata(submissionMetadata);
    }
  };

  useEffect(() => {
    getAllFormSubmissionsByUuid(value);
  }, [value]);

  return (
    <div>
      {loading && <p>Loading....</p>}
      <FormIndividualStats metadata={formSubmissionData} formUuid={formData.value} />
    </div>
  );
};

export default FormSubmissionStats;
