import React from "react";
import { CTComponents } from "./CTComponents";
import { Button } from "antd";

interface IProps {
  addAnswerType: (label: string) => void;
}

function ComponentList(props: IProps) {
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "2px",
        }}
      >
        {Object.keys(CTComponents).map((label: string, index) => {
          return (
            <Button
              key={index}
              children={label}
              onClick={() => props.addAnswerType(label)}
              style={{ margin: "2px", padding: "2px" }}
            />
          );
        })}
      </div>
    </div>
  );
}

export default ComponentList;
