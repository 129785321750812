import React, { useState, useEffect, useRef } from "react";

import { Button, Layout, Menu } from "antd";
import {
  MenuOutlined,
  UserOutlined,
  DashboardOutlined,
  FormOutlined,
  QuestionOutlined,
} from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import Sider from "antd/lib/layout/Sider";

import { Link } from "react-router-dom";

import "./SidebarMobile.less";
import { NOTION_HELP_LINK } from "../../../shared/constants/constants";

function useOutsideAlerter(ref: any) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: { target: any }) {
      if (ref.current && !ref.current.contains(event.target)) {
        alert("You clicked outside of me!");
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const SidebarMobile = (props: any) => {
  const [visible, setVisible] = useState(false);

  const { toggleCollapsed, collapsed } = props;

  const SidebarMenuForAll = (
    <>
      <Menu.Item
        key="1"
        icon={<DashboardOutlined />}
        onClick={() => toggleCollapsed()}
      >
        <Link to="/dashboard">Dashboard </Link>
      </Menu.Item>
      <Menu.Item
        key="2"
        icon={<FormOutlined />}
        onClick={() => toggleCollapsed()}
      >
        <Link to="/forms/all">Forms </Link>
      </Menu.Item>
      <Menu.Item
        key="3"
        icon={<UserOutlined />}
        onClick={() => toggleCollapsed()}
      >
        <Link to="/account/settings">Settings </Link>
      </Menu.Item>

      <Menu.Item key="4" icon={<QuestionOutlined />}>
        <a href={NOTION_HELP_LINK} target="_blank" rel="noreferrer">
          Help{" "}
        </a>
      </Menu.Item>
    </>
  );

  const wrapperRef = useRef(null);

  if (wrapperRef) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useOutsideAlerter(wrapperRef);
  }

  return (
    <div
      className="sidebar"
      style={{ zIndex: 9999, width: "0px", float: "left", position: "fixed" }}
    >
      <Button
        // className="navbar-button"
        type="primary"
        icon={<MenuOutlined />}
        onClick={() => toggleCollapsed()}
      />

      <Sider
        collapsible
        collapsed={false}
        onCollapse={toggleCollapsed}
        reverseArrow={true}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          display: collapsed ? "none" : "block",
          zIndex: 9999,
        }}
      >
        <Layout
          style={{
            float: "left",
            display: "flex",
            padding: "10px",
            flexDirection: "row",
            backgroundColor: "inherit",
            alignItems: "center",
          }}
        >
          <MenuOutlined
            style={{
              marginRight: "20px",
              color: "white",
              fontSize: "20px",
            }}
            onClick={toggleCollapsed}
          />
          <Link
            to="/dashboard"
            style={{
              textDecoration: "none",
              color: "white",
            }}
          >
            <Title style={{ color: "white" }} level={4}>
              CT Forms
            </Title>
          </Link>
        </Layout>
        <Menu
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          mode="inline"
          theme="dark"
          inlineCollapsed={collapsed}
          style={{ height: "100vh" }}
        >
          {SidebarMenuForAll}
        </Menu>
      </Sider>
    </div>
  );
};

export default SidebarMobile;
