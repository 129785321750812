import React from "react";
import { Image, Radio, Checkbox } from "antd";
import { FALLBACK_IMAGE } from "../../../shared/constants/constants";

interface IChoiceResponse {
  choice_text: string;
  imageKey?: string;
  imageUrl?: string;
}

interface IProps {
  choice: IChoiceResponse;
  elementType: string;
}

const ChoiceResponse = ({ choice, elementType }: IProps) => {
  const _elementTypes: any = {
    Dropdown: choice.choice_text,
    Radio: <Radio checked>{choice.choice_text}</Radio>,
    Checkbox: <Checkbox checked>{choice.choice_text}</Checkbox>,
  };

  return (
    <p
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        wordBreak: "break-word",
      }}
    >
      {_elementTypes[elementType]}
      {Boolean(choice.imageUrl) && (
        <Image
          src={choice.imageUrl}
          style={{ maxHeight: 200, maxWidth: 200 }}
          alt="Choice Image"
          fallback={FALLBACK_IMAGE}
        />
      )}
    </p>
  );
};

export default ChoiceResponse;
