import React, { useEffect, useState } from "react";

import { Button, Card, Divider, Form, Input, Select, Switch } from "antd";

const { Option } = Select;

const FormMailSubmissionSettings = (props: any) => {
  const [subject, setSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [formFieldToEmail, setFormFieldToEmail] = useState("");

  const { createFormHandlers, emailFields, handlerData } = props;

  const onSave = async () => {
    const mailData = {
      subject,
      body: emailBody,
      is_active: isActive,
      form_field_name_to_email: formFieldToEmail,
    };

    await createFormHandlers({
      plugin_uid: "mail_sender",
      plugin_data: mailData,
    });
  };

  useEffect(() => {
    console.log("handler data", handlerData);

    if (handlerData) {
      console.log("handler data", handlerData);

      const mailSenderHandlerData = handlerData.filter(
        (handler: { plugin_uid: string }) =>
          handler.plugin_uid === "mail_sender"
      );

      if (mailSenderHandlerData.length > 0) {
        const pluginData = mailSenderHandlerData[0].plugin_data;

        setSubject(pluginData.subject);
        setEmailBody(pluginData.body);
        setIsActive(pluginData.is_active);
        setFormFieldToEmail(pluginData.form_field_name_to_email);
      }

      console.log("mail sender handler data", mailSenderHandlerData);
    }
  }, [handlerData]);

  return (
    <Card title="Submitter E-mail Settings" style={{ margin: "10px" }}>
      <p>
        Settings for mail which will be send to form's submitter when they
        submit forms
      </p>
      <Divider />
      <Form layout="vertical">
        <Form.Item label="Form Field to Email: ">
          {emailFields && emailFields.length > 0 ? (
            <Select
              style={{ textAlign: "left" }}
              value={formFieldToEmail}
              onChange={(value: any) => {
                setFormFieldToEmail(value);
              }}
            >
              {emailFields &&
                emailFields.map((emailField: any) => (
                  <Option value={emailField.plugin_data.name}>
                    {emailField.plugin_data.label
                      ? emailField.plugin_data.label
                      : "No label"}
                  </Option>
                ))}
            </Select>
          ) : (
            <p>You have not added any email element yet.</p>
          )}
        </Form.Item>
        <Form.Item label="Email Subject: ">
          <Input
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            maxLength={200}
          />
        </Form.Item>
        <Form.Item label="Email Body: ">
          <Input.TextArea
            value={emailBody}
            onChange={(e) => setEmailBody(e.target.value)}
            maxLength={2000}
          />
        </Form.Item>
        <Form.Item
          label="Enable Email Alerts to Form Submitter: "
          help="Disabling this will stop sending emails alerts on new form
    submissions"
          style={{ textAlign: "left" }}
        >
          <Switch checked={isActive} onChange={(value) => setIsActive(value)} />
        </Form.Item>
      </Form>
      <Divider />
      <Button type="primary" onClick={() => onSave()}>
        Save Data
      </Button>
    </Card>
  );
};

export default FormMailSubmissionSettings;
