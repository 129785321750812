import React from "react";
import { Form } from "antd";

interface IProps {
  children: React.ReactElement;
  elementStyle?: React.CSSProperties;
  label: string;
}

function FormItem(props: IProps) {
  const style = {
    wordBreak: "break-word",
    background: "#969DB1",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "32px",
    color: "#FFFFFF",
    ...props.elementStyle,
  };

  return (
    <Form.Item
      colon={false}
      label={
        <span
          style={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: "bolder",
            fontSize: "24px",
            lineHeight: "32px",
            textAlign: "center",
            color: "#BAC3CA",
            marginBottom: 10,
          }}
        >
          {props.label}
        </span>
      }
    >
      {React.cloneElement(props.children, {
        style,
      })}
    </Form.Item>
  );
}

export default FormItem;
