import React, { useState, useEffect } from "react";

import { Col, Layout, message, Row } from "antd";

import axios from "axios";

import ElementSpinner from "../../../shared/customComponents/spinner/ElementSpinner";

import ProfileForm from "../../components/profile/ProfileForm";

import "./profile.less";

const Profile = () => {
  const [loading, setLoading] = useState(true);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");

  const [userData, setUserData] = useState("");

  const onFinish = async (values: any) => {
    setLoading(true);
    await updateUserProfile(setLoading);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const getUserProfile = async (setLoading: any) => {
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/auth/user/`)
      .then((res) => {
        console.log("---- res ---- ", res);
        setLoading(false);

        const { first_name, last_name, company_name } = res.data;

        setUserData(res.data);

        setFirstName(first_name);
        setLastName(last_name);
        setCompanyName(company_name);
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Could not get user data.");
        setLoading(false);
      });
  };

  const updateUserProfile = async (setLoading: any) => {
    await axios
      .put(`${process.env.REACT_APP_SERVER_URL}/auth/user/`, {
        first_name: firstName,
        last_name: lastName,
        company_name: companyName,
      })
      .then((res) => {
        setLoading(false);
        message.success("Profile updated successfully.");
      })
      .catch((err: any) => {
        message.error("Could not update profile.");
        setLoading(false);
      });
  };

  useEffect(() => {
    getUserProfile(setLoading);
  }, []);

  return (
    <ElementSpinner loading={loading}>
      <Layout className="profile-layout">
        <div>
          <br />
          <Row
            style={{
              width: "100%",
              backgroundColor: "yellow",
            }}
          >
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <div
                style={{
                  width: "auto",
                }}
              >
                <ProfileForm
                  firstName={firstName}
                  lastName={lastName}
                  companyName={companyName}
                  onFinishFailed={onFinishFailed}
                  onFinish={onFinish}
                  setFirstName={setFirstName}
                  setLastName={setLastName}
                  setCompanyName={setCompanyName}
                  userData={userData}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Layout>
    </ElementSpinner>
  );
};

export default Profile;
