import React, { useEffect, useState } from "react";

import { Layout, message, Alert } from "antd";

import axios, { AxiosError } from "axios";
import { connect, useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { AnyAction } from "redux";

import FormSubmitFields from "../../components/formSubmission/FormSubmitFields";

import { LogoutUser } from "../../../auth/core/redux/actions";

import THEMECOLORS from "../../../shared/theme/themeColors";

import moment from "moment";
import FormSubmitSuccess from "./FormSubmitSuccess";
import CompanyHeader from "./CompanyHeader";
import CompanyFooter from "../../components/companyFooter/CompanyFooter";
import ElementSpinner from "../../../shared/customComponents/spinner/ElementSpinner";

import {
  getAllClientDetails,
  getClientDeviceDetails,
  getClientIP,
} from "../../../auth/core/services/clientInfo";

import { CONSENT_TYPES } from "../../../shared/constants/constants";

import FormHead from "./FormHead";

import ReactGa from "react-ga";

interface Iproof {
  name: string;
  version: string;
  id?: number;
  url: string;
}

const FormSubmit = (props: any) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { pathname } = useLocation();

  const [redirectTo, setRedirectTo] = useState<string>();

  const [userData, setUserData] = useState<any>();

  const [formData, setFormData] = useState<any>();

  const [formCreatorData, setFormCreatorData] = useState<any>();

  const [formSubmitSuccess, setFormSubmitSuccess] = useState<boolean>(false);

  const [isLogoPresent, setIsLogoPresent] = useState<boolean>(false);

  const [oriProofs, setOriProofs] = useState<Iproof[]>([]);

  const [isCompanyNamePresent, setIsCompanyNamePresent] =
    useState<boolean>(false);

  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      localStorage.clear();
    }
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      if (localStorage.getItem("tempAccessToken")) {
        // getAllProofs();
      } else {
        registerAnonymousUser();
      }
    } else {
      getAllProofs();
    }
  }, []);

  const getUserDetails = async () => {
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/auth/user/`)
      .then((res) => {
        console.log("user data recieved", res.data);
        setUserData(res.data);
      })
      .catch(() => {
        console.log("could not fetch user details ");
        // setLoading(false);
      });
  };

  const getAllProofs = async () => {
    const mode = localStorage.getItem("tempAccessToken") ? "anon" : "user";

    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/${uuid}/proofs/`, {
        headers: {
          Authorization:
            mode === "anon" &&
            `Bearer ${localStorage.getItem("tempAccessToken")}`,
        },
      })
      .then((res) => {
        console.log("---- res in get proofs---- ", res);
        setOriProofs(res.data.data);
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
      });
  };

  const handleLogout = () => {
    dispatch(LogoutUser());
    localStorage.setItem("redirect-path", pathname);
    setRedirectTo("login");
  };

  const handleRedirectLogin = () => {
    localStorage.setItem("redirect-path", pathname);
    setRedirectTo("login");
  };

  const params = useParams<any>();

  const { uuid } = params;

  const getFormByUuid = async (setLoading: any) => {
    setLoading(true);

    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/custom_forms/${uuid}/`)
      .then(async (res) => {
        console.log("---- res get form by uuid ---- ", res);
        await setFormData(res.data);

        await setFormCreatorData(res.data.user);

        await getElementsByFormUuid(setLoading, res.data);
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Could not get Form data.");
        setLoading(false);
      });
  };

  const getElementsByFormUuid = async (setLoading: any, formData: any) => {
    if (!loading) setLoading(true);
    console.log("*********** FETCHING FORM ELEMENTS ********************");
    const getS3FilePath = async (media_key: string, field: any) => {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/base/get-s3-url/?key=${media_key}`
        );
        console.log(`result from AWS`, result);
        if (field.questionImageKey) {
          field.questionImageUrl = result.data.url;
        } else {
          field.imageUrl = result.data.url;
        }
      } catch (error) {
        console.log("errors could not be get", error);
        message.error("Could not get form response data.");
      }
    };

    if (formData) {
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/${uuid}/elements/?size=50`
        )
        .then((result1) => {
          console.log("---- result from get elements API ---- ", result1);

          const elementData = result1.data.data;

          console.log("element Data ", elementData);

          let dictionary;
          dictionary = Object.assign(
            {},
            ...elementData.map((x: any) => ({
              [x.plugin_data.name]: x,
            }))
          );
          const promises = [];

          for (var element of elementData) {
            element.label = element.metadata.elementType;
            element.uuid = element.plugin_data.name;

            if (element.metadata.hasOwnProperty("questionImageKey")) {
              element.questionImageKey = element.metadata.questionImageKey;
              promises.push(getS3FilePath(element.questionImageKey, element));
            }
            if (element.metadata.hasOwnProperty("questionImageSize")) {
              element.questionImageSize = element.metadata.questionImageSize;
            }
            if (["Checkbox", "Radio"].includes(element.metadata.elementType)) {
              for (const choice of element.plugin_data.choices) {
                if (choice.imageKey) {
                  promises.push(getS3FilePath(choice.imageKey, choice));
                }
              }
            }
          }

          const allPromises = Promise.all(promises).then((values) => {
            setLoading(false);
          });

          console.log("converted dictionary ", dictionary);

          const newData = formData;

          newData.elements = dictionary;
          newData.elementIds = Object.values(newData.elements);

          setFormData(newData);
        })
        .catch((err: any) => {
          console.log("errors could not be get", err);
          message.error("Could not get form elements.");
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (accessToken) {
      getUserDetails();
    }
    getFormByUuid(setLoading);
  }, []);

  useEffect(() => {
    // to report page view
    ReactGa.pageview("/form-submit");
  }, []);

  const registerAnonymousUser = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/auth/registration/`, {})
      .then((response) => {
        console.log("response of axios", response.data);

        const { access_token: accessToken, refresh_token: refreshToken } =
          response.data;

        localStorage.setItem("tempAccessToken", accessToken);
        localStorage.setItem("tempRefreshToken", refreshToken);
      })

      .then(() => {
        getAllProofs();
      })
      .catch((err: any) => {
        console.log("error in axios API  -> ", err.response.data);
        setLoading(false);
      });
  };

  const handleFormSubmit = async (formSubmissionData: any) => {
    for (let key in formSubmissionData) {
      if (formSubmissionData[key] === "") {
        delete formSubmissionData[key];
      } else if (typeof formSubmissionData[key] === "number") {
        formSubmissionData[key] = String(formSubmissionData[key]);
      } else if (moment.isMoment(formSubmissionData[key])) {
        const newKey = key.split("_");

        if (newKey[1] === "date") {
          const dateToSend = formSubmissionData[key].format("YYYY-MM-DD");
          formSubmissionData[newKey[0]] = dateToSend;

          delete formSubmissionData[key];
        } else if (newKey[1] === "time") {
          const timeToSend = formSubmissionData[key].format("HH:mm");
          formSubmissionData[newKey[0]] = timeToSend;
          delete formSubmissionData[key];
        }
      }
    }

    const deviceDetails: any = await getClientDeviceDetails();

    const ipAddress: any = await getClientIP();

    const clientDetails = await getAllClientDetails();

    const formSubmissionPayload = {
      ...formSubmissionData,
    };

    if (userData && userData.email) {
      if (!formSubmissionPayload.hasOwnProperty("metadata")) {
        formSubmissionPayload.metadata = {
          form_submitter_email: userData.email,
        };
      }
    }

    if (formSubmissionPayload.hasOwnProperty("metadata")) {
      const metadata: any = {
        ...formSubmissionPayload.metadata,
        ...clientDetails,
        ...deviceDetails,
      };
      formSubmissionPayload.metadata = metadata;
    } else {
      formSubmissionPayload.metadata = { ...clientDetails, ...deviceDetails };
    }

    submitForm(formSubmissionPayload);
  };

  const submitForm = async (formSubmissionPayload: any) => {
    console.log("Submitting form... ---->", formSubmissionPayload);

    setLoading(true);

    const mode = localStorage.getItem("tempAccessToken") ? "anon" : "user";

    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/${uuid}/saved-data/`,
        formSubmissionPayload,
        {
          headers: {
            Authorization:
              mode === "anon" &&
              `Bearer ${localStorage.getItem("tempAccessToken")}`,
          },
        }
      )
      .then((res) => {
        console.log(`Form submission res.data`, res.data);
        message.success("Form submitted successfully");
        setFormSubmitSuccess(true);
        handleSentConsent();
        redirectToDashboard();
      })
      .catch((err: AxiosError) => {
        console.log("Error while submitting form", err);
        console.log("Error data", err?.response?.data);
        message.error("Error while submitting form");
      });
  };

  const handleSentConsent = async () => {
    let consentProofs: { data: any };

    let consentDataToSent: any;

    const clientDetails = getClientDeviceDetails();

    const ipAddress: any = await getClientIP();

    console.log("hello ---> ", await ipAddress);

    const mode = localStorage.getItem("tempAccessToken") ? "anon" : "user";

    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}​/api/users/consent/proofs/`,
      {
        headers: {
          Authorization:
            mode === "anon" &&
            `Bearer ${localStorage.getItem("tempAccessToken")}`,
        },
      }
    );

    console.log("consent proofs ******* -----> ", response.data.data);

    const proofsToSent: any = [];

    for (let i = 0; i >= response.data.data.length; i++) {
      const proof = response.data.data[i];
      proof.version = proof.latest_version;
      await proofsToSent.push(proof);
    }

    response.data.data.forEach(async (responseItem: { [x: string]: any }) => {
      const proof = responseItem;
      proof.version = proof.latest_version;
      await proofsToSent.push(proof);
    });

    console.log("consent proofs -----> ", proofsToSent);

    consentDataToSent = {
      ip_address: await ipAddress,
      consent_type: CONSENT_TYPES.SUBMISSION_CT_FORMS,
      proofs: await proofsToSent,
      ...clientDetails,
    };

    // submit consent of ct-forms
    submitConsent(await consentDataToSent);
  };

  const submitConsent = async (consentDataToSent: any) => {
    console.log("consent data", consentDataToSent);

    const mode = localStorage.getItem("tempAccessToken") ? "anon" : "user";

    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}​/api/users/consent/`,
        consentDataToSent,
        {
          headers: {
            Authorization:
              mode === "anon" &&
              `Bearer ${localStorage.getItem("tempAccessToken")}`,
          },
        }
      )
      .then((response: any) => {
        console.log(
          "----> Response from submit consent  ----> ",
          response.data
        );
        // consentProofs = response.data;
      })
      .catch((err) => console.log("--- erro", err));

    if (oriProofs.length > 0) {
      consentDataToSent.proofs = oriProofs;
      consentDataToSent.consent_type = CONSENT_TYPES.SUBMISSION_USER_DEFINED;

      await axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}​/api/users/consent/`,
          consentDataToSent,
          {
            headers: {
              Authorization:
                mode === "anon" &&
                `Bearer ${localStorage.getItem("tempAccessToken")}`,
            },
          }
        )
        .then((response: any) => {
          console.log(
            "----> Response from submit consent  ----> ",
            response.data
          );
          // consentProofs = response.data;
        })
        .catch((err) => console.log("--- erro", err));
    }
  };

  const handleFinishFailed = (errorInfo: any) => {
    console.log("Form submission failed", errorInfo);
  };

  const redirectToDashboard = () => {
    setTimeout(() => {
      setRedirectTo("dashboard");
      setLoading(false);
    }, 3 * 1000);
  };

  console.log("USER DATA --->? ", userData);

  useEffect(() => {
    if (
      formCreatorData &&
      formCreatorData.profile_picture &&
      formCreatorData.profile_picture.thumbnail_large
    ) {
      setIsLogoPresent(true);
    }

    if (formCreatorData && formCreatorData && formCreatorData.company_name) {
      setIsCompanyNamePresent(true);
    }
  }, [formCreatorData]);

  return (
    <ElementSpinner loading={loading}>
      {formSubmitSuccess ? (
        <FormSubmitSuccess />
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              minHeight: "100vh",
              justifyContent: "center",
              maxWidth: "100vw",
              backgroundColor: "white",
            }}
          >
            <Layout
              style={{
                maxWidth: "1000px",
                paddingTop: "5vh",
                backgroundColor: "white",
              }}
            >
              <>
                {formData && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      // backgroundColor: "green",
                    }}
                  >
                    <Layout
                      style={{
                        maxWidth: "800px",
                        paddingTop: "20px",
                        padding: "20px",
                        backgroundColor: "white",
                        // height: "100%",
                        // flex: 1,
                      }}
                    >
                      {isCompanyNamePresent || isLogoPresent ? (
                        <CompanyHeader
                          userData={formCreatorData}
                          isLogoPresent={isLogoPresent}
                          isCompanyNamePresent={isCompanyNamePresent}
                        />
                      ) : (
                        <></>
                      )}
                      <FormHead
                        title={formData.title}
                        description={formData.description}
                      />

                      {formData && formData.login_required && !userData && (
                        <>
                          <Alert
                            message={
                              <span>
                                You need to login to fill this form.
                                <p
                                  onClick={() => handleLogout()}
                                  style={{
                                    paddingLeft: "5px",
                                    cursor: "pointer",
                                    color: THEMECOLORS.fontColorPrimary,
                                    display: "inline",
                                  }}
                                >
                                  <u> Login</u>
                                </p>
                              </span>
                            }
                            type="warning"
                            style={{ marginBottom: "20px" }}
                          />
                        </>
                      )}
                      {formData &&
                        formData.elements &&
                        formData.login_required &&
                        userData && (
                          <FormSubmitFields
                            formData={formData}
                            handleFormSubmit={handleFormSubmit}
                            handleFinishFailed={handleFinishFailed}
                            oriProofs={oriProofs}
                            addProofs={formData.add_proofs}
                            userData={userData}
                            setFormSubmitSuccess={setFormSubmitSuccess}
                            handleSentConsent={handleSentConsent}
                          />
                        )}
                      {formData &&
                        formData.elements &&
                        !formData.login_required && (
                          <FormSubmitFields
                            formData={formData}
                            handleFormSubmit={handleFormSubmit}
                            handleFinishFailed={handleFinishFailed}
                            oriProofs={oriProofs}
                            addProofs={formData.add_proofs}
                            userData={userData}
                            setFormSubmitSuccess={setFormSubmitSuccess}
                            handleSentConsent={handleSentConsent}
                          />
                        )}
                      <CompanyFooter />
                    </Layout>
                  </div>
                )}
              </>
            </Layout>
            {redirectTo && <Redirect to={`/${redirectTo}/`} />}
          </div>
        </>
      )}
    </ElementSpinner>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    //
  };
};

const mapStateToProps = (state: any) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormSubmit);
