import React, { useEffect } from "react";
import QuestionCard from "./QuestionCard";
import { Space, BackTop } from "antd";
import { ItemInterface, ReactSortable } from "react-sortablejs";
import { IElement } from "../utils/interfaces";
import { ArrowUpOutlined } from "@ant-design/icons";
import { connect, ConnectedProps } from "react-redux";
import { IFormData } from "../utils/interfaces";

import "./FormBody.css";
interface IProps extends PropsFromRedux {
  onElementDelete: (element: IElement) => void;
  onElementUpdate: (element: IElement) => void;
  onElementPositionUpdate?: (element: IElement) => void;
  handleImageDelete: (imageKey: string) => number;
  handleImageUpload: (element: IElement) => IElement;
  debounce?: number;
}

function FormBody(props: IProps) {
  const { dispatch, elementIds, onElementDelete, onElementUpdate } = props;

  const setElements = (elementIds: ItemInterface[]) => {
    dispatch({
      type: "ORDER_FIELDS",
      payload: elementIds,
    });
  };

  const updateElementPosition = (e: ItemInterface) => {
    const currentElement: any = Object.values(elementIds).find(
      (elem, index) => index === e.newIndex
    );
    let prevElement: any = Object.values(elementIds).find(
      (elem, index) => index === e.newIndex - 1
    );
    let nextElement: any = Object.values(elementIds).find(
      (elem, index) => index === e.newIndex + 1
    );

    switch (e.newIndex) {
      case 0:
        if (nextElement) {
          const nextPosition =
            typeof nextElement.position === "number"
              ? nextElement.position
              : parseInt(nextElement.position);
          currentElement!.position = nextPosition / 2;
        }
        break;

      case elementIds.length - 1:
        if (prevElement) {
          const prevPosition =
            typeof prevElement.position === "number"
              ? prevElement.position
              : parseInt(prevElement.position);
          currentElement!.position = prevPosition * 2;
        }
        break;

      default:
        if (!prevElement || !nextElement) return;
        const nextPosition =
          typeof nextElement.position === "number"
            ? nextElement.position
            : parseInt(nextElement.position);
        const prevPosition =
          typeof prevElement.position === "number"
            ? prevElement.position
            : parseInt(prevElement.position);
        currentElement!.position = (prevPosition + nextPosition) / 2;
    }
    if (!props.onElementPositionUpdate) return;
    props.onElementPositionUpdate(currentElement!);
    dispatch({
      type: "UPDATE_ELEMENT_POSITION",
      payload: currentElement!,
    });
  };

  useEffect(() => {
    console.log("**************** FORM BODY RE-RENEDERED *********");
  }, []);

  return (
    <Space
      direction="vertical"
      style={{ display: "flex", maxWidth: 800, margin: "auto" }}
    >
      <ReactSortable
        list={elementIds as ItemInterface[]}
        setList={setElements}
        handle=".handle"
        onEnd={updateElementPosition}
      >
        {elementIds.map((element: IElement, index: number) => {
          return (
            <QuestionCard
              key={element.uuid}
              elementUuid={element.uuid as string}
              deleteElement={onElementDelete}
              onElementUpdate={onElementUpdate}
              handleImageDelete={props.handleImageDelete}
              handleImageUpload={props.handleImageUpload}
              debounce={props.debounce}
            />
          );
        })}
      </ReactSortable>
      <BackTop>
        <div className="backtop">
          <ArrowUpOutlined />
        </div>
      </BackTop>
    </Space>
  );
}

interface RootState {
  form: IFormData;
}

interface IAction {
  type: string;
  payload: any;
}

const mapState = (state: RootState) => ({
  elementIds: state.form.elementIds,
});

const mapDispatch = {
  dispatch: (action: IAction) => action,
};

export const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FormBody);
