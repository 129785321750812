import React, { useState, useEffect } from "react";

import { Button, Card, Form, Input, Popconfirm } from "antd";

import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

const FormProofsComponent = (props: any) => {
  const { proof, addNewProof, deleteProof, setAllowAdd, updateProof } = props;

  const [proofName, setProofName] = useState<string>("");
  const [proofVersion, setProofVersion] = useState<string>("");
  const [proofURL, setProofURL] = useState<string>("");

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [errorName, setErrorName] = useState<string>("");
  const [errorVersion, setErrorVersion] = useState<string>("");
  const [errorUrl, setErrorUrl] = useState<string>("");

  const checkForErrorsAndCreate = () => {
    if (!proofName) {
      setErrorName("Name is required.");
    } else if (
      !/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(
        proofURL
      )
    ) {
      setErrorUrl("Url is not valid. Should be in form http://www.google.com");
    } else {
      addNewProof({
        name: proofName,
        version: proofVersion,
        url: proofURL,
      });
    }
  };

  const checkForErrorsAndUpdate = () => {
    if (!proofName) {
      setErrorName("Name is required.");
    } else if (
      !/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(
        proofURL
      )
    ) {
      setErrorUrl("Url is not valid. Should be in form http://www.google.com");
    } else {
      updateProof({
        id: proof.id,
        name: proofName,
        version: proofVersion,
        url: proofURL,
      });
    }
  };

  const handleDeleteProof = (id: number) => {
    deleteProof(id);
  };

  const handleCancel = () => {
    setProofName(proof.name);
    setProofVersion(proof.version);
    setProofURL(proof.url);
    setErrorName("");
    setErrorVersion("");
    setErrorUrl("");
  };

  useEffect(() => {
    if (proof && proof.hasOwnProperty("id") && proof.id) {
      setProofName(proof.name);
      setProofVersion(proof.version);
      setProofURL(proof.url);
    }
  }, []);

  if (!props.proof?.id) {
    return (
      <Card style={{ backgroundColor: "whitesmoke" }}>
        <Form
          layout={window.innerWidth > 500 ? "vertical" : "horizontal"}
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          <Form.Item label="Name" style={{ margin: "5px", width: "100%" }}>
            <Input
              value={proofName}
              onChange={(e: any) => {
                setProofName(e.target.value);
                setErrorName("");
              }}
              maxLength={50}
              className="ct_forms_input_field"
              bordered={false}
            ></Input>
          </Form.Item>
          <Form.Item label="Version" style={{ margin: "5px", width: "100%" }}>
            <Input
              value={proofVersion}
              onChange={(e: any) => setProofVersion(e.target.value)}
              maxLength={50}
              className="ct_forms_input_field"
              bordered={false}
            ></Input>
          </Form.Item>

          <Form.Item label="URL" style={{ margin: "5px", width: "100%" }}>
            <Input
              value={proofURL}
              onChange={(e: any) => {
                setProofURL(e.target.value);
                setErrorUrl("");
              }}
              maxLength={200}
              className="ct_forms_input_field"
              bordered={false}
            ></Input>
          </Form.Item>
          <Form.Item>
            <Button
              onClick={() => {
                checkForErrorsAndCreate();
              }}
              style={{ margin: "5px", marginTop: "35px" }}
            >
              Add
            </Button>
            <Button
              onClick={() => {
                setAllowAdd();
              }}
              style={{ margin: "5px", marginTop: "35px" }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
        {errorName && <p style={{ color: "red" }}>{errorName}</p>}
        {errorVersion && <p style={{ color: "red" }}>{errorVersion}</p>}
        {errorUrl && <p style={{ color: "red" }}>{errorUrl}</p>}
      </Card>
    );
  } else {
    return (
      <Card>
        <Form
          layout={window.innerWidth > 500 ? "vertical" : "horizontal"}
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          <Form.Item label="Name" style={{ margin: "5px", width: "100%" }}>
            <Input
              value={proofName}
              onChange={(e: any) => {
                setProofName(e.target.value);
                setErrorName("");
              }}
              disabled={!isEditing}
              className="ct_forms_input_field"
              bordered={false}
            ></Input>
          </Form.Item>
          <Form.Item label="Version" style={{ margin: "5px", width: "100%" }}>
            <Input
              value={proofVersion}
              onChange={(e: any) => setProofVersion(e.target.value)}
              disabled={!isEditing}
              className="ct_forms_input_field"
              bordered={false}
            ></Input>
          </Form.Item>
          <Form.Item label="URL" style={{ margin: "5px", width: "100%" }}>
            <Input
              value={proofURL}
              onChange={(e: any) => {
                setProofURL(e.target.value);
                setErrorUrl("");
              }}
              disabled={!isEditing}
              className="ct_forms_input_field"
              bordered={false}
            ></Input>
          </Form.Item>
          {!isEditing && (
            <>
              <Button
                onClick={() => setIsEditing(true)}
                style={{ margin: "10px", marginTop: "35px" }}
                className="ct_forms_secondary_button"
              >
                <EditOutlined />
              </Button>
              <Popconfirm
                title="Are you sure to delete this?"
                onConfirm={(e) => handleDeleteProof(proof.id)}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  style={{ margin: "10px", marginTop: "35px" }}
                  className="ct_forms_secondary_button"
                >
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </>
          )}
          {isEditing && (
            <>
              <Button
                onClick={() => checkForErrorsAndUpdate()}
                style={{ margin: "10px", marginTop: "35px" }}
                className="ct_forms_secondary_button"
              >
                Save
              </Button>
              <Button
                onClick={(e) => {
                  setIsEditing(false);
                  handleCancel();
                }}
                style={{ margin: "10px", marginTop: "35px" }}
                className="ct_forms_secondary_button"
              >
                Cancel
              </Button>
            </>
          )}
        </Form>
        {errorName && <p style={{ color: "red" }}>{errorName}</p>}
        {errorVersion && <p style={{ color: "red" }}>{errorVersion}</p>}
        {errorUrl && <p style={{ color: "red" }}>{errorUrl}</p>}
      </Card>
    );
  }
};

export default FormProofsComponent;
