import React, { useEffect, useRef, useState } from "react";
import FormBody from "./FormBody";
import ComponentList from "./ComponentList";
import { CTComponents } from "./CTComponents";
import { IFormData, IElement } from "../utils/interfaces";
import { Layout, Space } from "antd";
import NavBar from "./NavBar/NavBar";
import { v4 as uuidv4 } from "uuid";
import PLUGIN_DATA_JSON from "../constants/plugin_data.json";
import { INITIAL_POSITION, MAX_ELEMENTS } from "../constants";
import "./CTFormBuilder.css";
import message from "../utils/message";
import FormHead from "./FormHead";
import { Provider } from "react-redux";
import { connect, ConnectedProps } from "react-redux";
import { store } from "../utils/store";

const PLUGIN_DATA: { [key: string]: { [key: string]: any } } = {
  ...PLUGIN_DATA_JSON,
};

interface IFormDetails {
  title?: string;
  description?: string;
}

interface IProps extends Props, PropsFromRedux {}

const getLastElementPosition = (formData: IFormData | undefined) => {
  if (formData?.elements) {
    const _elements = Object.values(formData?.elements);
    if (_elements.length) {
      const lastElement: IElement = _elements[_elements.length - 1];
      return lastElement.position;
    }
  }
  return null;
};

function CTFormBuilder(props: IProps) {
  const { elementsLength, dispatch } = props;

  const [lastElementPosition, setlastElementPosition] = useState(() =>
    getLastElementPosition(props.formData)
  );

  const bottomRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (props.formData?.uuid) {
      dispatch({
        type: "UPDATE_FORM",
        payload: { ...props.formData, elements: props.formData.elements ?? {} },
      });
    } else {
      const form_uuid = uuidv4();
      dispatch({ type: "ADD_UUID", payload: form_uuid });
      dispatch({ type: "ADD_NAME", payload: form_uuid });
    }
  }, []);

  useEffect(() => {
    if (props.updatedFormData) {
      dispatch({
        type: "UPDATE_FORM",
        payload: props.updatedFormData,
      });
    }
  }, [props.updatedFormData]);

  const addAnswerType = async (answerType: string) => {
    const maxValue = props.maxElements || MAX_ELEMENTS;
    if (elementsLength >= maxValue) {
      message.warning(`Max ${maxValue} elements allowed`);
      return;
    }

    const plugin_uid: string = CTComponents[answerType]["plugin_uid"];
    let plugin_data;
    const choiceData = {
      label: "",
      name: "",
      choices: [{ choice_text: "choice1" }],
      help_text: "",
      initial: "",
      required: false,
    };
    if (["checkbox_select_multiple", "radio"].includes(plugin_uid)) {
      plugin_data = choiceData;
    } else {
      plugin_data = PLUGIN_DATA[plugin_uid];
    }
    const position = lastElementPosition
      ? lastElementPosition * 2
      : INITIAL_POSITION;

    setlastElementPosition(position);

    const field_uuid = uuidv4();
    const _element = {
      [field_uuid]: {
        plugin_uid,
        position: position,
        plugin_data: {
          ...plugin_data,
          name: field_uuid,
          label: "Ask your question",
        },
        label: answerType,
        uuid: field_uuid,
      },
    };
    if (props.onElementCreate) {
      const elementId = await props.onElementCreate(_element);
      if (elementId) {
        dispatch({
          type: "CREATE_FIELD",
          payload: { [field_uuid]: { ..._element[field_uuid], id: elementId } },
        });
        executeScroll();
      }
    }
  };

  const deleteElement = async (element: IElement) => {
    const { uuid } = element;
    if (props.onElementDelete) {
      const deleteResponse = await props.onElementDelete(element);
      if (deleteResponse) {
        dispatch({
          type: "DELETE_FIELD",
          payload: uuid,
        });
      }
    }
  };

  const updateElement = (element: IElement) => {
    console.log("props in form builder", element);
    if (props.onElementUpdate) {
      props.onElementUpdate(element);
    }
  };

  const executeScroll = () => {
    setTimeout(() => {
      bottomRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    });
  };

  const Menu = <ComponentList addAnswerType={addAnswerType} />;

  useEffect(() => {
    console.log("**************** CT FORM BUILDER RE-RENEDERED *********");
  }, []);

  return (
    <div className="app">
      <Layout>
        <Layout.Content className="layout__content">
          <Space direction="vertical" style={{ display: "flex" }}>
            <FormHead onFormDetailsUpdate={props.onFormDetailsUpdate} />
            {Boolean(elementsLength) && (
              <FormBody
                onElementDelete={deleteElement}
                handleImageDelete={props.handleImageDelete}
                handleImageUpload={props.handleImageUpload}
                onElementUpdate={(element: IElement) => updateElement(element)}
                onElementPositionUpdate={props.onElementPositionUpdate}
                debounce={props.debounce}
              />
            )}
          </Space>
        </Layout.Content>
        <div ref={bottomRef}></div>
      </Layout>
      <NavBar addAnswerType={addAnswerType} />
    </div>
  );
}

interface RootState {
  form: IFormData;
}

interface IAction {
  type: string;
  payload: any;
}

const mapState = (state: RootState) => ({
  elementsLength: state.form.elementIds.length,
});

const mapDispatch = {
  dispatch: (action: IAction) => action,
};

export const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Builder = connector(CTFormBuilder);

interface Props {
  getFormData?: (formData: IFormData) => void;
  formData?: IFormData;
  onElementDelete?: (element: IElement) => number;
  onElementUpdate?: (element: IElement) => void;
  onElementPositionUpdate?: (element: IElement) => void;
  onFormDetailsUpdate: (data: IFormDetails) => void;
  updatedFormData?: (formData: IFormData) => void;
  onElementCreate?: (element: object) => number;
  handleImageDelete: (imageKey: string) => number;
  handleImageUpload: (element: IElement) => IElement;
  debounce?: number;
  maxElements?: number;
}
export default function FormBuilder(props: Props) {
  const accessToken = localStorage.getItem("accessToken");
  if(!accessToken){
    window.location.pathname='/login'
  }
  
  return (
    <Provider store={store}>
      <Builder {...props} />
    </Provider>
  );
}
