import React, { useEffect, useState } from "react";

import {
  Button,
  message,
  Select,
  Spin,
  Tag,
  Tooltip,
  Card,
  Space,
  Image,
} from "antd";
import axios from "axios";

import PreviewTemplate from "./PreviewTemplate";
import { FALLBACK_IMAGE } from "../../../../shared/constants/constants";

const { Option } = Select;

const FormFromTemplate = (props: any) => {
  const [templates, setTemplates] = useState<any>(null);

  const [category, setCategory] = useState<any>();

  const [selectedCategory, setSelectedCategory] = useState<any>();

  const [tags, setTags] = useState<any>();

  const [selectedTags, setSelectedTags] = useState<any>();

  const [domains, setDomains] = useState<any>();

  const [selectedDomains, setSelectedDomains] = useState<any>();

  const [loading, setLoading] = useState<boolean>(false);

  const [templateToPreview, setTemplateToPreview] = useState<any>(null);

  const [loadMoreSpinner, setLoadMoreSpinner] = useState<boolean>(false);

  const [isMoreFilesAvailable, setIsMoreFilesAvailable] =
    useState<boolean>(true);

  const [nextToken, setNextToken] = useState<any>();

  const getAllTemplates = async (setLoading: any) => {
    setTemplates([]);

    setLoading(true);

    const url = await getURLForFetchingTemplates();

    await axios
      .get(await url)
      .then((res) => {
        console.log("---- res ---- ", res.data);

        getFeatureImages(res.data.data, []);

        if (res.data.next == null) {
          setIsMoreFilesAvailable(false);
        } else {
          setIsMoreFilesAvailable(true);
          setNextToken(res.data.next);
        }
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Could not get Forms.");
        setLoading(false);
      });
  };

  const getMoreTemplates = async () => {
    setLoading(true);

    const url = await getURLForFetchingTemplates();

    await axios
      .get(await url)
      .then((res) => {
        getFeatureImages(res.data.data, templates);

        if (res.data.next == null) {
          setIsMoreFilesAvailable(false);
        } else {
          setIsMoreFilesAvailable(true);
          setNextToken(res.data.next);
        }

        setLoadMoreSpinner(false);

        setLoading(false);
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Could not get Forms.");
        setLoading(false);
      });
  };

  const resolveAWSFilePathForDownload = async (media_key: string) => {
    const imageUrl = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/base/get-s3-url/?key=${media_key}`
    );
    return imageUrl.data.url;
  };

  const getFeatureImages = async (
    templatesData: any,
    existingTemplates: any
  ) => {
    const newTemplates: any = [];

    console.log("---> new template ----> ", templatesData);

    if (templatesData && templatesData.length > 0) {
      for (let i = 0; i < templatesData.length; i++) {
        const localData = await templatesData[i];

        if (localData?.metadata?.featured_picture) {
          localData.metadata.featured_picture =
            await resolveAWSFilePathForDownload(
              localData.metadata.featured_picture
            );
        }

        newTemplates.push(await localData);
      }

      if (existingTemplates && existingTemplates.length > 0) {
        const newData = await existingTemplates.concat(await newTemplates);
        setTemplates(await newData);
      } else {
        setTemplates(newTemplates);
      }
    }
    setLoading(false);
  };

  const getURLForFetchingTemplates = async () => {
    let url = `${process.env.REACT_APP_SERVER_URL}/api/templates/?size=10&page=1`;

    if (
      nextToken &&
      isMoreFilesAvailable &&
      !selectedCategory &&
      !selectedTags &&
      !selectedDomains
    ) {
      url = nextToken;
    } else {
      if (selectedCategory) {
        url = await url.concat(`&category=${selectedCategory.value}`);
      }

      if (selectedTags) {
        url = await url.concat(`&tag=${selectedTags.value}`);
      }

      if (selectedDomains) {
        url = await url.concat(`&domain=${selectedDomains.value}`);
      }
    }
    return url;
  };

  useEffect(() => {
    getAllTemplates(setLoading);
    getAllCategories();
    getAllDomains();
    getAllTags();
  }, []);

  // useEffect(() => {
  //   console.log("templates recieved ---> ", templates);
  // }, [templates]);

  // useEffect(() => {
  //   console.log("Vlue of template to preview, ", templateToPreview);
  //   getAllTemplates(setLoading);
  // }, [templateToPreview]);

  useEffect(() => {
    setTemplates(null);
    getAllTemplates(setLoading);
  }, [selectedCategory, selectedTags, selectedDomains]);

  const getAllCategories = async () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/base/categories/`;

    await axios
      .get(url)
      .then((result) => {
        setCategory(result.data.data);
        return result;
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Could not get form data.");
        setLoading(false);
      });
  };

  const getAllTags = async () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/base/tags/`;

    await axios
      .get(url)
      .then((result) => {
        setTags(result.data.data);
        return result;
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Could not get form data.");
        setLoading(false);
      });
  };

  const getAllDomains = async () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/base/domains/`;

    await axios
      .get(url)
      .then((result) => {
        setDomains(result.data.data);
        return result;
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Could not get form data.");
        setLoading(false);
      });
  };

  const handleUseTemplate = async (templateId: string) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/templates/${templateId}/create-form/`;

    await axios
      .post(url)
      .then((res) => {
        console.log("---- res ---- ", res.data);

        message.success("Form created successfully.");
        window.location.href = `/forms/${res.data.uuid}/builder`;
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Could not get Forms.");
      });
  };

  const menuItems = [
    {
      placeholder: "Domains",
      value: selectedDomains,
      onChange: (e: any) => {
        setSelectedDomains(e);
      },
      state: domains,
    },
    {
      placeholder: "Tags",
      value: selectedTags,
      onChange: (e: any) => {
        setSelectedTags(e);
      },
      state: tags,
    },
    {
      placeholder: "Category",
      value: selectedCategory,
      onChange: (e: any) => {
        setSelectedCategory(e);
      },
      state: category,
    },
  ];

  const isMobileView = window.innerWidth < 768;
  if (loading) <Spin />;

  return (
    <>
      {templateToPreview === null ? (
        <>
          <h3 className="ct_forms_h3">Choose from template</h3>
          <div
            style={{
              display: "flex",
              flexDirection: isMobileView ? "column" : "row",
            }}
          ></div>
          {menuItems.map((item: any) =>
            item ? (
              <Select
                style={{
                  width: isMobileView ? "100%" : "300px",
                  margin: isMobileView ? "0px" : "0px 20px",
                  marginBottom: isMobileView ? "10px" : "0px",
                }}
                placeholder={item.placeholder}
                labelInValue
                allowClear
                value={item.value}
                onChange={item.onChange}
              >
                {item.state &&
                  item.state.length > 0 &&
                  item.state.map((option: any) => (
                    <Option
                      key={option.id}
                      value={option.id}
                      label={option.name}
                      title={option.name}
                    >
                      {option.name}
                    </Option>
                  ))}
              </Select>
            ) : null
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: isMobileView ? "10px 0px" : "20px",
            }}
          >
            {selectedDomains && (
              <h3>
                Selected Domain: <Tag>{selectedDomains.label}</Tag>
              </h3>
            )}
            {selectedTags && (
              <h3>
                Selected Tag: <Tag>{selectedTags.label}</Tag>
              </h3>
            )}
            {selectedCategory && (
              <h3>
                Selected Category: <Tag>{selectedCategory.label}</Tag>
              </h3>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexFlow: "row wrap",
            }}
          >
            {templates &&
              templates.length > 0 &&
              templates.map((template: any, index: number) => (
                <Card
                  style={{
                    width: "300px",
                    maxHeight: "400px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    margin: isMobileView ? 0 : 20,
                    marginTop: 0,
                    marginBottom: isMobileView ? 20 : 40,
                  }}
                >
                  <p
                    style={{
                      marginBottom: 5,
                      fontSize: "20px",
                    }}
                  >
                    <b>{template.title}</b>
                  </p>
                  <Tooltip title="Preview Form">
                    <div
                      style={{
                        cursor: "pointer",
                        marginTop: 10,
                      }}
                      onClick={() => {
                        setTemplateToPreview(template);
                      }}
                    >
                      <Space>
                        <Image
                          src={
                            template.metadata.featured_picture || FALLBACK_IMAGE
                          }
                          style={{
                            maxHeight: 200,
                            maxWidth: 200,
                            alignSelf: "left",
                          }}
                          fallback={FALLBACK_IMAGE}
                          preview={false}
                        />
                      </Space>
                    </div>
                  </Tooltip>

                  <Button
                    style={{
                      margin: "10px 0px",
                    }}
                    className="ct_forms_secondary_button"
                    onClick={() => handleUseTemplate(template.uuid)}
                  >
                    Use Template
                  </Button>
                </Card>
              ))}
            {isMoreFilesAvailable && (
              <Button
                style={{ margin: "5px", width: "100px", marginBottom: "10px" }}
                onClick={() => {
                  getMoreTemplates();
                }}
                disabled={loadMoreSpinner}
              >
                {loadMoreSpinner ? <Spin /> : "Load More"}
              </Button>
            )}
          </div>
        </>
      ) : (
        <PreviewTemplate
          templateToPreview={templateToPreview}
          setTemplateToPreview={setTemplateToPreview}
          handleUseTemplate={handleUseTemplate}
        />
      )}
    </>
  );
};

export default FormFromTemplate;
