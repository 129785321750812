import React, { useState } from "react";

import { Button, Input, Modal } from "antd";
import MDEditor from "@uiw/react-md-editor";

import "./FormHead.css";

interface IProps {
  title: string;
  description: string;
}

function FormHead(props: IProps) {
  const { title, description } = props;
  const [showFullscreen, setShowFullscreen] = useState(false);
  const isMobileView = window.innerWidth < 768;

  return (
    <div
      style={{
        width: "100%",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        padding: 10,
        paddingLeft: 20,
        alignItems: "flex-start",
        background: "#f7f7f8",
        borderRadius: "24px 24px 0px 0px",
        marginBottom: 20,
      }}
    >
      <Input.TextArea
        autoSize
        bordered={false}
        value={title}
        placeholder="Title"
        maxLength={50}
        style={{
          fontSize: 40,
          fontWeight: 600,
          color: "#272E35",
          wordBreak: "break-word",
        }}
      />
      <MDEditor.Markdown
        source={description}
        style={{
          maxHeight: 200,
          width: "100%",
          overflowY: "scroll",
          textAlign: "left",
          marginLeft: "12px",
          paddingRight: "12px",
          wordBreak: "break-word",
          maxWidth: "80vw",
        }}
      />
      <Button
        className="ct_forms_secondary_button"
        style={{
          marginTop: 15,
          marginLeft: 4,
        }}
        onClick={() => setShowFullscreen(!showFullscreen)}
      >
        View description
      </Button>
      <Modal
        visible={showFullscreen}
        onCancel={() => setShowFullscreen(false)}
        footer={null}
        width="95vw"
        style={{
          margin: "auto",
          marginBottom: 30,
          top: isMobileView ? 10 : 30,
          left: isMobileView ? 10 : 34,
          position: "fixed",
        }}
      >
        <MDEditor.Markdown
          source={description}
          style={{
            maxHeight: isMobileView ? "85vh" : "82vh",
            width: "100%",
            overflowY: "scroll",
            textAlign: "left",
            wordBreak: "break-word",
            marginTop: 30,
          }}
        />
      </Modal>
    </div>
  );
}

export default FormHead;
