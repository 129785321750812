import React, { useEffect, useState, useCallback } from "react";
import { CTCard, CTComponents } from "./CTComponents";
import {
  Space,
  Button,
  Switch,
  Typography,
  Input,
  Popconfirm,
  Modal,
  Divider,
  Image,
  Dropdown,
  Menu,
  Form,
} from "antd";

import ElementTypeDropdown from "./ElementTypeDropdown";
import {
  DeleteOutlined,
  DragOutlined,
  FileImageOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import _ from "lodash";

import { IElement } from "../utils/interfaces";
import {
  DEBOUNCE_DELAY,
  FALLBACK_IMAGE,
  IMAGE_REPLACE_MESSAGE,
} from "../constants";
import UploadLogo from "./UploadLogo";
import { connect, ConnectedProps } from "react-redux";
import { IFormData } from "../utils/interfaces";
import message from "../utils/message";
import FormItem from "./CTComponents/ElementPreview/FormItem";

import "./QuestionCard.css";

interface IProps extends PropsFromRedux {
  deleteElement: (element: IElement) => void;
  onElementUpdate: (element: IElement) => void;
  handleImageDelete: (imageKey: string) => number;
  handleImageUpload: (element: IElement) => IElement;
  debounce?: number;
  elementUuid: string;
}

function QuestionCard(props: IProps) {
  const { deleteElement, dispatch, element, isEditAllowed } = props;

  const [selectedLabel, setSelectedLabel] = useState(element.label);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    setSelectedLabel(element.label);
  }, [element]);

  const SelectedComponent = CTComponents[selectedLabel!]["component"];

  const updateFieldValue = (fieldObject: object) => {
    const elementValue = {
      ...element,
      plugin_data: { ...element.plugin_data, ...fieldObject },
    };

    if (!element.plugin_data) return;
    dispatch({
      type: "UPDATE_FIELD_VALUE",
      payload: {
        [element.plugin_data.name]: elementValue,
      },
    });
    updateElement(elementValue);
  };

  const updateElement = useCallback(
    _.debounce(
      (elementValue) => props.onElementUpdate(elementValue),
      (props.debounce ?? DEBOUNCE_DELAY) * 1000
    ),
    []
  );

  useEffect(() => {
    console.log("**************** QUESTION CARD RE-RENEDERED *********");
  }, []);

  const handleDeleteConfirm = () => {
    if (!element.plugin_data) return;
    deleteElement(element);
  };

  const handleDeleteCancel = () => {};

  const changeImageSize = (sizePercent: string) => {
    dispatch({
      type: "CHANGE_QUESTION_IMAGE_SIZE",
      payload: {
        [element.uuid as string]: {
          ...element,
          questionImageSize: sizePercent,
        },
      },
    });
    updateElement({ ...element, questionImageSize: sizePercent });
  };

  const handleDeleteQuestionImage = async () => {
    const deleteResult = await props.handleImageDelete(
      element.questionImageKey as string
    );
    if (deleteResult) {
      const _element = { ...element };
      delete _element.questionImageKey;
      delete _element.questionImageUrl;
      delete _element.questionImageSize;

      delete _element.metadata?.questionImageKey;
      delete _element.metadata?.questionImageSize;

      props.dispatch({
        type: "DELETE_QUESTION_IMAGE",
        payload: {
          [_element.uuid as string]: {
            ..._element,
          },
        },
      });
      updateElement(_element);
    }
  };

  if (!element.plugin_data) return null;

  const MenuButtonsForPhone = (
    <>
      <Space
        style={{
          display: "flex",
          flex: 1,
          width: "100%",
        }}
      >
        <Input
          style={{
            fontSize: 24,
            fontWeight: 600,
            color: "#272E35",
            wordBreak: "break-word",
          }}
          bordered={false}
          placeholder="label"
          disabled={!isEditAllowed}
          value={element.plugin_data?.label}
          maxLength={150}
          onChange={(e) => updateFieldValue({ label: e.target.value })}
          onBlur={(e) => {
            if (!e.target.value)
              updateFieldValue({ label: "Ask your question" });
          }}
        />
        <Button type="link" className="handle" style={{ cursor: "grab" }}>
          <DragOutlined />
        </Button>
      </Space>
      <Space
        style={{
          paddingTop: "5px",
          flex: 1,
        }}
      >
        <Button
          type="link"
          onClick={() =>
            element.questionImageUrl
              ? message.warn(IMAGE_REPLACE_MESSAGE)
              : setIsModalOpen(true)
          }
          disabled={!isEditAllowed}
        >
          <FileImageOutlined />
        </Button>
        {isModalOpen && (
          <Modal
            title="Upload Image"
            visible={isModalOpen}
            onCancel={() => setIsModalOpen(false)}
            footer={null}
          >
            <UploadLogo
              element={element}
              setIsModalOpen={setIsModalOpen}
              handleImageUpload={props.handleImageUpload}
              updateElement={updateElement}
            />
          </Modal>
        )}
        <ElementTypeDropdown
          elementTypeLabels={Object.keys(CTComponents)}
          element={element}
          componentDisabled={!isEditAllowed}
          updateElement={updateElement}
        />
      </Space>
    </>
  );

  const MenuButtonsForDesktop = (
    <>
      <Space
        style={{
          display: "flex",
          flex: 1,
          width: "100%",
        }}
      >
        <Input
          style={{
            fontSize: 24,
            fontWeight: 600,
            color: "#272E35",
            wordBreak: "break-word",
          }}
          bordered={false}
          placeholder="label"
          disabled={!isEditAllowed}
          value={element.plugin_data?.label}
          maxLength={150}
          onChange={(e) => updateFieldValue({ label: e.target.value })}
          onBlur={(e) => {
            if (!e.target.value)
              updateFieldValue({ label: "Ask your question" });
          }}
        />
        <Button
          type="link"
          onClick={() =>
            element.questionImageUrl
              ? message.warn(IMAGE_REPLACE_MESSAGE)
              : setIsModalOpen(true)
          }
          disabled={!isEditAllowed}
        >
          <FileImageOutlined />
        </Button>
        {isModalOpen && (
          <Modal
            title="Upload Image"
            visible={isModalOpen}
            onCancel={() => setIsModalOpen(false)}
            footer={null}
          >
            <UploadLogo
              element={element}
              setIsModalOpen={setIsModalOpen}
              handleImageUpload={props.handleImageUpload}
              updateElement={updateElement}
            />
          </Modal>
        )}
        <ElementTypeDropdown
          elementTypeLabels={Object.keys(CTComponents)}
          element={element}
          componentDisabled={!isEditAllowed}
          updateElement={updateElement}
        />
        <Button type="link" className="handle" style={{ cursor: "grab" }}>
          <DragOutlined />
        </Button>
      </Space>
    </>
  );

  return (
    <>
      <div
        className="question_card_container"
        onClick={() => dispatch({ type: "SELECT_ELEMENT", payload: element })}
      >
        <CTCard
          style={{
            pointerEvents: isEditAllowed ? "" : "none",
            backgroundColor: "#f7f7f8",
            borderRadius: "10px",
            boxShadow: isEditAllowed && "5px 5px 2px 2px lightgray",
            marginBottom: "10px",
          }}
          actions={[]}
        >
          <Space direction="vertical" style={{ display: "flex" }}>
            <>
              {window.innerWidth < 768
                ? MenuButtonsForPhone
                : MenuButtonsForDesktop}
            </>
            {Boolean(element.questionImageUrl) && (
              <>
                <Space size={1} align="start">
                  <Dropdown
                    trigger={["click"]}
                    overlay={
                      <Menu>
                        <Menu.ItemGroup title="Image Size">
                          <Menu.Item onClick={() => changeImageSize("25%")}>
                            Small
                          </Menu.Item>
                          <Menu.Item onClick={() => changeImageSize("50%")}>
                            Medium
                          </Menu.Item>
                          <Menu.Item onClick={() => changeImageSize("")}>
                            Original
                          </Menu.Item>
                        </Menu.ItemGroup>
                        <Menu.ItemGroup title="Actions">
                          <Menu.Item onClick={handleDeleteQuestionImage}>
                            Delete
                          </Menu.Item>
                        </Menu.ItemGroup>
                      </Menu>
                    }
                  >
                    <MoreOutlined style={{ fontSize: 20 }} />
                  </Dropdown>
                  <Image
                    src={element.questionImageUrl}
                    height={element.questionImageSize}
                    width={element.questionImageSize}
                    alt="Question Image"
                    fallback={FALLBACK_IMAGE}
                  />
                </Space>
                <Divider />
              </>
            )}
            <Space style={{ display: "flex" }} wrap>
              <SelectedComponent
                updateFieldValue={updateFieldValue}
                state={element}
                componentDisabled={!isEditAllowed}
                disabled
                handleImageUpload={props.handleImageUpload}
                handleImageDelete={props.handleImageDelete}
                updateElement={updateElement}
                deleteButton={
                  <Popconfirm
                    title="Are you sure to delete this?"
                    onConfirm={handleDeleteConfirm}
                    onCancel={handleDeleteCancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="link" disabled={!isEditAllowed}>
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                }
                extraProps={
                  <>
                    <FormItem label={"Help Text"}>
                      <Input
                        bordered={false}
                        value={element.plugin_data.help_text}
                        maxLength={50}
                        onChange={(e) =>
                          updateFieldValue({ help_text: e.target.value })
                        }
                      />
                    </FormItem>
                    <FormItem
                      label="Field Required?"
                      elementStyle={{
                        background: element.plugin_data.required
                          ? "#FF6E30"
                          : "#969DB1",
                      }}
                    >
                      <Switch
                        checked={element.plugin_data.required}
                        onChange={(checked, e) =>
                          updateFieldValue({ required: checked })
                        }
                      />
                    </FormItem>
                  </>
                }
              ></SelectedComponent>
            </Space>
          </Space>
        </CTCard>
      </div>
    </>
  );
}

interface RootState {
  form: IFormData;
}

interface IAction {
  type: string;
  payload: any;
}

const mapState = (state: RootState, props: any) => {
  const element = state.form.elements[props.elementUuid];
  const isEditAllowed = state.form.selectedElement?.uuid === element.uuid;
  return { element, isEditAllowed };
};

const mapDispatch = {
  dispatch: (action: IAction) => action,
};

export const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(QuestionCard);
