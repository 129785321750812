import React from "react";
import { Space, Input, Divider, Image, Card, Form } from "antd";

import { FALLBACK_IMAGE } from "../../../shared/constants/constants";
import { IElement } from "../../core/interfaces/interfaces";

import "./ElementCard.css";

interface IProps {
  element: IElement;
  currentElement: React.ReactChild;
}

function ElementCard(props: IProps) {
  const { element } = props;
  return (
    <>
      <div className="question_card_container">
        <Card
          style={{
            backgroundColor: "#f7f7f8",
            borderRadius: "10px",
            marginBottom: "10px",
          }}
          actions={[]}
        >
          <Space direction="vertical" style={{ display: "flex" }}>
            <Space
              style={{
                display: "flex",
                flex: 1,
                width: "100%",
              }}
            >
              <Input.TextArea
                autoSize
                style={{
                  fontSize: 24,
                  fontWeight: 600,
                  color: "#272E35",
                  wordBreak: "break-word",
                }}
                bordered={false}
                placeholder="label"
                value={element.plugin_data?.label}
                maxLength={150}
              />
            </Space>
            {Boolean(element.questionImageUrl) && (
              <>
                <Space size={1} align="start">
                  <Image
                    src={element.questionImageUrl}
                    height={element.questionImageSize}
                    width={element.questionImageSize}
                    alt="Question Image"
                    fallback={FALLBACK_IMAGE}
                  />
                </Space>
                <Divider />
              </>
            )}
          </Space>
          <Card
            size="small"
            style={{ margin: "10px 0px", padding: 10, borderRadius: "24px" }}
          >
            {props.currentElement}
          </Card>
        </Card>
      </div>
    </>
  );
}

export default ElementCard;
