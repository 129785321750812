import React, { useEffect, useState } from "react";

import { Card, Col, message, Row, Select } from "antd";

import axios from "axios";

import FormSubmissionStats from "./FormSubmissionStats";
import { StateType } from "../../core/redux/types";
import { connect } from "react-redux";

const { Option } = Select;

const FormAnalytics = (props: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>();

  const [selectedForm, setSelectedForm] = useState<any>();

  const handleChange = (e: any) => {
    console.log("handle change event ", e);
    setSelectedForm(e);
  };

  const getAllForms = async () => {
    setLoading(true);

    const url = props.state.auth.userData.is_staff
      ? `${process.env.REACT_APP_SERVER_URL}/api/templates/?size=1000`
      : `${process.env.REACT_APP_SERVER_URL}/api/custom_forms/?size=1000`;

    await axios
      .get(url)
      .then((res) => {
        setFormData(res.data.data);
        setLoading(false);
        if (res.data.data.length > 0) {
          const defaultData = {
            key: res.data.data[0].uuid,
            title: res.data.data[0].title,
            value: res.data.data[0].uuid,
          };
          setSelectedForm(defaultData);
        }
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Could not get Forms.");
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllForms();
  }, []);

  return (
    <>
      {formData && formData.length > 0 ? (
        <Card
          title="Analytics"
          style={{
            width: "80vw",
            marginTop: "10px",
            marginBottom: "10px",
            overflow: "auto",
            padding: "0px",
          }}
        >
          <Row gutter={24}>
            <Col span={24}>
              {selectedForm && (
                <Select
                  style={{ marginBottom: "20px", width: "200px" }}
                  labelInValue
                  onChange={handleChange}
                  defaultValue={selectedForm}
                  placeholder={selectedForm.title}
                >
                  {formData &&
                    formData.map((form: any) => (
                      <Option value={form.uuid} key={form.uuid}>
                        {form.title}
                      </Option>
                    ))}
                </Select>
              )}
              {selectedForm && <FormSubmissionStats formData={selectedForm} />}
            </Col>
          </Row>
        </Card>
      ) : (
        <Card
          title="Form Analytics"
          style={{ width: "80vw", marginTop: "10px", marginBottom: "10px" }}
        >
          No forms have been created
        </Card>
      )}
    </>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(FormAnalytics);
