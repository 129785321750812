import React, { useState, useEffect } from "react";

import { Button, Card, Divider, Form, Input, Space, Typography } from "antd";

const WebhookSettings = (props: any) => {
  const [webhookUrl, setWebhookUrl] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isUrlValid, setIsUrlValid] = useState(false);

  const { createFormHandlers, handlerData } = props;

  const onSave = async () => {
    const webhookData = {
      endpoint_url: webhookUrl,
    };

    await createFormHandlers({
      plugin_uid: "http_repost",
      plugin_data: webhookData,
    });
  };


  const validateUrl = () => {

    if (!/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(
      webhookUrl
    )
    ) {
      if (webhookUrl) {
        setErrorMessage("Invalid URL");
      } else {
        setErrorMessage("");
      }
      setIsUrlValid(false);
    } else {
      setErrorMessage("");
      setIsUrlValid(true);

    }
  }

  useEffect(() => {
    validateUrl();
  }, [webhookUrl]);

  useEffect(() => {
    if (handlerData) {
      const webhookHandlerData = handlerData.filter(
        (handler: { plugin_uid: string }) =>
          handler.plugin_uid === "http_repost"
      );

      if (webhookHandlerData.length > 0) {
        const pluginData = webhookHandlerData[0].plugin_data;

        setWebhookUrl(pluginData.endpoint_url);
      }
    }
  }, [handlerData]);

  return (
    <Space
      direction="vertical"
      size={4}
      style={{
        textAlign: "left",
      }}
    >

      <Typography.Title
        style={{
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: "40px",
          lineHeight: "48px",
          color: "#272E35",
          marginLeft: 20
        }}
      >
        Webhook
      </Typography.Title>
      <Card
        style={{
          background: "#F7F7F8",
          borderRadius: "24px 24px 0px 0px",
        }}
      >
        <Space direction="vertical">
          <Typography.Text
            style={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "20px",
              lineHeight: "24px",
              color: "#272E35",
            }}
          >
            Add Webhook URL
          </Typography.Text>
          <Typography.Text
            style={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "bolder",
              fontSize: "16px",
              lineHeight: "24px",
              color: "#272E35",
            }}
          >
            Settings for custom webhook to connect
            with other applications
          </Typography.Text>
        </Space>
        <Divider />
        <Form layout="vertical">
          <Form.Item label="Webhook URL"
            help="Enter url in http://www.url.com format"
          >
            <Input
              placeholder="http://www.url.com"
              value={webhookUrl}
              onChange={(e: any) => setWebhookUrl(e.target.value)}
              maxLength={100}
            />
          </Form.Item>
          {errorMessage && (
            <p style={{
              color: "red",
            }}>{errorMessage}</p>
          )}
        </Form>
        <Divider />
        <Button type="primary" onClick={() => onSave()}
          disabled={!isUrlValid}
        >
          Save
        </Button>
      </Card>
    </Space>
  );
};

export default WebhookSettings;
