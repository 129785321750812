import React, { useState } from "react";

import { Col, Layout, message, Row } from "antd";

import axios from "axios";

import ElementSpinner from "../../../shared/customComponents/spinner/ElementSpinner";

import ChangePasswordForm from "../../components/changePassword/ChangePasswordForm";

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);

  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  const [apiError, setApiError] = useState<any>();

  const onFinish = async (values: any) => {
    setLoading(true);
    if (!password1 || !password2) {
      message.error("Fields cannot be empty.");
      setApiError(["Fields cannot be empty."]);
      setLoading(false);
    } else {
      await updateUserProfile(setLoading);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const updateUserProfile = async (setLoading: any) => {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_SERVER_URL}/auth/password/change/`, {
        new_password1: password1,
        new_password2: password2,
      })
      .then((res) => {
        setLoading(false);
        message.success("Password updated successfully.");
        setApiError([]);
      })
      .catch((err: any) => {
        setLoading(false);
        message.error("Could not update password.");

        const errorsList: any[] = [];

        for (const [key, value] of Object.entries(err.response.data)) {
          console.log(`${key}: ${value}`);
          message.error(`${value}`);
          errorsList.push(value);
        }

        setApiError(errorsList);
      });
  };

  return (
    <ElementSpinner loading={loading} fixedToCentre={false}>
      <Layout className="profile-layout">
        <div>
          <br />
          <Row
            style={{
              width: "100%",
              backgroundColor: "yellow",
            }}
          >
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <div
                style={{
                  width: "auto",
                }}
              >
                <ChangePasswordForm
                  password1={password1}
                  password2={password2}
                  onFinishFailed={onFinishFailed}
                  onFinish={onFinish}
                  setPassword1={setPassword1}
                  setPassword2={setPassword2}
                  apiError={apiError}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Layout>
    </ElementSpinner>
  );
};

export default ChangePassword;
