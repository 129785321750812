import React from "react";

import { Table } from "antd";

const TableForMobile = (props: any) => {
  const { columns, bordered,dataSource: data, loading,rowSelectionTable,rowClassName,expandable } = props;
  return (
    <div
      style={{
        width: "95vw",
        overflowX: "scroll",
      }}
    >
      <Table
        rowClassName={rowClassName}
        expandable={expandable}
        showHeader={true}
        tableLayout="fixed"
        columns={columns}
        rowSelection={rowSelectionTable}
        dataSource={data}
        bordered={bordered}
        pagination={false}
        size="small"
        sticky={true}
        loading={loading}
        scroll={{ x: "auto", y: "100vh" }}
      />
    </div>
  );
};

export default TableForMobile;
