import React, { useEffect, useState } from "react";

import { Button, Card, Divider, Input, message, Spin } from "antd";
import axios from "axios";
import { useParams } from "react-router-dom";

const FeaturedImage = (props: any) => {
  const { formData, getFormByUuid } = props;
  const { metadata } = props;

  const [logoUrl, setLogoUrl] = useState<null | string>(null);
  const [loading, setLoading] = useState(false);

  const [selectedFile, setSelectedFile] = useState<any>(null);

  const params = useParams<any>();

  const { uuid } = params;

  useEffect(() => {
    console.log(
      "Hello this and that ---->",
      formData?.metadata?.featured_picture
    );
    if (
      props.formData &&
      props.formData.metadata &&
      props.formData.metadata.featured_picture
    ) {
      console.log("inside if");
      resolveAWSFilePathForDownload(props.formData.metadata.featured_picture);
    }
  }, [formData, metadata, props]);

  const resolveAWSFilePathForDownload = async (media_key: string) => {
    console.log("resolve shit was called --->");

    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/api/base/get-s3-url/?key=${media_key}`
      )
      .then(async (result1) => {
        console.log("image URL", result1.data.url);
        setLogoUrl(result1.data.url);
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
      });
  };

  const onFileChange = (e: any) => {
    if (e.target.files.length > 0) {
      if (e.target.files[0].type.match("image")) {
        const fileSize = e.target.files[0].size;

        if (fileSize > 5000000) {
          message.error("Max Image size 5MB allowed.");
        } else {
          setSelectedFile(e.target.files[0]);
          onFileUpload(e.target.files[0]);
          setLogoUrl(URL.createObjectURL(e.target.files[0]));
        }
      } else {
        message.error("This file type is not supported.");
      }
    } else {
      message.error("Please select a file to upload");
    }
  };

  const attachHeaders = async (data: any) => {
    const formData = new FormData();

    Object.entries(data).forEach((dataItem: any) => {
      formData.append(dataItem[0], dataItem[1]);
    });

    return formData;
  };

  const onFileUpload = async (file: any) => {
    const selectedFile = file;

    if (selectedFile) {
      const file = selectedFile.name !== "" ? selectedFile : "  ";
      const fileName = selectedFile.name !== "" ? selectedFile.name : "  ";

      const extenstion = selectedFile.name
        .substr(selectedFile.name.lastIndexOf(".") + 1)
        .split(".")[0];

      const keys = await getAWSTokenForLogoUpload(extenstion);

      const data = await keys.fields;

      const key = data.key;

      const formData: FormData = await attachHeaders(data);
      formData.append("file", file);

      if (formData) {
        const result = await uploadFileToAWS(keys.url, await formData, key);
        console.log("Result from AWS -----> ", result);
      }

      const data2 = await updateServerWithLogoUploadData(key);

      console.log("data 2 --> ", data2);
    } else {
      message.error("No image selected.");
    }
  };

  const getAWSTokenForLogoUpload = async (extenstion: string) => {
    setLoading(true);

    let result;

    try {
      result = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/base/token/?file_extension=${extenstion}`
      );
    } catch (err) {
      console.log("errr", err);
      message.error("Logo upload failed.");
      setLoading(false);
    }

    console.log("result in aws token --> ", result?.data);
    return result?.data?.data;
  };

  const uploadFileToAWS = async (url: string, formData: any, key: string) => {
    var instance = axios.create();
    delete instance.defaults.headers.common["Authorization"];

    return instance
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("after file upload", response.data);
        return key;
      })
      .catch((err) => {
        console.log(err);
        message.error("Logo upload failed.");
        setLoading(false);
      });
  };

  const updateServerWithLogoUploadData = async (logo_key: string) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/templates/${uuid}/`;

    await axios
      .put(url, { metadata: { featured_picture: logo_key } })
      .then((res: any) => {
        console.log("---- res ---- ", res);
        message.success("Form details were updated.");
        setLoading(false);
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Form details update failed.");
        setLoading(false);
      });
  };

  const removeFeaturedImage = async () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/templates/${uuid}/`;

    await axios
      .put(url, { metadata: {} })
      .then((res: any) => {
        console.log("---- res ---- ", res);
        message.success("Form details were updated.");
        getFormByUuid();
        setLoading(false);
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Form details update failed.");
        setLoading(false);
      });
  };

  return (
    <Card title={<span> Featured Image </span>} style={{ margin: "10px" }}>
      <p>This will be your featured image</p>
      <div>
        {logoUrl && (
          <div>
            <img
              src={logoUrl}
              alt="company logo"
              style={{ height: "100px", width: "100px", marginBottom: "10px" }}
            />
          </div>
        )}
        <div style={{ display: "flex" }}>
          <label htmlFor="Upload Logo">
            {!loading && (
              <Input
                type="file"
                onChange={(e) => onFileChange(e)}
                style={{ width: "220px", marginRight: 5 }}
              ></Input>
            )}
            {loading && (
              <div>
                Uploading <Spin />
              </div>
            )}
          </label>
        </div>
      </div>

      {logoUrl && (
        <>
          <Divider />
          <Button onClick={() => removeFeaturedImage()}>
            Remove Featured Image
          </Button>
        </>
      )}
    </Card>
  );
};

export default FeaturedImage;
