import React, { useEffect } from "react";

import { BrowserRouter as Router } from "react-router-dom";
import { useDispatch } from "react-redux";

import { setLoggedInUser } from "./auth/core/services/login";
import Routes from "./app/components/routing/Routes";

import "./App.less";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";

import { getCurrentSessionTokens } from "./auth/core/services/session";
import getNewAccessToken from "./auth/core/services/refreshToken";
import { message } from "antd";

import ReactGa from "react-ga";
import { SERVERMODE } from "./shared/constants/constants";

require("dotenv").config();

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  if (localStorage.getItem("accessToken")) {
    const token = localStorage.getItem("accessToken");
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axios.interceptors.request.use((req) => {
  // console.log(`AXIOS CALL : ${req.method} ${req.url}`);

  // Important: request interceptors **must** return the request.
  return req;
});

axios.interceptors.response.use(
  (res) => res,
  (err) => {
    console.log("ERROR from API ---->", err.response);

    if (err.response === undefined) {
      message.error("Your network is offline.");
    }

    if (err.response.status === 401) {
      console.log("calling refresh token");

      console.log("Error in API ", err.response.data.detail);

      const { refreshToken } = getCurrentSessionTokens();
      
      if (refreshToken) {
        console.log("requested refresh token ", refreshToken);
        getNewAccessToken(refreshToken);
      } else {
        window.location.href = "/login";
      }
    }
    

    if (err.response.status === 403) {
      message.error("You do not have permissions to perform this action.");
      return;
    }

    throw err;
  }
);

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    setLoggedInUser(dispatch);
  }, [dispatch]);

  useEffect(() => {
    // ReactGa.initialize("G-FNYCP17D2W");

    console.log("initializing react GA ....");

    ReactGa.initialize("UA-207532738-1");

    // to report page view
    ReactGa.pageview("/app");
  }, []);

  if (process.env.REACT_APP_MODE === SERVERMODE.PRODUCTION) {
    console.log = function () {};
  }

  return (
    <Router>
      <Routes />
    </Router>
  );
};

export default App;
