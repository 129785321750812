import React from "react";

import { Input, InputNumber, Form } from "antd";
import { IElement } from "../../utils/interfaces";
import AdvanceOptionsDrawer from "./ElementPreview/AdvanceOptionsDrawer";
import FormItem from "./ElementPreview/FormItem";

interface IProps {
  value?: string;
  placeholder?: string;
  style?: object;
  max_length?: number;
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  dispatch: ({ type, payload }: { type: string; payload: object }) => void;
  state: IElement;
  componentDisabled?: boolean;
  updateFieldValue: (fieldValue: object) => void;
  deleteButton: React.ReactNode;
  extraProps: React.ReactNode;
}

export default function CTInputNumber(props: IProps) {
  if (!props.state.plugin_data) return null;
  const advancedOptions = (
    <>
      <FormItem label="Placeholder">
        <Input
          value={props.state.plugin_data.placeholder}
          disabled={props.componentDisabled}
          onChange={(e) =>
            props.updateFieldValue({ placeholder: e.target.value })
          }
        />
      </FormItem>
      <FormItem label="Minimum Value" elementStyle={{ width: "100%" }}>
        <InputNumber
          value={props.state.plugin_data.min_value}
          min={-9999999999}
          max={props.state.plugin_data.max_value}
          disabled={props.componentDisabled}
          onChange={(value) => {
            if (value !== null)
              props.updateFieldValue({
                min_value: props.state.plugin_data?.decimal_places
                  ? value
                  : parseInt(value),
              });
          }}
          precision={props.state.plugin_data.decimal_places}
        />
      </FormItem>
      <FormItem label="Maximum Value" elementStyle={{ width: "100%" }}>
        <InputNumber
          value={props.state.plugin_data.max_value}
          min={props.state.plugin_data.min_value}
          max={9999999999}
          disabled={props.componentDisabled}
          onChange={(value) => {
            if (value !== null)
              props.updateFieldValue({
                max_value: props.state.plugin_data?.decimal_places
                  ? value
                  : parseInt(value),
              });
          }}
          precision={props.state.plugin_data.decimal_places}
        />
      </FormItem>
      <FormItem label="Precision" elementStyle={{ width: "100%" }}>
        <InputNumber
          placeholder={"precision"}
          value={props.state.plugin_data.decimal_places}
          min={0}
          max={10}
          disabled={props.componentDisabled}
          onChange={(value) => {
            if (value !== null)
              props.updateFieldValue({ decimal_places: parseInt(value) });
          }}
        />
      </FormItem>
    </>
  );
  return (
    <AdvanceOptionsDrawer
      componentDisabled={props.componentDisabled}
      deleteButton={props.deleteButton}
      advancedOptions={advancedOptions}
      state={props.state}
      extraProps={props.extraProps}
    />
  );
}
