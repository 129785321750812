import React from "react";
import { Card, Typography, Space, Row, Col, Button, message } from "antd";
import ClassicLayout from "./classic.svg";
import CardLayout from "./card.svg";
import axios from "axios";

interface IProps {
  uuid: string;
  layout: string | undefined;
  setLayout: any;
}

function FormLayoutSettings(props: IProps) {
  const { layout, setLayout } = props;

  const onSaveLayout = () => {
    axios
      .patch(
        `${process.env.REACT_APP_SERVER_URL}/api/custom_forms/${props.uuid}/`,
        { metadata: { layout } }
      )
      .then((res) => {
        console.log(`res`, res);
        message.success(`Layout saved successfully`);
      })
      .catch((err) => {
        console.log(`err`, err);
        message.error(`Error saving layout`);
      });
  };
  return (
    <Space
      direction="vertical"
      size={4}
      style={{
        textAlign: "left",
      }}
    >
      <Typography.Title
        style={{
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: "40px",
          lineHeight: "48px",
          color: "#272E35",
        }}
      >
        Layout
      </Typography.Title>
      <Card
        style={{
          background: "#F7F7F8",
          borderRadius: "24px 24px 0px 0px",
        }}
      >
        <Space direction="vertical">
          <Typography.Text
            style={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "20px",
              lineHeight: "24px",
              color: "#272E35",
            }}
          >
            Choose layout styling
          </Typography.Text>
          <Typography.Text
            style={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "bolder",
              fontSize: "16px",
              lineHeight: "24px",
              color: "#272E35",
            }}
          >
            Select layout which provides maximum responses
          </Typography.Text>
          <Row
            gutter={24}
            style={{
              width: "100%",
            }}
          >
            <Col span={12} style={{ width: "100%" }}>
              <Card
                style={{
                  border: layout === "classic" ? "1px solid #000000" : "",
                  paddingRight: 15,
                }}
                onClick={() => setLayout("classic")}
              >
                <img
                  style={{ height: 100, width: 100 }}
                  src={ClassicLayout}
                  alt="template logo"
                />
              </Card>
              <Typography.Title
                style={{
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "20px",
                  lineHeight: "32px",
                  color: "#272E35",
                }}
                level={4}
              >
                Classic
              </Typography.Title>
            </Col>
            <Col span={12} style={{ width: "100%" }}>
              <Card
                style={{
                  border: layout === "card" ? "1px solid #000000" : "",
                  paddingRight: 15,
                }}
                onClick={() => setLayout("card")}
              >
                <img
                  style={{ height: 100, width: 100 }}
                  src={CardLayout}
                  alt="template logo"
                />
              </Card>
              <Typography.Title
                style={{
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "20px",
                  lineHeight: "32px",
                  color: "#272E35",
                }}
                level={4}
              >
                Card
              </Typography.Title>
            </Col>
          </Row>
        </Space>
      </Card>
      <Button
        onClick={onSaveLayout}
        style={{
          background: "#FF6E30",
          borderRadius: "16px",
          marginTop: 20,
          color: "white",
          width: 87,
          height: 56,
        }}
      >
        Save
      </Button>
    </Space>
  );
}

export default FormLayoutSettings;
