import React from "react";

import { Col, Input, Row, Button, Form, Card } from "antd";
import Title from "antd/lib/typography/Title";

const ProfileForm = (props: any) => {
  const {
    onFinish,
    onFinishFailed,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    companyName,
    setCompanyName,
  } = props;

  const styles = { width: "100%" };

  return (
    <Card
      title={<Title level={4}>Profile</Title>}
      style={{
        paddingBottom: "0px",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "20px",
          paddingBottom: "0px",
          width: "100%",
        }}
      >
        <Form
          name="basic"
          initialValues={{ pxember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          style={{
            width: "100%",
          }}
        >
          <Row>
            <Col style={{ width: "100%" }}>
              <Form.Item label={firstName ? "First Name:" : ""}>
                <Input
                  placeholder="First Name"
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  maxLength={50}
                  value={firstName}
                  style={styles}
                />
              </Form.Item>
            </Col>

            <Col style={{ width: "100%" }}>
              <Form.Item label={lastName ? "Last Name:" : ""}>
                <Input
                  placeholder="Last Name"
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  maxLength={50}
                  value={lastName}
                  style={styles}
                />
              </Form.Item>
            </Col>

            <Col style={{ width: "100%" }}>
              <Form.Item label={companyName ? "Company Name:" : ""}>
                <Input
                  placeholder="Company Name"
                  onChange={(e) => {
                    setCompanyName(e.target.value);
                  }}
                  maxLength={100}
                  value={companyName}
                  style={styles}
                />
              </Form.Item>
            </Col>
          </Row>

          <div
            style={{
              paddingBottom: "40px",
            }}
          >
            <Button className="ct_forms_primary_button" type="primary" htmlType="submit">
              Save Details
            </Button>
          </div>
        </Form>
      </div>
    </Card>
  );
};

export default ProfileForm;
