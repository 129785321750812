import React, { useState, useEffect } from "react";

import { message } from "antd";

import FormHead from "../../../containers/formSubmission/FormHead";
import elementsToReturn from "../../../../formBuilder/components/CTComponents/ElementPreview/elementsToReturn";

import axios from "axios";
import ElementCard from "../../formSubmission/ElementCard";

const TemplateElementsPreview = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [elements, setElements] = useState([]);

  const { template } = props;
  const uuid = template.uuid;

  useEffect(() => {
    getElementsByFormUuid();
  }, []);

  const getElementsByFormUuid = async () => {
    if (!loading) setLoading(true);
    console.log("*********** FETCHING FORM ELEMENTS ********************");

    const getS3FilePath = async (media_key: string, field: any) => {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/base/get-s3-url/?key=${media_key}`
        );
        console.log(`result from AWS`, result);
        if (field.questionImageKey) {
          field.questionImageUrl = result.data.url;
        } else {
          field.imageUrl = result.data.url;
        }
      } catch (error) {
        console.log("errors could not be get", error);
        message.error("Could not get form response data.");
      }
    };

    const url = `${process.env.REACT_APP_SERVER_URL}/api/templates/${uuid}/elements/?size=50`;
    axios
      .get(url)
      .then((result1) => {
        console.log("---- result from get elements API ---- ", result1);
        const elementData = result1.data.data;

        console.log("element Data ", elementData);

        let dictionary;
        dictionary = Object.assign(
          {},
          ...elementData.map((x: any) => ({
            [x.plugin_data.name]: x,
          }))
        );
        const promises = [];

        for (var element of elementData) {
          console.log("element", element);

          element.label = element.metadata.elementType;
          element.uuid = element.plugin_data.name;

          if (element.metadata.hasOwnProperty("questionImageKey")) {
            element.questionImageKey = element.metadata.questionImageKey;
            promises.push(getS3FilePath(element.questionImageKey, element));
          }
          if (element.metadata.hasOwnProperty("questionImageSize")) {
            element.questionImageSize = element.metadata.questionImageSize;
          }
          if (["Checkbox", "Radio"].includes(element.metadata.elementType)) {
            for (const choice of element.plugin_data.choices) {
              if (choice.imageKey) {
                promises.push(getS3FilePath(choice.imageKey, choice));
              }
            }
          }
        }

        const allPromises = Promise.all(promises).then((values) => {
          setLoading(false);
        });

        console.log("converted dictionary ", dictionary);

        setElements(dictionary);
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Could not get form elements.");
        setLoading(false);
      });
  };

  return (
    <div style={{}}>
      <FormHead title={template.title} description={template.description} />
      {Object.values(elements).map((element: any) => {
        return (
          <div
            style={{
              maxWidth: window.innerWidth < 900 ? 480 : 800,
              margin: "auto",
            }}
          >
            <ElementCard
              currentElement={elementsToReturn(element)}
              element={element}
            />
          </div>
        );
      })}
    </div>
  );
};

export default TemplateElementsPreview;
