import React from "react";

import { Form, Input, message } from "antd";

const FileUpload = (props: any) => {
  const { plugin_data, extra } = props;

  const { required } = plugin_data;

  const onFileChange = async (e: any) => {
    console.log("file details --->", e.target.files[0]);

    if (e.target.files.length > 0) {
      if (
        e.target.files[0].type.match("image") ||
        e.target.files[0].type.match("pdf") ||
        e.target.files[0].type.match(
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) ||
        e.target.files[0].type.match(
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) ||
        e.target.files[0].type.match(
          "application/vnd.oasis.opendocument.spreadsheet"
        )
      ) {
        const fileSize = e.target.files[0].size;

        if (fileSize > 5000000) {
          message.error("Max Image size 5MB allowed.");
        } else {
        }
      } else {
        message.error("This file type is not supported.");
      }
    } else {
      message.error("Please select a file to upload");
    }
  };

  return (
    <>
      <Form.Item
        required={required}
        style={{ margin: "0px", padding: "0px" }}
        extra={extra}
      ></Form.Item>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: "18px",
        }}
      >
        <Input
          type="file"
          onChange={(e) => onFileChange(e)}
          style={{ width: "220px" }}
        ></Input>
      </div>
      <p style={{ color: "red", fontSize: "10px" }}>
        Max file size allowed is 5Mb. <br />
        Supported file types include - images, pdf, docs and spreadsheets.
      </p>
    </>
  );
};

export default FileUpload;
