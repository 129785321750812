import React from "react";

import { Input, InputNumber, Form } from "antd";
import { IElement } from "../../utils/interfaces";
import AdvanceOptionsDrawer from "./ElementPreview/AdvanceOptionsDrawer";
import FormItem from "./ElementPreview/FormItem";

interface IProps {
  value?: string;
  placeholder?: string;
  style?: object;
  max_length?: number;
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  dispatch: ({ type, payload }: { type: string; payload: object }) => void;
  state: IElement;
  componentDisabled?: boolean;
  updateFieldValue: (fieldValue: object) => void;
  deleteButton: React.ReactNode;
  extraProps: React.ReactNode;
}

export default function CTInputPassword(props: IProps) {
  if (!props.state.plugin_data) return null;
  const advancedOptions = (
    <>
      <div style={{ display: "flex", flexFlow: "wrap" }}>
        <FormItem label="Placeholder">
          <Input
            value={props.state.plugin_data.placeholder}
            disabled={props.componentDisabled}
            onChange={(e) =>
              props.updateFieldValue({ placeholder: e.target.value })
            }
          />
        </FormItem>
        <FormItem label="Maximum Length" elementStyle={{ width: "100%" }}>
          <InputNumber
            min={0}
            max={255}
            value={props.state.plugin_data.max_length}
            disabled={props.componentDisabled}
            onChange={(value) => {
              if (value !== null)
                props.updateFieldValue({ max_length: parseInt(String(value)) });
            }}
          />
        </FormItem>
      </div>
    </>
  );
  return (
    <AdvanceOptionsDrawer
      componentDisabled={props.componentDisabled}
      deleteButton={props.deleteButton}
      advancedOptions={advancedOptions}
      state={props.state}
      extraProps={props.extraProps}
    />
  );
}
