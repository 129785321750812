import React, { useEffect, useState } from "react";

const RandomInput = (props: any) => {
  const {
    onChange,
    uploadedFileKey,
    fileExtenstion,
    fileName,
    fileType,
    fileSize,
  } = props;

  const [inputField, setInputField] = useState<any>({});

  const mainChange = (e: any) => {
    setInputField(e);
    onChange(e);
  };

  useEffect(() => {
    console.log(
      "values in random input --->",
      uploadedFileKey,
      fileExtenstion,
      fileType,
      fileSize,
      fileName
    );
    if (uploadedFileKey) {
      // setInputField(key);
      onChange({
        key: uploadedFileKey,
        contentType: fileType,
        fileSize,
        fileName,
        fileExtenstion,
      });
      setInputField({
        key: uploadedFileKey,
        contentType: fileType,
        fileSize,
        fileName,
        fileExtenstion,
      });
    }
  }, [fileExtenstion, fileName, fileSize, fileType, uploadedFileKey]);

  return (
    <input
      onChange={(e: any) => {
        mainChange(e.target.value);
      }}
      value={inputField}
      style={{ display: "none" }}
    />
  );
};

export default RandomInput;
