import React from "react";
import { IElement } from "../../utils/interfaces";
import AdvanceOptionsDrawer from "./ElementPreview/AdvanceOptionsDrawer";

interface IProps {
  componentDisabled?: boolean;
  state: IElement;
  deleteButton: React.ReactNode;
  extraProps: React.ReactNode;
}

export default function CTTimePicker(props: IProps) {
  return (
    <AdvanceOptionsDrawer
      componentDisabled={props.componentDisabled}
      deleteButton={props.deleteButton}
      advancedOptions={<></>}
      state={props.state}
      extraProps={props.extraProps}
    />
  );
}
