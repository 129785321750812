import CTInput from "./CTInput";
import CTButton from "./CTButton";
import CTSelect from "./CTSelect";
import CTSlider from "./CTSlider";
import CTCard from "./CTCard";
import CTTimePicker from "./CTTimePicker";
import CTCheckbox from "./CTCheckbox";
import CTDatePicker from "./CTDatePicker";
import CTRadio from "./CTRadio";
import CTInputTextArea from "./CTInputTextArea";
import CTInputPassword from "./CTInputPassword";
import CTSwitch from "./CTSwitch";
import CTEmail from "./CTEmail";
import CTUpload from "./CTFileUpload";
import CTUrl from "./CTUrl";
import CTInputNumber from "./CTInputNumber";
import CTRatings from "./CTRatings";
import CTPhone from "./CTPhone";
import CTVideo from "./CTVideo";

interface IProps {
  component: any;
  plugin_uid: string;
}

export const CTComponents: { [key: string]: IProps } = {
  Text: {
    component: CTInput,
    plugin_uid: "text",
  },
  Slider: {
    component: CTSlider,
    plugin_uid: "slider",
  },
  Dropdown: {
    component: CTSelect,
    plugin_uid: "select",
  },
  TimePicker: {
    component: CTTimePicker,
    plugin_uid: "time",
  },
  Checkbox: {
    component: CTCheckbox,
    plugin_uid: "checkbox_select_multiple",
  },
  DatePicker: {
    component: CTDatePicker,
    plugin_uid: "date",
  },
  Radio: {
    component: CTRadio,
    plugin_uid: "radio",
  },
  "Long Text": {
    component: CTInputTextArea,
    plugin_uid: "textarea",
  },
  Password: {
    component: CTInputPassword,
    plugin_uid: "password",
  },
  Switch: {
    component: CTSwitch,
    plugin_uid: "boolean",
  },
  Email: {
    component: CTEmail,
    plugin_uid: "email",
  },
  // goes as URL
  Upload: {
    component: CTUpload,
    plugin_uid: "file_object",
  },
  Link: {
    component: CTUrl,
    plugin_uid: "url",
  },
  Number: {
    component: CTInputNumber,
    plugin_uid: "decimal",
  },
  Phone: {
    component: CTPhone,
    plugin_uid: "text",
  },
  Ratings: {
    component: CTRatings,
    plugin_uid: "float",
  },
  Video: {
    component: CTVideo,
    plugin_uid: "file_object",
  },
};

export {
  CTInput,
  CTButton,
  CTSlider,
  CTTimePicker,
  CTSelect,
  CTCard,
  CTInputTextArea,
  CTInputPassword,
  CTSwitch,
  CTEmail,
  CTUpload,
  CTUrl,
  CTInputNumber,
  CTPhone,
  CTRatings,
  CTVideo,
};
