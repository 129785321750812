import React from "react";

import { Table } from "antd";

const TableForMobile = (props: any) => {
  const { columns, dataSource: data } = props;
  return (
    <div
      style={{
        width: "95vw",
        overflowX: "scroll",
      }}
    >
      <Table
        showHeader={true}
        tableLayout="fixed"
        columns={columns}
        dataSource={data}
        bordered={true}
        pagination={{
          position: ["bottomCenter"],
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["5", "10", "20", "30"],
        }}
        size="small"
        sticky={true}
        scroll={{ x: "95vw", y: "100vh" }}
      />
    </div>
  );
};

export default TableForMobile;
