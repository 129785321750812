import React, { useCallback, useEffect, useState } from "react";

import _ from "lodash";
import axios, { AxiosResponse } from "axios";
import { useParams } from "react-router-dom";

import AntSpinner from "../../../shared/customComponents/spinner/AntSpinner";
import MainForm from "./MainForm";
import ElementSpinner from "../../../shared/customComponents/spinner/ElementSpinner";
import { message } from "antd";
import { connect } from "react-redux";
import { StateType } from "../../core/redux/types";

const FormBuilder = (props: any) => {
  console.log("****** PROPS IN FORM BUILDER ******", props);

  const [formData, setFormData] = useState<any>();

  const [activeTab, setActiveTab] = useState<string>("1");

  const [updatedFormData, setUpdatedFormData] = useState<any>({});

  const [loading, setLoading] = useState(true);
  const [elementLoading, setElementLoading] = useState(false);

  const [shouldUpdate, setShouldUpdate] = useState(false);

  const [mode, setMode] = useState<"ADMIN" | "USER">("USER");

  const changeTitle = (e: { target: { value: any } }) => {
    const title = e.target.value;
    setUpdatedFormData({ ...formData, title });
  };

  useEffect(() => {
    if (props?.state?.auth?.userData?.is_staff) {
      setMode("ADMIN");
    }
  }, [props]);

  const params = useParams<any>();

  const { uuid } = params;

  useEffect(() => {
    const { tab } = params;
    if (tab === "builder") {
      setActiveTab("1");
    } else if (tab === "settings") {
      setActiveTab("2");
    } else if (tab === "responses") {
      setActiveTab("3");
    }
  }, []);

  const getFormByUuid = async (setLoading: any) => {
    setLoading(true);

    console.log("*********** FETCHING FORM DATA ********************");

    const url = props?.state?.auth?.userData?.is_staff
      ? `${process.env.REACT_APP_SERVER_URL}/api/templates/${uuid}/`
      : `${process.env.REACT_APP_SERVER_URL}/api/custom_forms/${uuid}/`;

    await axios
      .get(url)
      .then((result1) => {
        const result = result1.data;
        setFormData(result);

        setUpdatedFormData(result);

        getElementsByFormUuid(setLoading, result);
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Could not get form data.");
        setLoading(false);
      });
  };

  const getElementsByFormUuid = async (setLoading: any, formData: any) => {
    if (!loading) setLoading(true);
    console.log("*********** FETCHING FORM ELEMENTS ********************");

    const getS3FilePath = async (media_key: string, field: any) => {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/base/get-s3-url/?key=${media_key}`
        );
        console.log(`result from AWS`, result);
        if (field.questionImageKey) {
          field.questionImageUrl = result.data.url;
        } else {
          field.imageUrl = result.data.url;
        }
      } catch (error) {
        console.log("errors could not be get", error);
        message.error("Could not get form response data.");
      }
    };

    if (formData) {
      const url = props?.state?.auth?.userData?.is_staff
        ? `${process.env.REACT_APP_SERVER_URL}/api/templates/${uuid}/elements/?size=50`
        : `${process.env.REACT_APP_SERVER_URL}/api/${uuid}/elements/?size=50`;

      await axios
        .get(url)
        .then((result1) => {
          console.log("---- result from get elements API ---- ", result1);

          const elementData = result1.data.data;

          console.log("element Data ", elementData);

          let dictionary;
          dictionary = Object.assign(
            {},
            ...elementData.map((x: any) => ({
              [x.plugin_data.name]: x,
            }))
          );
          const promises = [];

          for (var element of elementData) {
            console.log("element", element);

            element.label = element.metadata.elementType;
            element.uuid = element.plugin_data.name;

            if (element.metadata.hasOwnProperty("questionImageKey")) {
              element.questionImageKey = element.metadata.questionImageKey;
              promises.push(getS3FilePath(element.questionImageKey, element));
            }
            if (element.metadata.hasOwnProperty("questionImageSize")) {
              element.questionImageSize = element.metadata.questionImageSize;
            }
            if (["Checkbox", "Radio"].includes(element.metadata.elementType)) {
              for (const choice of element.plugin_data.choices) {
                if (choice.imageKey) {
                  promises.push(getS3FilePath(choice.imageKey, choice));
                }
              }
            }
          }

          const allPromises = Promise.all(promises).then((values) => {
            setLoading(false);
          });

          console.log("converted dictionary ", dictionary);

          const newData = formData;

          newData.elements = dictionary;
          newData.elementIds = Object.values(newData.elements);

          setFormData(newData);
          setShouldUpdate(true);
        })
        .catch((err: any) => {
          console.log("errors could not be get", err);
          message.error("Could not get form elements.");
          setLoading(false);
        });
    }
  };

  const updateForm = async (localProps: any) => {
    const url = props?.state?.auth?.userData?.is_staff
      ? `${process.env.REACT_APP_SERVER_URL}/api/templates/${uuid}/`
      : `${process.env.REACT_APP_SERVER_URL}/api/custom_forms/${uuid}/`;

    await axios
      .put(url, localProps)
      .then((res) => {
        console.log("---- res ---- ", res);
        setFormData(res.data);
        message.success("Form details were updated.");
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Form details update failed.");
      });
  };

  useEffect(() => {
    getFormByUuid(setLoading);
  }, []);

  const debouncedSave = useCallback(
    _.debounce((nextValue: any) => updateForm(nextValue), 1000),

    []
  );

  const handleFormDetailsUpdate = async (props: any) => {
    const nextValue = props;
    debouncedSave(nextValue);
  };

  const handleElementCreate = async (localProps: any) => {
    if (!elementLoading) setElementLoading(true);
    console.log("props in element create", localProps);

    let finalData;

    for (var key in localProps) {
      // check if the property/key is defined in the object itself, not in parent
      if (localProps.hasOwnProperty(key)) {
        console.log("key and localProps -- ", key, localProps[key]);

        finalData = localProps[key];
        finalData.metadata = { elementType: localProps[key].label };
      }
    }

    if (finalData.plugin_uid === "decimal") {
      if (finalData.plugin_data.decimal_places) {
        finalData.plugin_data.decimal_places =
          finalData.plugin_data.decimal_places.toString();
      }
    }

    if (finalData.plugin_uid === "date") {
      finalData.metadata["dateFormat"] = finalData.plugin_data.input_formats;
    }

    console.log("Final Data ", finalData);

    if (formData) {
      try {
        const url = props.state.auth?.userData?.is_staff
          ? `${process.env.REACT_APP_SERVER_URL}/api/templates/${uuid}/elements/`
          : `${process.env.REACT_APP_SERVER_URL}/api/${uuid}/elements/`;

        const res = await axios.post(url, finalData);
        if (res.status === 201) {
          console.log(`res`, res);
          message.success("Form element created.");
          return res.data?.id;
        } else {
          return null;
        }
      } catch (error) {
        console.log(`error`, error);
        message.error("Element could not be created.");
        return null;
      } finally {
        setElementLoading(false);
      }
    }
  };

  const handleElementDelete = async (prop: any) => {
    console.log("--------> props in handle element delete ", prop);
    if (!elementLoading) setElementLoading(true);

    if (prop) {
      if (prop.metadata?.questionImageKey) {
        handleImageDelete(prop.metadata.questionImageKey);
      }
      if (["checkbox_select_multiple", "radio"].includes(prop.plugin_uid)) {
        for (const choice of prop.plugin_data.choices) {
          if (choice.imageKey) {
            handleImageDelete(choice.imageKey);
          }
        }
      }

      try {
        const url = props.state.auth.userData.is_staff
          ? `${process.env.REACT_APP_SERVER_URL}/api/templates/${uuid}/elements/${prop.id}/`
          : `${process.env.REACT_APP_SERVER_URL}/api/${uuid}/elements/${prop.id}/`;

        const res = await axios.delete(url);
        console.log("---- res on delete---- ", res);
        if (res.status === 204) {
          message.success("Form element deleted.");
          return 1;
        }
      } catch (error) {
        console.log("Delete Error: ", error);
        message.error("Element could not be deleted.");
        return 0;
      } finally {
        setElementLoading(false);
      }
    }
  };

  const onElementOrderChange = async (prop: any) => {
    console.log("prop in element order change form builder ----? ", prop);
    let finalData = prop;

    finalData.metadata = {
      elementType: prop.label,
      questionImageKey: prop.questionImageKey || prop.metadata.questionImageKey,
      questionImageSize:
        prop.questionImageSize || prop.metadata.questionImageSize,
    };

    if (finalData.plugin_uid === "decimal") {
      if (finalData.plugin_data.decimal_places) {
        finalData.plugin_data.decimal_places =
          finalData.plugin_data.decimal_places.toString();
      }
    }

    if (finalData.plugin_uid === "date") {
      finalData.metadata["dateFormat"] = finalData.plugin_data.input_formats;
    }

    if (prop) {
      const url = props.state.auth.userData.is_staff
        ? `${process.env.REACT_APP_SERVER_URL}/api/templates/${uuid}/elements/`
        : `${process.env.REACT_APP_SERVER_URL}/api/${uuid}/elements/`;

      await axios
        .put(url, [prop])
        .then((res) => {
          console.log("---- res ---- ", res);
          setFormData(res.data);
          message.success("Form element order updated.");
          return 1;
        })
        .catch((err: any) => {
          console.log("errors could not be get", err);
          message.error("Element order could not be updated.");
          return 0;
        });
    }
  };

  const handleElementUpdate = async (prop: any) => {
    console.log(
      "--------> props in handle element update  API call---->",
      prop
    );

    if (prop) {
      if (prop.hasOwnProperty("plugin_uid")) {
        if (!prop.metadata) prop.metadata = { elementType: prop.label };
        else prop.metadata["elementType"] = prop.label;
      }

      if (prop.hasOwnProperty("questionImageSize")) {
        prop.metadata["questionImageSize"] = prop.questionImageSize;
      }
      if (prop.hasOwnProperty("questionImageKey")) {
        prop.metadata["questionImageKey"] = prop.questionImageKey;
      }

      if (prop.plugin_uid === "decimal") {
        prop.plugin_data.decimal_places =
          prop.plugin_data.decimal_places.toString();
        prop.plugin_data.max_value = prop.plugin_data.max_value.toString();
        prop.plugin_data.min_value = prop.plugin_data.min_value.toString();
        prop.plugin_data["max_digits"] = (
          parseInt(prop.plugin_data.decimal_places) +
          prop.plugin_data.max_value.toString().length
        ).toString();
      }
      if (prop.plugin_uid === "date") {
        prop.metadata["dateFormat"] = prop.plugin_data.input_formats;
      }

      const url = props.state.auth.userData.is_staff
        ? `${process.env.REACT_APP_SERVER_URL}/api/templates/${uuid}/elements/`
        : `${process.env.REACT_APP_SERVER_URL}/api/${uuid}/elements/`;

      await axios
        .put(url, [prop])
        .then((res) => {
          console.log("---- res ---- ", res);
          message.success("Form element updated.");
          return 1;
        })
        .catch((err: any) => {
          console.log("errors could not be get", err);
          message.error("Form element could not be updated.");
          return 0;
        });
    }
  };

  const handleImageUpload = async (element: any) => {
    const attachHeaders = async (data: any) => {
      const formData = new FormData();

      Object.entries(data).forEach((dataItem: any) => {
        formData.append(dataItem[0], dataItem[1]);
      });

      return formData;
    };

    // Upload questionImageUrl to S3 & save in metadata
    const onFileUpload = async (file: any) => {
      const selectedFile = file;
      console.log(`selectedFile`, selectedFile);

      if (selectedFile) {
        const file = selectedFile.name !== "" ? selectedFile : "  ";
        const extenstion = selectedFile.name
          .substr(selectedFile.name.lastIndexOf(".") + 1)
          .split(".")[0];

        const keys = await getAWSTokenForLogoUpload(extenstion);

        const data = await keys.fields;

        const key = data.key;

        const formData: FormData = await attachHeaders(data);

        formData.append("file", file);

        if (formData) {
          const result = await uploadFileToAWS(keys.url, await formData, key);

          console.log("Result from AWS -----> ", result);
          return result;
        }
      } else {
        message.error("No image selected.");
      }
    };

    const getAWSTokenForLogoUpload = async (extenstion: string) => {
      let result;

      try {
        result = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/base/token/?file_extension=${extenstion}`
        );
      } catch (err) {
        console.log("errr", err);
        message.error("Image Upload failed.");
      }

      console.log("result in aws token --> ", result?.data);
      return result?.data?.data;
    };

    const uploadFileToAWS = async (url: string, formData: any, key: string) => {
      var instance = axios.create();
      delete instance.defaults.headers.common["Authorization"];

      return instance
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then((response: AxiosResponse) => {
          message.success("Image uploaded successfully.");
          console.log("after image upload", response.data);
          return key;
        })
        .catch((err) => {
          console.log(err);
          message.error("Image upload failed.");
        });
    };

    if (element.hasOwnProperty("questionImageData")) {
      const questionImageKey = await onFileUpload(element.questionImageData);
      element.metadata["questionImageKey"] = questionImageKey;
      element.questionImageKey = questionImageKey;
    }

    if (element.hasOwnProperty("choiceImageData")) {
      const { choiceImageData, choiceIndex, ...rest } = element;
      element = rest;

      const choiceImageKey = await onFileUpload(choiceImageData);

      element.plugin_data.choices[choiceIndex]["imageKey"] = choiceImageKey;
    }

    return element;
  };

  const handleImageDelete = async (imageKey: string): Promise<number> => {
    try {
      const deleteResult = await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/api/base/token/?key=${imageKey}`
      );
      console.log(`Image delete Result`, deleteResult);
      message.success("Image deleted successfully");
      return 1;
    } catch (err) {
      console.log("errr", err);
      message.error("Image delete failed");
      return 0;
    }
  };

  return (
    <>
      {loading ? (
        <AntSpinner />
      ) : (
        <>
          {" "}
          {props.state && props.state.auth && props.state.auth.userData && (
            <ElementSpinner loading={elementLoading}>
              <MainForm
                setFormData={setFormData}
                formData={formData}
                updatedFormData={updatedFormData}
                handleElementCreate={handleElementCreate}
                onElementUpdate={(prop: any) => handleElementUpdate(prop)}
                handleElementDelete={handleElementDelete}
                handleImageDelete={handleImageDelete}
                handleImageUpload={handleImageUpload}
                onElementOrderChange={(props: any) =>
                  onElementOrderChange(props)
                }
                onFormDetailsUpdate={(props: any) =>
                  handleFormDetailsUpdate(props)
                }
                activeTabKey={activeTab}
              />
            </ElementSpinner>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    state: state,
  };
};

// const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
//   return {
//     getUserData: () => dispatch(GetUserData()),
//   };
// };

export default connect(mapStateToProps)(FormBuilder);
