import React from "react";
import { InputNumber, Input } from "antd";
import { IElement } from "../../utils/interfaces";
import AdvanceOptionsDrawer from "./ElementPreview/AdvanceOptionsDrawer";
import FormItem from "./ElementPreview/FormItem";

interface IProps {
  value?: string;
  placeholder?: string;
  style?: object;
  max_length?: number;
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  dispatch: ({ type, payload }: { type: string; payload: object }) => void;
  state: IElement;
  componentDisabled?: boolean;
  updateFieldValue: (fieldValue: object) => void;
  deleteButton: React.ReactNode;
  extraProps: React.ReactNode;
}

export default function CTInput(props: IProps) {
  if (!props.state.plugin_data) return null;
  const advancedOptions = (
    <>
      <FormItem label={"Placeholder"}>
        <Input
          maxLength={50}
          disabled={props.componentDisabled}
          value={props.state.plugin_data.placeholder}
          onChange={(e) =>
            props.updateFieldValue({ placeholder: e.target.value })
          }
        />
      </FormItem>
      <FormItem elementStyle={{ width: "100%" }} label={"Maximum Length"}>
        <InputNumber
          min={0}
          max={255}
          disabled={props.componentDisabled}
          value={props.state.plugin_data.max_length}
          onChange={(value) => {
            if (value !== null)
              props.updateFieldValue({
                max_length: parseInt(String(value)),
              });
          }}
        />
      </FormItem>
    </>
  );

  return (
    <AdvanceOptionsDrawer
      componentDisabled={props.componentDisabled}
      deleteButton={props.deleteButton}
      advancedOptions={advancedOptions}
      state={props.state}
      extraProps={props.extraProps}
    />
  );
}
