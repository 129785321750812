import React from "react";

import MDEditor from "@uiw/react-md-editor";

interface IProps {
  value?: string;
  onChange?: (value: string | undefined) => void;
}

const LongTextMarkdownEditor = (props: IProps) => {
  const { value, onChange } = props;

  return (
    <>
      <MDEditor
        placeholder="Form Description"
        textareaProps={{
          maxLength: 3000,
          placeholder: "Form Description",
        }}
        value={value}
        onChange={(value) => onChange!(value)}
      />
    </>
  );
};

export default LongTextMarkdownEditor;
