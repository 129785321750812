import React, { useEffect } from "react";

import { message } from "antd";
import axios from "axios";
import { useLocation } from "react-router-dom";

const qs = require("query-string");

const DownloadMedia = (props: any) => {
  const pathname = useLocation();

  const data = qs.parse(pathname.search);

  const { key: media_key } = data;

  const getS3FilePath = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/api/base/get-s3-url/?key=${media_key}`
      )
      .then(async (result1) => {
        console.log("ssd", result1.data);
        window.open(result1.data.url, "_blank");

        // window.location.href = "/dashboard";
        window.close();
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Could not get form response data.");
      });
  };

  useEffect(() => {
    getS3FilePath();
  }, []);

  return (
    <div>
      <h1>Your file will open in new window</h1>
    </div>
  );
};

export default DownloadMedia;
