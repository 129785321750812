import React from "react";

import { Card } from "antd";

const ctFormsImage =
  require("../../../shared/assets/images/ct-forms.svg").default;

function CompanyFooter() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        marginTop: "10px",
        maxWidth: "800px",
      }}
    >
      <Card
        style={{
          width: "100%",
          margin: "0px",
          padding: "0px",
          border: "none",
          borderTop: "1px solid whitesmoke",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            maxWidth: "100%",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <p> Powered by</p>
          <img
            src={ctFormsImage}
            alt="CypherTree Forms"
            style={{ width: "150px", height: "30px" }}
          />
        </div>
      </Card>
    </div>
  );
}

export default CompanyFooter;
