import React, { useState } from "react";

import { Button, message } from "antd";
import axios from "axios";

import { useParams } from "react-router-dom";

import RecordView from "./RecordView";

const RecordVideo = (props) => {
  const attachHeaders = async (data) => {
    const formData = new FormData();

    Object.entries(data).forEach((dataItem) => {
      formData.append(dataItem[0], dataItem[1]);
    });

    return formData;
  };

  const onFileUpload = async (
    blobUrl,
    setAwsMediaKey,
    setFileExtenstion,
    setFileName,
    setFileSize,
    setFileType,
    setFileUploading
  ) => {
    let blob = await fetch(blobUrl).then((r) => r.blob());

    const selectedFile = blob;

    if (selectedFile) {
      setFileUploading(true);

      const file = selectedFile;
      const fileName = "randomFileName.mp4";

      const extenstion = "mp4";

      const keys = await getAWSTokenForLogoUpload(extenstion);

      const data = await keys.fields;

      const key = data.key;

      const formData = await attachHeaders(data);
      formData.append("file", file);

      if (formData) {
        const result = await uploadFileToAWS(keys.url, await formData, key);
        console.log("Result from AWS -----> ", result);
        console.log(
          "Data to set -->",
          result,
          extenstion,
          fileName,
          selectedFile.size,
          selectedFile.type
        );
        setAwsMediaKey(result);
        setFileExtenstion(extenstion);
        setFileName(fileName);
        setFileSize(selectedFile.size);
        setFileType(selectedFile.type);
        setFileUploading(false);
      }
    } else {
      message.error("No image selected.");
    }
  };

  const resolveAWSFilePathForDownload = async (media_key) => {
    console.log("the media key recieved --> ", media_key);
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/api/base/get-s3-url/?key=${media_key}`
      )
      .then(async (result1) => {
        console.log("image URL", result1.data.url);
        window.open(result1.data.url, "_blank");
      })
      .catch((err) => {
        console.log("errors could not be get", err);
      });
  };
  const params = useParams();

  const { uuid } = params;

  const getAWSTokenForLogoUpload = async (extenstion) => {
    let result;

    try {
      const userType = localStorage.getItem("tempAccessToken")
        ? "anon"
        : "user";
      result = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/base/token/?file_extension=${extenstion}&form_uuid=${uuid}`,
        {
          headers: {
            Authorization:
              userType === "anon" &&
              `Bearer ${localStorage.getItem("tempAccessToken")}`,
          },
        }
      );
    } catch (err) {
      console.log("errr", err);
      message.error("Logo upload failed.");
    }

    console.log("result in aws token --> ", result?.data);
    return result?.data?.data;
  };

  const uploadFileToAWS = async (url, formData, key) => {
    var instance = axios.create();
    delete instance.defaults.headers.common["Authorization"];

    return instance
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("after file upload", response.data);
        message.success("Video was uploaded.");
        return key;
      })
      .catch((err) => {
        console.log(err);
        message.error("Logo upload failed.");
        // setLoading(false);
      });
  };

  const {
    setUploadedFileKey,
    setFileExtenstion,
    setFileName,
    setFileSize,
    setFileType,
    uploadedFileKey,
    fileName,
  } = props;

  const [fileUploading, setFileUploading] = useState(false);

  const handleFileDelete = async (awsKey, successCallback) => {
    try {
      const userType = localStorage.getItem("tempAccessToken")
        ? "anon"
        : "user";
      const deleteResult = await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/api/base/token/?key=${awsKey}`,
        {
          headers: {
            Authorization:
              userType === "anon" &&
              `Bearer ${localStorage.getItem("tempAccessToken")}`,
          },
        }
      );
      console.log(`File delete Result`, deleteResult);
      message.success("Video deleted successfully");
      setUploadedFileKey(null);
      setFileName("");
      successCallback();
    } catch (err) {
      console.log("errr", err);
      message.error("Video delete failed");
    }
  };

  if (uploadedFileKey) {
    return (
      <>
        <p style={{ wordBreak: "break-word" }}>{fileName}</p>
        <Button
          onClick={() => {
            handleFileDelete(uploadedFileKey, () => {});
          }}
        >
          Remove
        </Button>
        <Button
          onClick={() => {
            resolveAWSFilePathForDownload(uploadedFileKey);
          }}
          style={{ margin: "2px" }}
        >
          Download recording
        </Button>
      </>
    );
  }

  return (
    <>
      <RecordView
        onFileUpload={onFileUpload}
        setFinalAwsKey={setUploadedFileKey}
        setUploadedFileKey={setUploadedFileKey}
        setFileExtenstion={setFileExtenstion}
        setFileName={setFileName}
        setFileSize={setFileSize}
        setFileType={setFileType}
        resolveAWSFilePathForDownload={resolveAWSFilePathForDownload}
        fileUploading={fileUploading}
        setFileUploading={setFileUploading}
        uploadedFileKey={props.uploadedFileKey}
      />
    </>
  );
};

export default RecordVideo;
