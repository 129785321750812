import React, { useEffect, useState } from "react";

import { Card, Layout, message, Spin, Typography } from "antd";

import axios from "axios";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

const qs = require("query-string");

const { Title } = Typography;

const EmailVerificationPage = (props: any) => {
  const [shouldRedirectToLogin, setShouldRedirectToLogin] = useState(false);

  const data = qs.parse(props.location.search);

  const { token } = data;

  const [verificationText, setVerificationText] = useState(
    "Your Email is being verified"
  );

  const redirectToLogin = () => {
    setTimeout(() => {
      setShouldRedirectToLogin(true);
    }, 3000);
  };

  const verifyEmail = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/api/users/verify-email/?token=${token}`
      )
      .then((res) => {
        console.log("res", res);
        message.success("Email verified successfully.");
        setVerificationText("Email verified successfully. Redirecting .....");
        redirectToLogin();
      })
      .catch((err) => {
        console.log("err", err);
        message.error("Email could not be verified.");
        setVerificationText("Email could not be verified. Redirecting .....");
        redirectToLogin();
      });
  };

  useEffect(() => {
    if (token) {
      verifyEmail();
    }
  }, []);

  return (
    <Layout style={{ flex: 1, height: "80vh" }}>
      <Card
        style={{
          flex: 1,
          height: "80vh",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Title level={3}>{verificationText}</Title>
        <Spin />
      </Card>
      {shouldRedirectToLogin && <Redirect to="/login/" />}
    </Layout>
  );
};

const mapStateToProps = (state: any) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(EmailVerificationPage);
