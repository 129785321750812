import React, { useState } from "react";

import { Col, Popconfirm, Row, Tag, Space, Button, Tooltip } from "antd";
import ViewResponseModal from "../../../containers/formResponses/ViewResponseModal";
import { DeleteOutlined, EditOutlined, MailOutlined } from "@ant-design/icons";

import moment from "moment";
import { Link } from "react-router-dom";

import TableForMobile from "./TableForMobile";
import TableForDesktop from "./TableForDesktop";
import CopyToClipboard from "../../copyToClipboard/CopyToClipboard";
import InviteModal from "../invite/InviteModal";
import { StateType } from "../../../core/redux/types";
import { connect } from "react-redux";

const FormsList = (props: any) => {
  const { formData, deleteFormById } = props;
  const [currentUuid, setCurrentUuid] = useState("");
  
  const columns = [
    {
      title: "Name",
      dataIndex: "title",
      ellipsis: true,
      width: "200px",
      render: (title: React.ReactNode, record: any) => (
        <Tooltip placement="topLeft" title={title}>
          <Link to={`/forms/${record.uuid}/builder`}>
            {" "}
            {title ? title : <Tag color="red">Untitled Form</Tag>}
          </Link>
        </Tooltip>
      ),
    },
    {
      title: "Responses",
      dataIndex: "response_count",
      key: "responses",
      width: "110px",
      render: (response_count: React.ReactNode, record: any) => (
        <Tooltip title="View Responses">
          {" "}
          <Link to={`/forms/${record.uuid}/responses`}>
            <p>{response_count}</p>
          </Link>
        </Tooltip>
      ),
    },
    {
      title: "Published",
      dataIndex: "is_public",
      key: "is_public",
      width: "80px",
      render: (is_public: React.ReactNode) => (
        <p>
          {" "}
          {is_public ? (
            <Tag color="green">{"Yes".toUpperCase()}</Tag>
          ) : (
            <Tag color="red">{"No".toUpperCase()}</Tag>
          )}
        </p>
      ),
    },
    {
      title: "Last Updated",
      dataIndex: "updated",
      key: "updated",
      width: "200px",
      sorter: {
        compare: (a: { updated: any }, b: { updated: moment.MomentInput }) =>
          moment(a.updated).unix() - moment(b.updated).unix(),
      },
      render: (updated: any) => (
        <p>
          {" "}
          {new Date(updated).toLocaleDateString("en-gb", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
          {", "}
          {
            Intl.DateTimeFormat("en", {
              hour: "numeric",
              minute: "numeric",
            }).format(new Date(updated)) // 2 PM
          }
        </p>
      ),
    },
    {
      title: "Action",
      key: "action",
      // width: "400px",
      render: (text: any, record: any) => (
        <Space size="middle">
          <Tooltip title="Edit Form">
            <Link to={`/forms/${record.uuid}/builder`}>
              <Button>
                <EditOutlined />
              </Button>
            </Link>
          </Tooltip>

          <CopyToClipboard
            linkForSubmission={`${process.env.REACT_APP_FRONTEND_URL}/form-submit/${record.uuid}`}
            linkForIframe={`${process.env.REACT_APP_FRONTEND_URL}/form-submit/iframe/${record.uuid}`}
            formUuid={record.uuid}
          />

          <Tooltip title="Send Invite">
            <Button
              onClick={() => {
                setIsInviteModalOpen(true);
                setCurrentUuid(record.uuid);
              }}
            >
              <MailOutlined />
            </Button>
          </Tooltip>

          <Popconfirm
            title="Delete this form?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => deleteFormById(record.uuid)}
          >
            <Button>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const columnsForMobile = [
    {
      title: "Name",
      dataIndex: "title",
      ellipsis: true,
      width: "200px",
      render: (title: React.ReactNode, record: any) => (
        <Tooltip placement="topLeft" title={title}>
          <Link to={`/forms/${record.uuid}/builder`}>
            {title ? title : <Tag color="red">Untitled Form</Tag>}
          </Link>
        </Tooltip>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "200px",
      render: (text: any, record: any) => (
        <Space size="middle">
          <Link to={`/forms/${record.uuid}/builder`}>
            <Button>
              <EditOutlined />
            </Button>
          </Link>
          <Button>
            <Popconfirm
              title="Delete this form?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => deleteFormById(record.uuid)}
            >
              <DeleteOutlined />
            </Popconfirm>
          </Button>
          <CopyToClipboard
            linkForSubmission={`${process.env.REACT_APP_FRONTEND_URL}/form-submit/${record.uuid}`}
            linkForIframe={`${process.env.REACT_APP_FRONTEND_URL}/form-submit/iframe/${record.uuid}`}
            formUuid={record.uuid}
          />
        </Space>
      ),
    },
  ];

  const data = formData;

  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row", alignSelf: "left" }}>
        <p
          style={{
            fontSize: "26px",
            fontWeight: "bold",
            color: "#696969	",
            width: "auto",
            margin: "10px 20px 5px 0 ",
            padding: "0",
            paddingBottom: "20px",
          }}
        >
          {props.state.auth?.userData?.is_staff ? "TEMPLATES" : "FORMS"}
        </p>
      </div>
      <Row>
        <Col
          span={24}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {window.innerWidth < 769 ? (
            <TableForMobile columns={columnsForMobile} dataSource={data} />
          ) : (
            <TableForDesktop columns={columns} dataSource={data} />
          )}
        </Col>
      </Row>
      {isInviteModalOpen && (
        <InviteModal
          isInviteModalOpen={isInviteModalOpen}
          setIsInviteModalOpen={setIsInviteModalOpen}
          currentUuid={currentUuid}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(FormsList);
