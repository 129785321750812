import React, { useEffect, useState } from "react";

import { Card, message } from "antd";
import axios from "axios";

import DomainsToTemplates from "../adminSettings/DomainsToTemplate";

const TemplateDomains = (props: any) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [domains, setDomains] = useState<any>();

  const { formData } = props;

  const [initialDomains, setInitialDomains] = useState<any>();

  const getAllTags = async () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/base/domains/`;

    await axios
      .get(url)
      .then((result) => {
        setDomains(result.data.data);
        return result;
      })
      .then((result: any) => {
        console.log("result ---> ", result);
        updateInitialDomains(result.data.data);
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Could not get form data.");
        setLoading(false);
      });
  };

  const updateInitialDomains = async (domains: any) => {
    const localData: any = [];
    if (
      formData &&
      formData.domains.length > 0 &&
      domains &&
      domains.length > 0
    ) {
      for (let i = 0; i < formData.domains.length; i++) {
        for (let j = 0; j < domains.length; j++) {
          if (formData.domains[i].domain === domains[j].id) {
            const dataToPush: any = {};
            dataToPush.label = domains[j].name;
            dataToPush.name = domains[j].name;
            dataToPush.key = parseInt(domains[j].id);
            dataToPush.value = parseInt(domains[j].id);
            localData.push(dataToPush);
          }
        }
      }
      setInitialDomains(await localData);
    } else {
      setInitialDomains([]);
    }
  };

  useEffect(() => {
    getAllTags();
  }, []);

  useEffect(() => {}, [initialDomains]);

  return (
    <Card title={<span>Domains </span>} style={{ margin: "10px" }}>
      <DomainsToTemplates domains={domains} initialDomains={initialDomains} />
    </Card>
  );
};

export default TemplateDomains;
