import React, { useEffect, useState } from "react";

import { Button, Input, message, Popover, Modal } from "antd";

import {
  CopyTwoTone,
  ShareAltOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import MDEditor from "@uiw/react-md-editor";
import axios from "axios";
import { connect } from "react-redux";
import { StateType } from "../../core/redux/types";

interface IProps {
  state: any;
  linkForSubmission: string;
  linkForIframe: string;
  formUuid: string;
}

const CopyToClipboard = (props: IProps) => {
  const { linkForSubmission, linkForIframe, formUuid } = props;

  console.log("uuid in copu to clipboard", formUuid);

  const [showPopOver, setShowPopOver] = useState(false);
  const [showEmbedCodeModal, setShowEmbedCodeModal] = useState(false);

  const [formData, setFormData] = useState<any>();

  const getFormByUuid = async (uuid: string) => {
    const url = props.state.auth.userData.is_staff
      ? `${process.env.REACT_APP_SERVER_URL}/api/templates/${uuid}/`
      : `${process.env.REACT_APP_SERVER_URL}/api/custom_forms/${uuid}/`;

    await axios
      .get(url)
      .then((result1) => {
        const result = result1.data;
        setFormData(result);
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Could not get form data.");
      });
  };

  const handleCopyToClipboard = (copyInput: string) => {
    if (navigator.clipboard && window.isSecureContext) {
      message.success("Copied to clipboard");
      return navigator.clipboard.writeText(copyInput);
    } else {
      let textArea = document.createElement("textarea");
      textArea.value = copyInput;
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      message.success("Copied to clipboard");

      return new Promise<void>((res, rej) => {
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
      });
    }
  };

  const getEmbedCode = (embedSrc: string) => `
<iframe
  title="CT Forms"
  src="${embedSrc}"
  height="365"
  width="440"
  frameborder="0"
  >Loading...
</iframe>
`;

  useEffect(() => {
    if (formUuid) {
      getFormByUuid(formUuid);
    }
  }, [showEmbedCodeModal]);

  return (
    <Popover
      visible={showPopOver}
      content={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Input value={linkForSubmission}></Input>
            <Button
              style={{ marginLeft: "2px" }}
              onClick={() => {
                handleCopyToClipboard(linkForSubmission);
                setShowPopOver(false);
              }}
            >
              <CopyTwoTone />
            </Button>
          </div>
          <Button
            style={{ marginTop: "6px" }}
            onClick={() => {
              setShowEmbedCodeModal(true);
              setShowPopOver(false);
            }}
          >
            Embed Form
          </Button>
        </div>
      }
      title={
        <CloseOutlined
          onClick={() => setShowPopOver(false)}
          style={{
            display: "flex",
            justifyContent: "end",
            fontSize: 16,
            marginTop: 3,
          }}
        />
      }
      trigger="click"
    >
      <Button onClick={() => setShowPopOver(true)}>
        <ShareAltOutlined />
      </Button>
      <Modal
        visible={showEmbedCodeModal}
        title="Embed Form"
        onCancel={() => setShowEmbedCodeModal(false)}
        footer={
          <>
            {formData && !formData.login_required && (
              <Button
                onClick={() => {
                  handleCopyToClipboard(getEmbedCode(linkForIframe));
                  setShowEmbedCodeModal(false);
                }}
                color="primary"
              >
                <CopyTwoTone />
              </Button>
            )}
          </>
        }
      >
        {formData && !formData.login_required ? (
          <MDEditor.Markdown
            source={`> Use following code in the HTML page.          
\`\`\`javascript
${getEmbedCode(linkForIframe)}
\`\`\``}
          />
        ) : (
          <>
            <p>
              {" "}
              <b> Login Required to fill the form</b> setting is active on this
              form.
            </p>
            <p>You need to disable it in order to use iframe feature.</p>{" "}
            <p>
              To disable it go to <b>Form Settings</b> of your form.
            </p>
          </>
        )}
      </Modal>
    </Popover>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    state: state,
  };
};

// const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
//   return {
//     getUserData: () => dispatch(GetUserData()),
//   };
// };

export default connect(mapStateToProps)(CopyToClipboard);
