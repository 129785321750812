import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router'
import axios from 'axios'
import TableForMobile from '../drive/TableForMobile'
import TableForDesktop from '../drive/TableForDesktop'
import label1 from '../../../shared/assets/images/label 1.png'
import { Form,Card,Tag,Input,Row,Button,Col, Typography } from 'antd'
import { DeleteOutlined, EditOutlined, ReloadOutlined } from '@ant-design/icons'
import { ChromePicker } from 'react-color'

const {Title}=Typography
export default function CreateNewLabel() {
    const params = useParams<any>();
    const [form] = Form.useForm()
    const { uuid } = params;
    const [isOpenColorPalette, setOpenColorPalette] = useState(false)
    const [isCodeGenerator,setCodeGenerator] = useState(false)
    const [isEditColorPalette, setIsEditColorPalette] = useState(false)
    const [editLabelId, setEditLabelId] = useState<number>(0)
    const [labelsList, setlabelsList] = useState<any>()
    const [isAddLabel, setIsAddLabel] = useState(false)
    const [isEditLabel,setIsEditLabel]=useState(false)
    const [selectedColor, setSelectedColor] = useState<string>('#dddddd')

    const getLabelsData=()=>{
        axios
            .get(
                `${process.env.REACT_APP_SERVER_URL}/api/${uuid}/labels`
            )
            .then( (result1) => {
                setlabelsList(result1.data.data);
            })
            .catch((err: any) => {
                console.log("errors could not be get", err);
            });
        }
    const addNewLabelData=(record:any)=>{
        axios
        .post(
                `${process.env.REACT_APP_SERVER_URL}/api/${uuid}/labels/`,record
            )
            .then( (result1) => {
                getLabelsData()
                // setlabelsList(result1.data.data);
            })
            .catch((err: any) => {
                console.log("errors could not be get", err);
            });
    }
    const editLabelData=(record:any,id:number)=>{
         axios
        .put(
                `${process.env.REACT_APP_SERVER_URL}/api/${uuid}/labels/${id}/`,record
            )
            .then( (result1) => {
                getLabelsData()
                // setlabelsList(result1.data.data);
            })
            .catch((err: any) => {
                console.log("errors could not be get", err);
            });
    }
    const deleteLabelData=(id:number)=>{
         axios
        .delete(
                `${process.env.REACT_APP_SERVER_URL}/api/${uuid}/labels/${id}/`
            )
            .then( (result1) => {
                getLabelsData()
                // setlabelsList(result1.data.data);
            })
            .catch((err: any) => {
                console.log("errors could not be get", err);
            });
    }
    const resetForm=()=>{
        setIsAddLabel(false)
        setIsEditLabel(false)
        setIsEditColorPalette(!isEditColorPalette)
        setSelectedColor('#dddddd')
    }
    const addNewLabel=()=>{
        setIsAddLabel(true)
        setIsEditLabel(false)
         form.setFieldsValue({
            labelName:'',
            description:''
        })
    }
    const openColorPalette=()=>{
        setOpenColorPalette(!isOpenColorPalette)
    }
    const onColorChange=(color:any)=>{
        setSelectedColor(color.hex)
    }
    const onCompleteColorChange = (color:any)=>{
        setSelectedColor(color.hex)
    }
    const createLabelAction=(values:any)=>{
         const record={is_active:true,
                        name:values.labelName,
                        description:values.description,
                        color_hex:selectedColor}
            addNewLabelData(record)
            setIsAddLabel(false)
            // setSelectedColor('#dddddd')
    }
    const editLabelAction=(values:any)=>{
        const payload={ 
                        name:values.labelName && values.labelName,
                        description:values.description && values.description,
                        color_hex:selectedColor}
                        setIsEditColorPalette(!isEditColorPalette)
        editLabelData(payload,editLabelId)
          setIsEditLabel(false)
        // setSelectedColor('#dddddd')
    }
    const handleGenerateCode=()=>{
        const hexColor='#'+Math.floor(Math.random()*16777215).toString(16);
        setSelectedColor(hexColor)
        setCodeGenerator(!isCodeGenerator)
        console.log('hexcolor',hexColor);
    }
    const onEditClick=(record:any)=>{
        setSelectedColor(record.color_hex)
        setEditLabelId(record.id)
        form.setFieldsValue({
            labelName:record.name,
            description:record.description
        })
        // setEditLabelDescription(record.description!==undefined?record.description:'')
        // setEditLabelName(record.name)
        // // setIsEditColorPalette(!isEditColorPalette)
        setIsEditLabel(true)
        setIsAddLabel(false)
    }
    const onDeleteClick=(id:number)=>{
        deleteLabelData(id)
    }
    useEffect(() => {
       getLabelsData()
    }, [])
    var tempArr=labelsList !== undefined && Object.values(labelsList).map((item:any,idx:number)=>{
        const tempArray={
            key:idx,
            id:item.id,
            name:item.name,
            description:item.description,
            color_hex:item.color_hex
        }
        return tempArray
    })
    const columns=[
        {   
            title:<Title style={{fontSize:'18px',fontWeight:500}}>Labels</Title>,
            render:(record:any)=>(<Tag color={record.color_hex} style={{borderRadius:'16px'}}>{record.name}</Tag>)
        },
        {   dataIndex:'description',
            title:<Title style={{fontSize:'18px',fontWeight:500}}>Description</Title>,
            key:'decription',
            width:window.innerWidth < 769 ?100:''
        },
        {
            key:'operation',
            title:<Title style={{fontSize:'18px',fontWeight:500}}>Actions</Title>,
            render:(record:any)=>(
                <>
                    <Button type='link' onClick={()=>onEditClick(record)}>
                        <EditOutlined/>
                    </Button>
                    <Button type='link' onClick={()=>onDeleteClick(record.id)}>
                        <DeleteOutlined />
                    </Button>
                </>
            )
        }
    ]
    return (
        <>
            <Card style={window.innerWidth < 769 ?{borderRadius:'16px',backgroundColor:'#F7F7F8'}:{backgroundColor:'#F7F7F8',borderRadius:'16px', width: "80vw",
                display:'flex',marginTop:'25px',marginLeft:'94px',textAlign:'left'}}>
                <Row>
                    <Col span={6}>
                        <img src={label1} alt='label' height='72px' width='72px' style={{marginTop:'10px'}}/>
                    </Col>
                    <Col span={18}>
                     <Title level={4}>Add Labels to Responses</Title>
                     <p>Add label to responses can help to filter out the responses by type or workflow.</p>
                    </Col>
                </Row>
            </Card>
            {(isAddLabel || isEditLabel) && 
            <Card 
               style={window.innerWidth < 769 ?
                                {borderRadius:'16px',backgroundColor:'#F7F7F8'}:
                                {backgroundColor:'#F7F7F8',borderRadius:'16px', width: "80vw",
                                marginTop:'25px',marginLeft:'94px',textAlign:'left'}}>
                <Form layout={window.innerWidth < 769 ?'horizontal':'vertical'} form={form} onFinish={isEditLabel?(values)=>editLabelAction(values):createLabelAction}>
                    <Row>
                        <Tag color={selectedColor} 
                            style={{
                                borderRadius:'16px',
                                marginBottom:'10px',
                                width:'160px',
                                height:'43px',
                                fontSize:'20px',
                                fontWeight:600,
                                color:' #0D0C0C',
                                }}>
                            <div style={{marginTop:'9px',marginLeft:'3px'}}>Label Preview</div>
                        </Tag>
                    </Row>
                    {window.innerWidth < 769 ?
                    (<Row>
                        <Form.Item name='labelName' label='Label Name' style={{fontSize:'18px',fontWeight:600}}>
                            <Input />
                        </Form.Item>
                         <Form.Item name='description' label='Description' style={window.innerWidth < 769 ?{fontSize:'18px',fontWeight:600}:{marginLeft:'10px',fontSize:'18px',fontWeight:600}}>
                            <Input />
                        </Form.Item>
                        <Form.Item name='colorSelect' label='Color' style={window.innerWidth < 769 ?{fontSize:'18px',fontWeight:600}:{marginLeft:'10px',fontSize:'18px',fontWeight:600}}>
                            <Input onClick={openColorPalette} value={selectedColor}/>
                            {isOpenColorPalette && 
                                <div className= 'popover'>
                                    <div className='cover' >
                                        <ChromePicker  
                                            color={selectedColor}
                                            // value={record.labels[0].color_hex}
                                            onChange={ (e) => onColorChange(e)}
                                            onChangeComplete={ (e) => onCompleteColorChange(e)} />
                                    </div>
                                </div>}
                        </Form.Item>
                        <Form.Item style={{marginTop:'40px'}}>
                            <Button onClick={handleGenerateCode} type='link'>
                                <ReloadOutlined/>
                            </Button>
                        </Form.Item>
                    </Row>)
                    :
                    (<Row>
                        <Col span={8}>
                        <Form.Item name='labelName' label='Label Name' style={{fontSize:'18px',fontWeight:600}}>
                            <Input />
                        </Form.Item>
                        </Col>
                        <Col span={8}>
                        
                        <Form.Item name='description' label='Description' style={window.innerWidth < 769 ?{fontSize:'18px',fontWeight:600}:{marginLeft:'10px',fontSize:'18px',fontWeight:600}}>
                            <Input />
                        </Form.Item>
                        </Col>
                        <Col span={5}>
                        
                        <Form.Item name='colorSelect' label='Color' style={window.innerWidth < 769 ?{fontSize:'18px',fontWeight:600}:{marginLeft:'10px',fontSize:'18px',fontWeight:600}}>
                            <Input onClick={openColorPalette} value={selectedColor}/>
                            {isOpenColorPalette && 
                                <div className= 'popover'>
                                    <div className='cover' >
                                        <ChromePicker  
                                            color={selectedColor}
                                            // value={record.labels[0].color_hex}
                                            onChange={ (e) => onColorChange(e)}
                                            onChangeComplete={ (e) => onCompleteColorChange(e)} />
                                    </div>
                                </div>}
                        </Form.Item>
                        </Col>
                        <Col span={2}>
                        <Form.Item style={{marginTop:'30px'}}>
                            <Button onClick={handleGenerateCode} type='link'>
                                <ReloadOutlined/>
                            </Button>
                        </Form.Item>
                        </Col>
                    </Row>)}
                    <Button 
                        type='primary' 
                        htmlType='submit' 
                        style={window.innerWidth < 769 ?{marginLeft:'50px',borderRadius:'16px',width:'87px',height:'36px'}:{borderRadius:'16px',width:'87px',height:'36px'}}>
                          Save
                        </Button>
                        <Button 
                          // type='primary' 
                          onClick={resetForm}
                          htmlType='reset' 
                          style={window.innerWidth < 769 ?{marginLeft:'12px',borderRadius:'16px',width:'87px',height:'36px'}:{marginLeft:'12px',borderRadius:'16px',width:'87px',height:'36px'}}
                        >
                        Cancel
                    </Button>
                    <Row style={{marginTop:'7px'}}>
                        <Typography><b>Note:- </b>Please refresh responses list after adding new label</Typography>
                    </Row>
                </Form>
            </Card>}
            <Card 
                style={window.innerWidth < 769 ?
                        {borderRadius:'16px',backgroundColor:'#F7F7F8'}:
                        {backgroundColor:'#F7F7F8',borderRadius:'16px', width: "80vw",
                        marginTop:'25px',marginLeft:'94px',textAlign:'left'}}
                title={<Title>Labels</Title>} 
                headStyle={window.innerWidth < 769 ?{fontSize:'20px',flex:'none'}:{fontSize:'20px'}}
                extra={<Button onClick={addNewLabel} type='link' style={{fontSize:'20px'}}>
                            Add New Label
                        </Button>
                    }> 
                        {window.innerWidth < 769 ?
                            <TableForMobile
                                rowClassName='label-table'
                                columns={columns}
                                dataSource={tempArr}
                                bordered={false}
                            /> :             
                            <TableForDesktop
                                rowClassName='label-table'
                                columns={columns}
                                dataSource={tempArr}
                                bordered={false}
                            />}
            </Card>
            
        </>
    )
}
