import React, { useEffect, useState } from "react";

import { Button, Card, Checkbox, Divider, message, Spin } from "antd";

import axios from "axios";

import { useParams } from "react-router-dom";

import FormProofsComponent from "./FormProofsComponent";

interface Iproof {
  name: string;
  version: string;
  id?: number;
  url: string;
}

const FormProofs = (props: any) => {
  const { addProofs } = props;

  const [oriProofs, setOriProofs] = useState<Iproof[]>([]);

  const [loading, setLoading] = useState(true);

  const [allowAdd, setAllowAdd] = useState(false);

  const [includeInFormSubmission, setIncludeInFormSubmission] =
    useState<boolean>(false);

  let { uuid } = useParams<any>();

  const getAllProofs = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/${uuid}/proofs/`)
      .then((res) => {
        console.log("---- res in get proofs---- ", res);
        setOriProofs(res.data.data);
        setLoading(false);
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        setLoading(false);
      });
  };

  const addNewProof = async (proof: any) => {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/${uuid}/proofs/`, proof)
      .then((res) => {
        console.log("---- res post add proof ---- ", res);
        setLoading(false);
        setOriProofs([]);
        getAllProofs();
        setAllowAdd(false);
        message.success("Data was added successfully");
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        setLoading(false);
        message.error("Data could not be added");
      });
  };

  const updateProof = async (proof: any) => {
    console.log("----> update proof ---->", proof);

    setLoading(true);

    await axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/${uuid}/proofs/${proof.id}/`,
        proof
      )
      .then((res) => {
        console.log("---- res in update proof ---- ", res);
        setLoading(false);
        setOriProofs([]);
        getAllProofs();
        setAllowAdd(false);
        message.success("Data was updated successfully");
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Data could not be updated");
        setLoading(false);
      });
  };

  const deleteProof = async (id: number) => {
    setLoading(true);
    await axios
      .delete(`${process.env.REACT_APP_SERVER_URL}/api/${uuid}/proofs/${id}/`)
      .then((res) => {
        console.log("---- res in delete proof ---- ", res);
        setLoading(false);
        setOriProofs([]);
        getAllProofs();
        message.success("Data was deleted successfully.");
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        setLoading(false);
        message.error("Data could not be deleted");
      });
  };

  const handleChangeAddProofToForm = (e: any) => {
    setIncludeInFormSubmission(e.target.checked);
    updateForm({ add_proofs: e.target.checked });
  };

  const updateForm = async (props: any) => {
    await axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/custom_forms/${uuid}/`,
        props
      )
      .then((res) => {
        console.log("---- res ---- ", res);

        message.success("Form settings were updated.");
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Form settings update failed.");
      });
  };

  useEffect(() => {
    getAllProofs();

    if (addProofs) {
      setIncludeInFormSubmission(true);
    }
  }, []);

  if (loading) {
    return <Spin />;
  } else {
    return (
      <Card
        title="Terms and Conditions & Privacy Policy"
        style={{ margin: "10px" }}
      >
        {oriProofs.length > 0 && (
          <>
            <Checkbox
              value={includeInFormSubmission}
              onChange={(e) => handleChangeAddProofToForm(e)}
              defaultChecked={includeInFormSubmission}
            >
              Include in submission
            </Checkbox>
            <Divider />
          </>
        )}
        {oriProofs.length > 0 &&
          oriProofs.map((proof: any, index: number) => (
            <div key={index}>
              {" "}
              <FormProofsComponent
                proof={proof}
                mode="fromServer"
                addNewProof={addNewProof}
                deleteProof={deleteProof}
                updateProof={updateProof}
              />{" "}
            </div>
          ))}
        {oriProofs.length === 0 && (
          <p> You have not added any Terms and Conditions or Privacy Policy</p>
        )}
        {!allowAdd && oriProofs.length < 2 && (
          <>
            <Divider />
            <Button
              onClick={() => setAllowAdd(true)}
              type="primary"
              className="ct_forms_primary_button"
              style={{ margin: "auto" }}
            >
              Add New
            </Button>
          </>
        )}
        {allowAdd && (
          <FormProofsComponent
            addNewProof={addNewProof}
            setAllowAdd={setAllowAdd}
          />
        )}
      </Card>
    );
  }
};

export default FormProofs;
