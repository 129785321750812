import React from "react";

import { Card, Col, Row } from "antd";
import Layout from "antd/lib/layout/layout";

import Profile from "../../../app/containers/profile/Profile";
import ProfileLogo from "../../../app/containers/profile/ProfileLogo";
import DeleteAccount from "../../../app/containers/profile/DeleteAccount";
import ChangePassword from "../changePassword/ChangePassword";
import { Link } from "react-router-dom";

const SettingsPage = () => {
  return (
    <Layout
      style={{
        marginTop: "20px",
        textAlign: "left",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <Row
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Col xs={24} sm={24} md={24} xl={16} xxl={12}>
          <div
            style={{
              width: "auto",
            }}
          >
            <p
              style={{
                fontSize: "26px",
                fontWeight: "bold",
                color: "#696969	",
                width: "auto",
                margin: "10px 20px 5px 0 ",
                padding: "0",
              }}
            >
              SETTINGS
            </p>
          </div>
        </Col>

        <Col xs={24} sm={24} md={24} xl={16} xxl={12}>
          <div
            style={{
              width: "auto",
            }}
          >
            <Profile />
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} xl={16} xxl={12}>
          <div
            style={{
              width: "auto",
            }}
          >
            <ProfileLogo />
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} xl={16} xxl={12}>
          <div
            style={{
              width: "auto",
              marginBottom: "25px",
            }}
          >
            <ChangePassword />
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} xl={16} xxl={12}>
          <div
            style={{
              width: "100%",
              marginBottom: "50px",
            }}
          >
            <DeleteAccount />
          </div>
        </Col>
      </Row>
    </Layout>
  );
};

export default SettingsPage;
