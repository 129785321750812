import React, { useEffect, useState } from "react";

import { Col, Layout, message, Row } from "antd";

import axios from "axios";

import AntSpinner from "../../../shared/customComponents/spinner/AntSpinner";
import FormsList from "../../components/forms/formsList/FormsList";
import AddFormModal from "../../components/forms/addFormModal/AddFormModal";
import { StateType } from "../../core/redux/types";
import { connect } from "react-redux";

const Forms = (props: any) => {
  const [loading, setLoading] = useState(true);

  const [formData, setFormData] = useState<undefined | any>();
  const [count, setCount] = useState();
  const [next, setNext] = useState();
  const [previous, setPrevious] = useState();

  const [isModalOpen, setModalOpen] = useState(false);

  const getAllForms = async (setLoading: any) => {
    // setLoading(true);
    const url = props.state.auth.userData.is_staff
      ? `${process.env.REACT_APP_SERVER_URL}/api/templates/?size=1000`
      : `${process.env.REACT_APP_SERVER_URL}/api/custom_forms/?size=1000`;

    await axios
      .get(url)
      .then((res) => {
        console.log("---- res ---- ", res);
        setFormData(res.data.data);
        setCount(res.data.count);
        setNext(res.data.next);
        setPrevious(res.data.previous);
        setLoading(false);
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Could not get Forms.");
        setLoading(false);
      });
  };

  const deleteFormById = async (uuid: any) => {
    const url = props.state.auth.userData.is_staff
      ? `${process.env.REACT_APP_SERVER_URL}/api/templates/${uuid}/`
      : `${process.env.REACT_APP_SERVER_URL}/api/custom_forms/${uuid}/`;

    await axios
      .delete(url)
      .then((res) => {
        console.log("---- res ---- ", res);
        message.success("Form was deleted successfully.");
        // setLoading(false);
        getAllForms(setLoading);
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Form could not be deleted.");
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllForms(setLoading);
  }, []);

  return (
    <>
      {loading ? (
        <AntSpinner />
      ) : (
        <Layout
          style={{
            // marginTop: "50px",
            textAlign: "left",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
          className="profile-layout"
        >
          <div>
            <br />
            <Row
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  {formData && (
                    <FormsList
                      formData={formData}
                      next={next}
                      previous={previous}
                      count={count}
                      deleteFormById={deleteFormById}
                      setModalOpen={setModalOpen}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </div>
          {isModalOpen && (
            <AddFormModal
              isModalOpen={isModalOpen}
              setModalOpen={setModalOpen}
              loading={loading}
              setLoading={setLoading}
              getAllForms={getAllForms}
            />
          )}
        </Layout>
      )}
    </>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(Forms);
