import React, { useEffect, useState } from "react";

import { Card, Col, Layout, message, Row } from "antd";

import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { Redirect } from "react-router-dom";
import axios from "axios";
import ReactGa from "react-ga";

import { GetUserData } from "../../../auth/core/redux/actions";
import AntSpinner from "../../../shared/customComponents/spinner/AntSpinner";
import { StateType } from "../../core/redux/types";
import DataUsage from "./DataUsage";

const AdminDashboard = (props: any) => {
  const { getUserData } = props;

  const [redirectToForm, setRedirectToForm] = useState(false);
  const [formPath, setFormPath] = useState("");

  const [loading, setLoading] = useState<boolean>(false);

  const [formStats, setFormsStats] = useState<any>(null);

  const checkIfRedirect = () => {
    const pathToRedirect = localStorage.getItem("redirect-path");
    if (pathToRedirect) {
      setFormPath(pathToRedirect);
      localStorage.removeItem("redirect-path");
      setRedirectToForm(true);
    }
  };

  useEffect(() => {
    checkIfRedirect();
  }, []);

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    // to report page view
    ReactGa.pageview("/dashboard");
  }, []);

  const getStatsForDashboard = async (setLoading: any) => {
    setLoading(true);

    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/users/stats/`)
      .then((res) => {
        console.log("---- res ---->  ", res.data);
        setFormsStats(res.data);
        setLoading(false);
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Could not get user stats.");
        setLoading(false);
      });
  };

  useEffect(() => {
    getStatsForDashboard(setLoading);
  }, []);

  if (loading) {
    return <AntSpinner />;
  }

  return (
    <Layout
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      {redirectToForm && <Redirect to={formPath} />}

      <br />
      {formStats && (
        <>
          <Row
            gutter={24}
            style={{
              width: "80vw",
              marginBottom: "20px",
            }}
          >
            <Col span={window.innerWidth > 769 ? 12 : 24}>
              <Card
                title="Your data usage"
                style={{
                  width: "100%",
                  height: "100%",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <Row gutter={24}>
                  <Col span={24}>
                    <DataUsage
                      usedData={formStats.used_storage}
                      totalData={formStats.allocated_storage}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </Layout>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getUserData: () => dispatch(GetUserData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
