import { IFormData } from "./interfaces";

export const initialFormData: IFormData = {
  uuid: "",
  name: "",
  title: "",
  description: "",
  elements: {},
  selectedElement: null,
  elementIds: [],
};

export const formDataReducer = (
  state: IFormData = initialFormData,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case "ADD_UUID":
      return { ...state, uuid: action.payload };
    case "ADD_NAME":
      return { ...state, name: action.payload };
    case "ADD_TITLE":
      return { ...state, title: action.payload };
    case "ADD_DESCRIPTION":
      return { ...state, description: action.payload };
    case "UPDATE_FORM":
      return action.payload;
    case "CREATE_FIELD":
      const newElement = Object.values(action.payload)[0];
      return {
        ...state,
        elements: { ...state.elements, ...action.payload },
        selectedElement: newElement,
        elementIds: [...state.elementIds, newElement],
      };
    case "UPDATE_FIELD_VALUE":
      return { ...state, elements: { ...state.elements, ...action.payload } };
    case "CHANGE_SELECTED_TYPE":
      return {
        ...state,
        elements: {
          ...state.elements,
          [action.payload.uuid]: { ...action.payload },
        },
      };
    case "DELETE_FIELD":
      const { elements } = state;
      if (!elements) return state;
      const elementIds = state.elementIds.filter(
        (element) => element.uuid !== action.payload
      );
      delete elements[action.payload];
      return { ...state, elements, elementIds };
    case "ORDER_FIELDS":
      return { ...state, elementIds: action.payload };
    case "UPDATE_ELEMENT_POSITION":
      const element = state.elements![action.payload.uuid];
      element.position = action.payload.position;
      return {
        ...state,
        elements: { ...state.elements, element },
      };
    case "SELECT_ELEMENT":
      return {
        ...state,
        selectedElement: action.payload,
      };
    case "SET_QUESTION_IMAGE_FILE":
    case "CHANGE_QUESTION_IMAGE_SIZE":
    case "SET_CHOICE_IMAGE_FILE":
    case "DELETE_QUESTION_IMAGE":
      return { ...state, elements: { ...state.elements, ...action.payload } };

    default:
      return state;
  }
};
