import React, { useEffect, useState } from "react";

import { Button, message, Select } from "antd";
import axios from "axios";
import { useParams } from "react-router-dom";

const { Option } = Select;

const CategoryToTemplate = (props: any) => {
  const { category, initialCategory } = props;

  const params = useParams<any>();

  const { uuid } = params;

  const [selectedCategory, setSelectedCategory] = useState<any>([]);

  useEffect(() => {
    setSelectedCategory(initialCategory);
  }, [props]);

  const handleChange = (value: any) => {
    setSelectedCategory(value);
  };

  const handleSubmit = async () => {
    const finalData: any = [];

    await selectedCategory.map((category: any) => {
      const localData: any = category;
      localData.category = parseInt(category.key);

      finalData.push(localData);
    });
    setCategoryToTemplate(await finalData);
  };

  const setCategoryToTemplate = async (dataToSet: any) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/templates/${uuid}/`;

    await axios
      .put(url, { categories: dataToSet })
      .then((res: any) => {
        console.log("---- res ---- ", res);
        message.success("Form details were updated.");
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Form details update failed.");
      });
  };

  return (
    <div>
      {selectedCategory && initialCategory && (
        <>
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select category"
            defaultValue={initialCategory}
            labelInValue
            onChange={handleChange}
          >
            {category &&
              category.length > 0 &&
              category.map((category: any) => (
                <Option
                  key={category.id}
                  value={category.id}
                  label={category.name}
                  title={category.name}
                >
                  {category.name}
                </Option>
              ))}
          </Select>
          <Button
            onClick={() => handleSubmit()}
            type="primary"
            style={{ margin: "20px" }}
          >
            Save Details
          </Button>
        </>
      )}
    </div>
  );
};

export default CategoryToTemplate;
