import React, { useState } from "react";

import {
  Button,
  Card,
  Col,
  message,
  Modal,
  Row,
  Space,
  Typography,
} from "antd";

import axios from "axios";

import { v4 as uuid } from "uuid";

import { Redirect } from "react-router-dom";
import { StateType } from "../../../core/redux/types";
import { connect } from "react-redux";
import NewForm from "./NewForm";
import FormFromTemplate from "./FormFromTemplate";
import NewFormLogo from "./newFormLogo.svg";
import TemplateLogo from "./templateLogo.svg";

const AddFormModal = (props: any) => {
  const [formName, setFormName] = useState<string>("");

  const [redirectToFormUrl, setRedirectToFormUrl] = useState("/forms/all");

  const [shouldRedirectToForms, setShouldRedirectToForms] = useState(false);

  const [mode, setMode] = useState<any>(null);

  const [loading, setLoading] = useState(false);

  const { isModalOpen, setModalOpen } = props;

  const handleCancel = () => {
    setFormName("");
    setModalOpen(false);
  };

  const handleOk = async () => {
    setLoading(true);

    const newUuid = uuid();

    const url = props.state.auth.userData.is_staff
      ? `${process.env.REACT_APP_SERVER_URL}/api/templates/`
      : `${process.env.REACT_APP_SERVER_URL}/api/custom_forms/`;

    await axios
      .post(url, {
        uuid: newUuid,
        name: newUuid,
        title: formName,
        description: "",
        is_public: true,
        login_required: false,
        is_template: props.state.auth.userData.is_staff,
        tags: [],
        categories: [],
        domains: [],
        metadata: {
          layout: "classic",
        },
      })
      .then((res) => {
        message.success("Form created successfully.");
        setLoading(false);
        setRedirectToFormUrl(`/forms/${newUuid}/builder`);
        setShouldRedirectToForms(true);
        window.location.href = `/forms/${newUuid}/builder`;
        handleCancel();
      })
      .catch((err: any) => {
        console.log("errors could not be get", err.response.data);
        if (err?.response?.data?.errors?.quota) {
          message.error(err.response.data.message);
        } else {
          message.error("Form could not be created.");
        }
        setLoading(false);
        handleCancel();
      });
  };
  const items = [
    {
      src: NewFormLogo,
      mode: "NEW",
      alt: "new form logo",
      label: "Start from scratch",
    },
    {
      src: TemplateLogo,
      mode: "TEMPLATE",
      alt: "template logo",
      label: "Choose from template",
    },
  ];

  return (
    <Modal
      // title="Create Form"
      title={
        mode && (
          <Button type="text" onClick={() => setMode(null)}>
            BACK
          </Button>
        )
      }
      visible={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      bodyStyle={{
        backgroundColor: "#F7F7F8",
        maxHeight: "80vh",
        overflowY: "scroll",
      }}
      centered
      mask
      width={"80vw"}
    >
      {mode === null && (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
          }}
        >
          <h3 className="ct_forms_h3">Choose Mode</h3>
          <Row
            gutter={24}
            style={{
              margin: "auto",
            }}
          >
            {items.map((item, index) => (
              <Col span={window.innerWidth > 900 ? 12 : 24} key={index}>
                <Card
                  onClick={() => setMode(item.mode)}
                  style={{ width: 240, margin: "auto" }}
                >
                  <img
                    style={{ width: 100, height: 100 }}
                    src={item.src}
                    alt={item.alt}
                  />
                </Card>
                <Typography.Title className="ct_forms_h3" level={5}>
                  {item.label}
                </Typography.Title>
              </Col>
            ))}
          </Row>
        </div>
      )}

      {mode === "NEW" && (
        <NewForm
          formName={formName}
          setFormName={setFormName}
          handleOk={handleOk}
        />
      )}
      {mode === "TEMPLATE" && <FormFromTemplate />}

      {shouldRedirectToForms && <Redirect to={redirectToFormUrl} />}
    </Modal>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(AddFormModal);
