import React, { useEffect, useReducer, useState } from "react";
import { Button, Input, Modal } from "antd";
import {
  DeleteOutlined,
  PlusOutlined,
  FileImageOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { MAX_CHOICES } from "../../constants";
import { IElement, IChoice } from "../../utils/interfaces";
import { useMounted } from "../../utils/useMounted";
import message from "../../utils/message";
import UploadLogo from "../UploadLogo";
import AdvanceOptionsDrawer from "./ElementPreview/AdvanceOptionsDrawer";

import "./Choice.css";
import FormItem from "./ElementPreview/FormItem";

interface IProps {
  state: IElement;
  children?: React.ReactNode;
  disabled?: boolean;
  componentDisabled?: boolean;
  updateFieldValue: (choices: object) => void;
  noImage?: boolean;
  handleImageDelete: (imageKey: string) => number;
  handleImageUpload: (element: IElement) => IElement;
  updateElement: (element: IElement) => void;
  deleteButton: React.ReactNode;
  extraProps: React.ReactNode;
}

const choiceReducer = (
  state: any,
  action: {
    type: string;
    payload: any;
  }
) => {
  let _state = [...state];
  switch (action.type) {
    case "ADD_CHOICE":
      return [..._state, action.payload];
    case "UPDATE_CHOICE":
      const { index, value } = action.payload;
      _state[index] = { ..._state[index], choice_text: value };
      return _state;
    case "DELETE_CHOICE":
      _state.splice(action.payload, 1);
      return _state;
    case "DELETE_CHOICE_IMAGE":
      _state[action.payload.index] = { ...action.payload.choice };
      return _state;

    default:
      return state;
  }
};

export default function Choice(props: IProps) {
  let choices = props.state?.plugin_data?.choices;

  const [state, dispatch] = useReducer(choiceReducer, choices);
  const [textChoices, setTextChoices] = useState("");
  const [newChoice, setNewChoice] = useState<string>("");
  const [helpText, setHelpText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [index, setIndex] = useState<number>();
  const isMounted = useMounted();

  useEffect(() => {
    if (isMounted) {
      const nonEmptyChoices = state.filter(
        (choice: IChoice) => choice.choice_text
      );
      props.updateFieldValue({ choices: nonEmptyChoices });
    }
  }, [state]);

  const addChoice = () => {
    const existingChoice = state.find(
      (choice: IChoice) => choice.choice_text === newChoice
    );
    if (existingChoice) {
      setHelpText(`${newChoice} exists`);
    } else if (!newChoice) {
      setHelpText(`Empty choice not allowed`);
    } else {
      dispatch({ type: "ADD_CHOICE", payload: { choice_text: newChoice } });
      setNewChoice("");
      setHelpText("");
    }
  };

  const deleteChoice = async (index: number) => {
    const _choice = state[index];
    if (_choice.imageKey && !(await deleteChoiceImage(index))) {
      message.error("Choice delete failed");
      return;
    }
    dispatch({ type: "DELETE_CHOICE", payload: index });
  };

  const onChangeChoice = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    dispatch({
      type: "UPDATE_CHOICE",
      payload: { index, value: e.target.value },
    });
    setHelpText("");
  };

  const handleChangeNewChoice = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewChoice(e.target.value);
    setHelpText("");
  };

  const setChoicesByText = (choices: string) => {
    const commaSplits = choices.split(",");
    commaSplits.forEach((choice: string) => {
      if (choice) {
        const existingChoice = state.find(
          (_choice: IChoice) => _choice.choice_text === choice
        );
        if (existingChoice) message.error(`Choice "${choice}" already exists`);
        else dispatch({ type: "ADD_CHOICE", payload: { choice_text: choice } });
      } else {
        message.error(`Empty choice not allowed`);
      }
    });
    setTextChoices("");
  };

  const deleteChoiceImage = async (index: number): Promise<number> => {
    const choice = state[index];
    const _choice = { ...choice };
    const deleteResult = await props.handleImageDelete!(_choice.imageKey);
    console.log(`deleteResult`, deleteResult);
    if (deleteResult) {
      delete _choice.imageKey;
      delete _choice.imageUrl;
      dispatch({
        type: "DELETE_CHOICE_IMAGE",
        payload: { index, choice: _choice },
      });
      return 1;
    }
    return 0;
  };

  const advancedOptions = (
    <div>
      <FormItem label="Multiple Choices" elementStyle={{ marginRight: "5px" }}>
        <Input.TextArea
          value={textChoices}
          placeholder={"apple, banana, mango"}
          onChange={(e) => setTextChoices(e.target.value)}
          autoSize
          disabled={props.componentDisabled}
        ></Input.TextArea>
      </FormItem>
      <Button
        onClick={() => setChoicesByText(textChoices)}
        disabled={props.componentDisabled || state.length === MAX_CHOICES}
      >
        <PlusOutlined />
      </Button>
    </div>
  );

  return (
    <>
      <div className="choice_container">
        {state &&
          state.map((choice: IChoice, index: number) => {
            return (
              <div>
                <div>
                  <div key={index} className="choice_row">
                    {props.children}
                    <Input
                      bordered={false}
                      className="choice_input"
                      value={choice.choice_text}
                      onChange={(e) => onChangeChoice(e, index)}
                      disabled={props.componentDisabled}
                    />
                    {!props.noImage &&
                      (choice.imageKey ? (
                        <div>
                          <img
                            src={choice.imageUrl}
                            style={{
                              height: 30,
                              width: 30,
                              paddingBottom: 10,
                            }}
                            alt="Choice"
                          />
                          <Button
                            size="small"
                            onClick={() => deleteChoiceImage(index)}
                            type="link"
                          >
                            <CloseOutlined />
                          </Button>
                        </div>
                      ) : (
                        <div className="choice_image">
                          <Button
                            size="small"
                            disabled={
                              props.componentDisabled || choice.imageUrl
                            }
                            onClick={() => {
                              setIsModalOpen(true);
                              setIndex(index);
                            }}
                            type="link"
                          >
                            <FileImageOutlined />
                          </Button>
                        </div>
                      ))}
                    <Button
                      size="small"
                      disabled={
                        props.componentDisabled ||
                        Object.keys(state).length === 1
                      }
                      onClick={() => deleteChoice(index)}
                      type="link"
                    >
                      <DeleteOutlined />
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}
        <div className="choice_row">
          {props.children}
          <Input
            bordered={false}
            className="choice_input"
            onPressEnter={addChoice}
            value={newChoice}
            onChange={handleChangeNewChoice}
            disabled={props.componentDisabled}
            placeholder="Add choice"
          />
          <Button
            type="link"
            onClick={addChoice}
            disabled={props.componentDisabled || state.length === MAX_CHOICES}
          >
            <PlusOutlined />
          </Button>
        </div>
      </div>
      <p style={{ color: "red" }}>{helpText}</p>
      {isModalOpen && (
        <Modal
          title="Upload Image"
          visible={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          footer={null}
        >
          <UploadLogo
            element={props.state!}
            setIsModalOpen={setIsModalOpen}
            choiceIndex={index}
            handleImageUpload={props.handleImageUpload}
            updateElement={props.updateElement}
          />
        </Modal>
      )}
      <AdvanceOptionsDrawer
        componentDisabled={props.componentDisabled}
        deleteButton={props.deleteButton}
        advancedOptions={advancedOptions}
        state={props.state}
        extraProps={props.extraProps}
      />
    </>
  );
}
