import React from "react";

import { Button, Form, Input } from "antd";

const NewForm = (props: any) => {
  const { formName, setFormName, handleOk } = props;

  return (
    <div>
      <h3 style={{ marginLeft: 10 }} className="ct_forms_h3">
        Name your form
      </h3>
      <Form>
        <Form.Item>
          <Input
            className="ct_forms_input_field"
            bordered={false}
            placeholder="Form Name"
            value={formName}
            maxLength={30}
            onChange={(e) => setFormName(e.target.value)}
          ></Input>
          <Button
            style={{ marginTop: 20 }}
            type="primary"
            className="ct_forms_primary_button"
            onClick={handleOk}
          >
            Create
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default NewForm;
