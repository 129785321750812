import React, { useState } from "react";

import { Typography, Alert, message } from "antd";
import { WarningTwoTone } from "@ant-design/icons";

import { Link } from "react-router-dom";

import axios from "axios";

const { Text } = Typography;

const PrivacyPolicyBar = () => {
  const [verifcationMailSent, setVerificationMailSent] = useState(false);

  const handleResendVerificationEmail = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/api/users/resend-verification-email/`
      )
      .then(() => {
        message.success("Verification email sent successfully.");
        setVerificationMailSent(true);
      })
      .catch((err) => {
        console.log("err", err);
        message.error("Verification email could not be sent.");
      });
  };

  const alertBoxToReturnMobile = (
    <Text>
      <WarningTwoTone
        twoToneColor="red"
        style={{
          marginRight: "10px",
          color: "yellow",
          flexDirection: window.innerWidth > 500 ? "row" : "column",
        }}
      />
      Your email is unverified.{" "}
      {verifcationMailSent ? (
        "A Verification Email has been sent to your email-id."
      ) : (
        <div>
          <Link to="#" onClick={handleResendVerificationEmail}>
            Click here to re-send verification email.
          </Link>
        </div>
      )}
    </Text>
  );

  const alertBoxToReturn = (
    <Text>
      <WarningTwoTone
        twoToneColor="red"
        style={{
          marginRight: "10px",
          color: "yellow",
          flexDirection: window.innerWidth > 500 ? "row" : "column",
        }}
      />
      Your email is unverified.{" "}
      {verifcationMailSent ? (
        "A Verification Email has been sent to your email-id."
      ) : (
        <>
          <Link to="#" onClick={handleResendVerificationEmail}>
            Click here to re-send verification email.
          </Link>
        </>
      )}
    </Text>
  );

  return (
    <div
      style={{
        flex: 1,
        height: "40px",
        justifyContent: "center",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Alert
        type="warning"
        style={{
          width: "100%",
          textAlign: "center",
        }}
        message={
          window.innerWidth > 600 ? alertBoxToReturn : alertBoxToReturnMobile
        }
      />
    </div>
  );
};

export default PrivacyPolicyBar;
