import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./app/core/redux/store";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  beforeSend: (event) => {
    if (window.location.hostname === "localhost") {
      return null;
    } else {
      return event;
    }
  },
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Sentry.ErrorBoundary
        fallback={<p>Something went wrong. Please reload the page</p>}
        showDialog
      >
        <App />
      </Sentry.ErrorBoundary>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
