import React, { useState, useEffect } from "react";

import { Button, Layout, Menu } from "antd";
import {
  MenuOutlined,
  UserOutlined,
  DashboardOutlined,
  FormOutlined,
  QuestionOutlined,
} from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import Sider from "antd/lib/layout/Sider";

import { AnyAction } from "redux";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { GetUserData } from "../../../auth/core/redux/actions";
import { ThunkDispatch } from "redux-thunk";
import { NOTION_HELP_LINK } from "../../../shared/constants/constants";

const Sidebar = (props: any) => {
  const { isAuthenticated } = props.state.auth;

  const [userData, setUserData] = useState<any>();

  useEffect(() => {
    if (props.state?.auth?.userData) {
      console.log("userdata ---> ", props.state.auth.userData);
      setUserData(props.state.auth.userData);
    } else {
      props.getUserData();
    }
  }, [props]);

  const { toggleCollapsed, collapsed, isMobile, setCollapsed } = props;

  const [selectedKey, setSelectedKey] = useState<any[]>([]);

  const { pathname } = useLocation();

  const SidebarMenuForAll = (
    <>
      <Menu.Item key="1" icon={<DashboardOutlined />}>
        <Link to="/dashboard">Dashboard</Link>
      </Menu.Item>

      <Menu.Item key="2" icon={<FormOutlined />}>
        <Link to="/forms/all">Forms </Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<UserOutlined />}>
        <Link to="/account/settings">Settings </Link>
      </Menu.Item>
      <Menu.Item key="4" icon={<QuestionOutlined />}>
        <a href={NOTION_HELP_LINK} target="_blank" rel="noreferrer">
          Help{" "}
        </a>
      </Menu.Item>
    </>
  );

  const SidebarMenuForAdmin = (
    <>
      <Menu.Item key="1" icon={<DashboardOutlined />}>
        <Link to="/dashboard">Dashboard </Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<FormOutlined />}>
        <Link to="/forms/all">Templates </Link>
      </Menu.Item>
    </>
  );

  useEffect(() => {
    if (pathname.match("/dashboard")) {
      setSelectedKey(["1"]);
    }
    if (pathname.match("/forms") || pathname.match("/form-builder")) {
      setSelectedKey(["2"]);
    }
    if (pathname.match("/account/settings")) {
      setSelectedKey(["3"]);
    }
  }, [pathname]);

  useEffect(() => {
    if (window.innerWidth <= 1440) {
      setCollapsed(true);
    }
  }, []);

  const handleToggleCollapse = () => {
    if (window.innerWidth > 1279) toggleCollapsed();
  };

  if (!isAuthenticated) {
    return <></>;
  } else {
    if (isMobile) {
      return <Button onClick={() => toggleCollapsed()}>Sidebar</Button>;
    } else {
      return (
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={handleToggleCollapse}
          breakpoint={"lg"}
          style={{
            position: "fixed",
            overflow: "auto",
            height: "100vh",
            left: 0,
            marginRight: "24px",
            zIndex: 1000,
          }}
        >
          <Layout
            style={{
              float: "left",
              display: "flex",
              padding: "10px",
              flexDirection: "row",
              backgroundColor: "inherit",
              alignItems: "center",
            }}
          >
            {!collapsed ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  height: "44px",
                }}
              >
                <MenuOutlined
                  style={{
                    marginRight: "20px",
                    color: "white",
                    fontSize: "20px",
                  }}
                  onClick={window.innerWidth > 1279 && toggleCollapsed}
                />
                <Link
                  to="/dashboard"
                  style={{
                    color: "white",
                  }}
                >
                  <Title
                    style={{
                      color: "white",
                      textTransform: "uppercase",
                      margin: "0px",
                    }}
                    level={3}
                  >
                    CT Forms
                  </Title>
                </Link>
              </div>
            ) : (
              <Link
                to="/dashboard"
                style={{
                  color: "white",
                }}
              >
                <Title style={{ color: "white" }} level={5}>
                  CT FORMS
                </Title>
              </Link>
            )}
          </Layout>

          {userData && (
            <Menu
              mode="inline"
              theme="dark"
              inlineCollapsed={collapsed}
              style={{ height: "100vh" }}
              selectedKeys={selectedKey}
              onClick={(e) => {
                setSelectedKey([e.key]);
              }}
            >
              {userData && userData.is_staff
                ? SidebarMenuForAdmin
                : SidebarMenuForAll}
            </Menu>
          )}
        </Sider>
      );
    }
  }
};

const mapStateToProps = (state: any) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getUserData: () => dispatch(GetUserData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
