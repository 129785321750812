import React from "react";
import axios from "axios";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Col, Row, Button,Tag,Modal,Tooltip,Input,Form,Select, message } from "antd";
import ChoiceResponse from "../formBuilderPage/ChoiceResponse";
import FileUploadType2 from '../formBuilderPage/FileUploadType2'
import {MailOutlined,InboxOutlined,EyeOutlined,DownloadOutlined,SyncOutlined} from '@ant-design/icons'
import moment from "moment";
import '../../../App.less'
import MDEditor from "@uiw/react-md-editor";
import TableForDesktop from "./TableForDesktop";
import TableForMobile from "../drive/TableForMobile";
import ViewResponseModal from "./ViewResponseModal";
import ChoiceResponseForMultipleCheckbox from "../formBuilderPage/ChoiceResponseForMultipleCheckbox";
const {Option} = Select
const ResponsesList = (props: any) => {
  const params = useParams<any>();
  const [formData, setFormData] = React.useState<any>();
  const { uuid, id: submissionId } = params;
  const {
    handleClickViewResponse,
    // labelsList
  } = props;
  
  const [currentPageNo, setCurrentPageNo] = React.useState<number>(1);
  const [ellipsis, setEllipsis] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [isId, setIsId] = React.useState<number>(0)
  const [devices, setDevices] = React.useState(false)
  const [isViewNote, setViewNote] = React.useState(false)
  const [isEditNote, setEditNote] = React.useState(false)
  const [isFilterField,setFilterField]=React.useState(false)
  const [noteData, setNoteData] = React.useState('')
  const [textData,setTextData] = React.useState<any>();
  const [labelsList, setlabelsList] = React.useState<any>()
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const [recordForLabels, setRecordForLabels] = React.useState<any>()
  const [selectedRecord, setSelectedRecord] = React.useState<any>();
  const [isShowModal, setShowModal] = React.useState(false)
  const [selectedValue,setSelectedValue]=React.useState<React.Key[]>([]);
  const [viewRecord, setViewRecord] = React.useState<any>()
  const [formSubmissionData, setFormSubmisionData] = React.useState<any>();
  const [submissionsCount, setSubmissionsCount] = React.useState<number>(0);
  const [currentIndex, setCurrentIndex] = React.useState<number | undefined>();
  const [modalData, setModalData] = React.useState<any>(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isAllrowSelected, setAllRowSelected] = React.useState(false);
  const accessToken = localStorage.getItem("accessToken");
  if(!accessToken){
    window.location.pathname='/login'
  }
  const newUrl = window.location
  
  const getLabelsData=async()=>{
          await axios
            .get(
                `${process.env.REACT_APP_SERVER_URL}/api/${uuid}/labels`
            )
            .then( (result1) => {
                setlabelsList(result1.data.data);
            })
            .catch((err: any) => {
                console.log("errors could not be get", err);
            });
        }
    
  const handleNotes=async(record:any,setRecords:any)=>{
    await axios
      .patch(`${process.env.REACT_APP_SERVER_URL}/api/${uuid}/saved-data/read/?set_read=${setRecords.setRead}&update_all=${setRecords.updateAll}`
     , {response_ids:record})
      .then((res) => {
        getAllFormSubmissionsByUuid(setLoading)
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
      });
  }
  const requestDownloadCsv = async (setLoading: any) => {
    setLoading(true);

    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/${uuid}/saved-data/export/`
      )
      .then(async (result1) => {
        message.info("CSV will be sent to your email.");
        setLoading(false);
      })
      .catch((err: any) => {
        message.error("Could not send data.");
        setLoading(false);
      });
  };

  const handleNotes2=async(id:number,record:any)=>{
    await axios
     .patch(`${process.env.REACT_APP_SERVER_URL}/api/${uuid}/saved-data/${id}`
     , {notes:record})
       .then((res) => {
        getAllFormSubmissionsByUuid(setLoading)
        message.success('Response '+id+' Notes Updated Successfully!')
      })
      .catch((err: any) => {
        message.error('Failed to update notes')
      });
  }
  const assignLabel=async(id:number,record:any)=>{
     await axios
     .patch(`${process.env.REACT_APP_SERVER_URL}/api/${uuid}/saved-data/${id}`
     , {labels:record})
       .then((res) => {
        getAllFormSubmissionsByUuid(setLoading)
        message.success('Response '+id+' Labels Updated Successfully!')
         setSelectedRowKeys([])
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error('Failed to update Labels')
      });
  }
  const callNewAction=async(record:any,newIndex:number)=>{
    var recordId=[]
    let setRecords={setRead:true,updateAll:false}
    setViewRecord(record);
     await setCurrentIndex(newIndex);
    await setModalData(modalData);
    await setIsModalOpen(true);
    setShowModal(true)
    recordId.push(record.id)
    handleNotes(recordId,setRecords)
  }
   const changeCurrentIndex = async (index: number) => {
    const newPageNumber = Math.ceil(index / 10);
    let notFoundData=[]
      const foundData=formSubmissionData.find((item:any)=>item.id===index)
       notFoundData.push({msg:'No record found',id:index})
      if(foundData !== undefined){
        await setViewRecord(foundData);
        await setCurrentPageNo(await newPageNumber);
        setCurrentIndex(index);
        await setIsModalOpen(true);
      }else{
        await setViewRecord(notFoundData)
      }
  };
  const rowSelection  = {
    selectedRowKeys,
    onChange: (record:any) => {
      setSelectedRowKeys(record)
    },
    onSelect:(record:any,index:number)=>{
      setDevices(record.read)
      setSelectedRecord(record.id)
      setRecordForLabels(record)
    },
    onSelectAll:(record:any)=>{
      setAllRowSelected(record)
      setDevices(tempArr[0].read)
    }
  };

var tempArr =formSubmissionData!==undefined && formSubmissionData.map((item:any,index:number)=>{
   const tempArray={
    key: index,
    created:item.created,
    data:item.data,
    id:item.id,
    labels:item.labels,
    metadata:item.metadata,
    notes:item.notes,
    read:item.read,
    user:item.user
  };
  return tempArray
})
const handleDescription=(response:any)=>{
  setTextData(response);
  setEllipsis(!ellipsis)
}
const handleLabelSelect=(id:number,values:any)=>{
  
    const labels=values.map((item:any,idx:number)=>{
      return {form_label:item}
    })
 setFilterField(false)
  assignLabel(id,labels )
}
const handleEditNote=(note:any,id:number)=>{
  setEditNote(!isEditNote)
  setNoteData(note)
  setIsId(id)
  setViewNote(false)
}
const onFormDetailsUpdate=(value:any)=>{
  setNoteData(value)
}
const onEditNoteDetail=()=>{
  handleNotes2(isId,noteData)
  setEditNote(!isEditNote)
}
  const getAllFormSubmissionsByUuid = async (setLoading:any) => {
    setLoading(true)
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/api/${uuid}/saved-data/?size=1000`
      )
      .then( (result1) => {
        setFormSubmisionData(result1.data.data);
        getElementsByFormUuid(result1)
        getLabelsData()
        setLoading(false)
        if (submissionId && result1.data.data.length > 0) {
          const modalDataToSet = result1.data.data.filter(
            (data: any) => data.id == submissionId
          );

          if (modalDataToSet.length > 0) {
            handleClickViewResponse(modalDataToSet[0], 0);
          }
        } else if (result1.data.data.length > 0) {
          handleClickViewResponse(result1.data.data[0], 0);
        }
        setSubmissionsCount(result1.data.data.length); 
         
      })
      .catch((err: any) => {
        setLoading(false)
        console.log("errors could not be get", err);
      });
  };

  const getElementsByFormUuid =  (formData:any) => {
    if (formData) {
       axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/${uuid}/elements/?size=50`
        )
        .then((result1) => {
          const elementData = result1.data.data;
          const element2={id:{},labels:{},note:{},submitterId:{}}
          let dictionary;
          dictionary = Object.assign(
            {},
            ...elementData.map((x: any) => ({
              [x.plugin_data.name]: x,
            }))
          )
          setFormData(dictionary);
        })
        .catch((err: any) => {
          // message.error("Could not get Form elements.");
        });
    }
  };
  React.useEffect(() => {
    if(formSubmissionData===undefined){
      getAllFormSubmissionsByUuid(setLoading);
       getLabelsData()
    }
  }, []);
  
  const getMarkasRead=(status:boolean)=>{
    setIsModalOpen(!isModalOpen)
    if(isAllrowSelected){
      let markRead={setRead:status,updateAll:true}
      let record2=Object.values(tempArr).map((item:any,index:number)=>{
       let record1=item.id
       return record1
      })
      handleNotes(record2,markRead)
    }else if(selectedRowKeys.length<=1){
      var record=[selectedRecord]
      let markRead={setRead:status,updateAll:false}
      handleNotes(record,markRead)
    }
    else{
      let record1=selectedRowKeys.map((id:any,idx:number)=>{
        let record2=Object.values(tempArr).filter((item:any)=>
          id===item.key
        )
        return record2
      })
      let recordIDs=Object.values(record1).map((item:any,idx:number)=>{
        let record= item[0].id
        return record
      })
      let markRead={setRead:status,updateAll:false}
      handleNotes(recordIDs,markRead)
    }
  }
  const renderData= formSubmissionData!==undefined && formSubmissionData.map((item:any,index:number)=>{
    let elementId={
      id:item.id,
      labels:item.labels,
      submitterId:item.metadata.form_submitter_email,
      date:
        <>
          {item.created !== "-" && (
            <p>
              {new Date(item.created).toLocaleDateString("en-gb", {
                year: "numeric",
                month: "long",
                day: "numeric",
                })
              },
              {
                Intl.DateTimeFormat("en", {
                  hour: "numeric",
                  minute: "numeric",
                }).format(new Date(item.created)) // 2 PM
              }
            </p>
          )}
          {item.created == "-" && <p>-</p>}
        </>,
      viewResponse:
        <>
          <Button type="link" 
            style={{
                    textOverflow: "ellipsis",
                    wordWrap: "normal",
                    width: "100px",
                    overflow: "none",
                    textAlign: "left",
                    alignContent: "left",
                    left: "0px",
                    margin: "0px",
                    padding: "0px",
                  }}
            onClick={()=>{callNewAction(item,item.id);}} >
              <p style={{fontSize:"15px",fontWeight:"bold"}}>
                <Link to={`/forms/${uuid}/responses/${item.id}`} >
                  {window.innerWidth<769?<EyeOutlined/>: 'View Response'}
                </Link>
              </p>
          </Button>
        </>,
      visitorDetails:item.metadata.ip,
      browser:item.metadata.browser_info,
      location:
        <>
          {item?.metadata?.city !== "-" && (
            <p>
              {item?.metadata?.city}, {item?.metadata?.country}
            </p>
          )}
          {item?.metadata?.city === "-" && <p>-</p>}
        </>,
      device:
        <p>
          {item?.metadata?.device_info !=="undefined undefined" ? item?.metadata?.device_info:"-"}
        </p>,
      notes:item.notes,
      data:item.data,
      read:item.read
    }
    return elementId
  })
    
    const renderMetadata=renderData!==undefined && Object.values(renderData).map((item:any,index:number)=>{
      let tagElement=item?.labels!==undefined ? item?.labels.map((item:any)=> {return item.form_label}):[]
  
      let tagElement2=item.labels.map((itm:any,idx:number)=>{
        let elementTag=<Tag color={itm.color_hex} >{itm.name}</Tag>
        return elementTag
      })
    let elementData3={
                      key:index,
                      id:item.id ,
                      labels:isShowModal || isFilterField ? tagElement2 :
                      <Form.Item name='labelName' style={{marginLeft:'20px'}}>
                      {labelsList !== undefined && 
                        <Select
                          mode="multiple"
                          style={{ marginTop:'10px',width:'200px' }}
                          placeholder="Labels"
                          defaultValue={tagElement}
                          onChange={(values)=>handleLabelSelect(item.id,values)}
                          > 
                          {Object.values(labelsList).map((itm:any,idx:number)=>{
                            return  <Option value={itm.id} label={itm.name}>
                                      <Tag color={itm.color_hex}>{itm.name}</Tag>
                                    </Option>
                            }
                          )}
                        </Select>
                      }
                    </Form.Item>
                    ,
                      submitterId:item.submitterId,
                      notes:
                      <>
                        <Button type='link' onClick={()=>handleEditNote(item.notes,item.id)}>
                          View Note
                        </Button>
                      </>,
                      date:item.date,
                      viewResponse:item.viewResponse,
                      visitorDetails:item.visitorDetails,
                      browser:item.browser,
                      location:item.location,
                      device:item.device,
                      read:item.read
                      }
    let elementData2=Object.values(item.data).map((itm:any,idx:number)=>{
      let elementData
        if(typeof itm.response==="object"){
          if(itm.response.length!==undefined ||itm.element_metadata.elementType==="Checkbox"){
              elementData={[itm.label]:<>
             
                {Object.values(itm.response).map((itm2:any)=>
                  Object.keys(itm2).includes('imageUrl')?                  
                  <ChoiceResponseForMultipleCheckbox
                    choice={itm.response}
                    elementType={itm.element_metadata.elementType}
                  />:
                  <Tag color='red'>{Object.values(itm2)}</Tag>
                )}
              </>}
          }
          else if(itm.response.length!==undefined ||itm.element_metadata.elementType==="Radio"){
             elementData={[itm.label]:<ChoiceResponse
                    choice={itm.response}
                    elementType={itm.element_metadata.elementType}
                  />
            }
          }
          else if(itm.element_metadata.elementType==='Upload' || itm.element_metadata.elementType==='Video'){
            elementData={[itm.label]:<FileUploadType2 fileData={itm.response} />}
          }
          else if(itm.element_metadata.elementType==="Dropdown" ){
            let res=Object.values(itm.response)
              elementData={[itm.label]:res}
          }
          else{
            let res=Object.values(itm.response)
            elementData={[itm.label]:res}
          }
        }
        else{
          if(itm.element_metadata.elementType==='DatePicker'){
            elementData={[itm.label]:moment(itm.response).format('DD MMMM,YYYY')}
          }else if(itm.element_metadata.elementType==='Link'){
            elementData={[itm.label]:<a href={itm.response} target="_blank" rel="noreferrer">Click here</a>}
          }else if(itm.element_metadata.elementType==="Long Text"){
            elementData={[itm.label]: <Button type='link' onClick={()=>handleDescription(itm.response)}>View details</Button>}
          }else if(itm.element_metadata.elementType==='Email'){
             elementData={[itm.label]:itm.response
            //  <a 
            //                             href={`mailto:${itm.response}`} 
            //                             target='_blank' 
            //                             rel="noreferrer"
            //                           >
                                        // {itm.response}
                                      // </a>
                          } 
          }
          else{
          elementData={[itm.label]:itm.response}
          }
        }
        return elementData
      })
      const obj=Object.assign({},...elementData2)
      const newObj={...elementData3,...obj}
      return newObj
  })  
  const filterData = (renderMetadata:any) =>(formatter:any)=> renderMetadata.map((item:any) => ({
      text:item.submitterId,
      value:item.submitterId
    }
  ))
  const filterIdData = (renderMetadata:any) =>(formatter:any)=> renderMetadata.map( (item:any) => (
    {
      text: item.id,
      value: item.id
    }
  ))
  const filterVisitorData=(renderMetadata:any)=>(formatter:any)=>renderMetadata.map((item:any)=>({
    text:item.visitorDetails,
    value:item.visitorDetails
  }))
  const filterBrowserData=(renderMetadata:any)=>(formatter:any)=>renderMetadata.map((item:any)=>({
    text:item.browser,
    value:item.browser
  }))
  const filterLocationData=(renderMetadata:any)=>(formatter:any)=>renderMetadata.map((item:any)=>({
    text:item.location,
    value:item.location
  }))
    var labelName:string
    var textLabelName:string
  formData!==undefined &&  Object.values(formData).map((itm:any)=>{
    if(itm.metadata.elementType==='Email'){
        labelName=itm.plugin_data.label
      }
    else if(itm.metadata.elementType==='Text'){
      textLabelName=itm.plugin_data.label
    }else{}
    })
  const filterEmailData=(renderMetadata:any)=>(formatter:any)=>renderMetadata.map((item:any,idx:number)=>{
     return {
      text:item[labelName],
      value:item[labelName]
    }
  })
  const filterTextData=(renderMetadata:any)=>(formatter:any)=>renderMetadata.map((item:any,idx:number)=>{
     return {
      text:item[textLabelName],
      value:item[textLabelName]
    }
  })
  
  const filterLabelData=(values:any)=>{
    values.length===0 && onClearLabelFilter()
    const getFilterData4=formSubmissionData.map((item:any)=>{
      let elementId
      item.labels.map((itm:any)=>values.map((newItm:any)=>{if(newItm===itm.form_label) elementId= item.id}))
      return elementId
    })
    const getFilterData3=formSubmissionData.filter((item:any)=>
      getFilterData4.includes(item.id)
    )
    setFormSubmisionData(getFilterData3)
  }

  const onClearLabelFilter=()=>{
     getAllFormSubmissionsByUuid(setLoading);
     setFilterField(false)
  }
  
  let elementsArray2=[{ dataIndex:'id',title:'Id',key:'id', 
                        sorter: (a:any, b:any) => a.id - b.id,
                        // width:'100px',
                        filters:filterIdData(renderMetadata)((i:any) => i.id), 
                          onFilter: (value: any, a:any) => a.id===value,
                        fixed:'left'
                      },
                      {dataIndex:'submitterId',title:'Submitter',key:'submitterId',
                        // render:(record:any)=>record?<>{record}</>:'-',
                        filters:filterData(renderMetadata)((i:any) => i.submitterId), 
                        onFilter: (value: string, renderMetadata:any) => renderMetadata.submitterId === value,
                      },
                      { dataIndex:'date',title:'Date',key:'date'},
                      { dataIndex:'viewResponse',title:'Responses',key:'viewResponse'},
                      { dataIndex:'labels',title:'Labels',key:'labels',width:80},
                      { dataIndex:'notes',title:'Notes',key:'notes'}]
    let elementsArray3=[{dataIndex:'visitorDetails',title:'Visitor Details',key:'visitorDetails',
                        filters:filterVisitorData(renderMetadata)((i:any)=>i.visitorDetails),
                        onFilter:(value:string,renderMetadata:any)=>renderMetadata.visitorDetails === value},
                        { dataIndex:'browser',title:'Browser',key:'browser',
                          filters:filterBrowserData(renderMetadata)((i:any)=>i.browser),
                          onFilter:(value:string,renderMetadata:any)=>renderMetadata.browser===value
                        },
                        { dataIndex:'location',title:'Location',key:'location',
                          filters:filterLocationData(renderMetadata)((i:any)=>i.location),
                          onFilter:(value:string,renderMetadata:any)=>renderMetadata.location === value
                        },
                        { dataIndex:'device',title:'Device',key:'device',width:80}
                      ]
    formData!==undefined && Object.values(formData).map((data:any,id:any)=>{
      let elementsArray
      if(data.metadata.elementType==='Email'){
          elementsArray={
            
            dataIndex:data.plugin_data.label,
            title:data.plugin_data.label,
            // data.plugin_data.label,
            key:data.plugin_data.label,
            filters:filterEmailData(renderMetadata)((i:any)=>i[data.plugin_data.label]), 
            onFilter:(value:string,renderMetadata:any) => renderMetadata[data.plugin_data.label].indexOf(value)===0,
         }
      }else if(data.metadata.elementType==='Text'){
        elementsArray={
          dataIndex:data.plugin_data.label,
          // title:<div style={{fontSize:'14px',fontWeight:'bolder'}}>{data.plugin_data.label}</div>,
          title:data.plugin_data.label,
          key:data.plugin_data.label,
          filters:filterTextData(renderMetadata)((i:any)=>i[data.plugin_data.label]), 
          onFilter:(value:string,renderMetadata:any) => renderMetadata[data.plugin_data.label].indexOf(value)===0,
        }
      }
      else {
          elementsArray={
          dataIndex:data.plugin_data.label,
          //  title:<div style={{fontSize:'14px',fontWeight:'bolder'}}>{data.plugin_data.label}</div>,
          title:data.plugin_data.label,
          key:data.plugin_data.label,
          width:100
        }
      }
      elementsArray2.push(elementsArray)
      return elementsArray
    })
    elementsArray3.map((item:any)=>{
      let elementsArray=item
      elementsArray2.push(elementsArray)
    })

    const mobileColumns=[{ dataIndex:'id',title:'Id',key:'id', 
                        sorter: (a:any, b:any) => a.id - b.id,
                        filters:filterIdData(renderMetadata)((i:any) => i.id), 
                          onFilter: (value: any, a:any) => a.id===value
                       },
                       {dataIndex:'submitterId',title:'Submitter',key:'submitterId',
                        filters:filterData(renderMetadata)((i:any) => i.submitterId), 
                        onFilter: (value: string, renderMetadata:any) => renderMetadata.submitterId===value,
                        render:(record:any)=>record?<>{record}</>:'-'
                      },
                        { dataIndex:'viewResponse',title:'Responses',key:'viewResponse'},
                      ]
  return (
    <div>
      <Row>          
        {devices===false  ? <Tooltip title= "Mark as Read">
          <Button 
            // type="primary"
            style={{marginLeft:'10px',marginTop:window.innerWidth<769? '10px':''}}
            onClick={()=>getMarkasRead(true)}>
             <MailOutlined /> Mark as Read
          </Button>
        </Tooltip> :
        <Tooltip title= "Mark as Unread">
          <Button 
            // type="primary"
            style={{marginLeft:'10px',marginTop:window.innerWidth<769? '10px':''}}
            onClick={()=>getMarkasRead(false)}>
             <InboxOutlined /> Mark as Unread
          </Button>
        </Tooltip>
        } 
        {/* <Form> */}
          <Form.Item 
            label={'Filter by Labels'}
            style={{marginLeft:'20px'}}>
         {labelsList !== undefined &&
         
          <Select
            mode="multiple"
            allowClear
            autoClearSearchValue
            style={{ width: '250px'}}
            placeholder="Labels"
            // disabled={selectedRowKeys===undefined}
            // defaultValue={selectedRowKeys && tagElement}
            // value={selectedValue}
            onClick={()=>setFilterField(true)}
            onClear={onClearLabelFilter}
            onChange={(values)=>filterLabelData(values)}
            onMouseLeave={()=> setFilterField(false)}
            // optionLabelProp="label"
          > {Object.values(labelsList).map((item:any,idx:number)=>{
              return  <Option value={item.id} label={item.name}>
                <Tag color={item.color_hex}>{item.name}</Tag>
                  </Option>
              })}
          </Select>}
          </Form.Item>
        {/* </Form> */}
        <Tooltip title="Download CSV">
                    <Button
                      type="primary"
                      onClick={() => requestDownloadCsv(setLoading)}
                       style={{ marginLeft: "15px" }}
                    >
                      <DownloadOutlined />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Refresh Responses">
                    <Button
                      type="primary"
                      onClick={() => {
                        getAllFormSubmissionsByUuid(setLoading);
                      }}
                      style={{ marginLeft: "5px" }}
                    >
                      <SyncOutlined />
                    </Button>
                  </Tooltip>
        
      </Row>
      <Row>
        <Col
          span={24}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {window.innerWidth < 769 ?
           formSubmissionData && formSubmissionData.length !== 0 && (
            <TableForMobile
             rowClassName={(record:any, index:number) => record.read === false ? 'table-row-light' :  'table-row-dark'}
             
              columns={mobileColumns}
              rowSelectionTable={rowSelection}
              dataSource={renderMetadata}
              currentPageNo={currentPageNo}
              setCurrentPageNo={setCurrentPageNo}
            />
          ):
           formSubmissionData && formSubmissionData.length !== 0 && (
            <TableForDesktop
             rowClassName={(record:any,index:number)=>record.read===false ?  'table-row-light' :  'table-row-dark'}
              columns={elementsArray2}
              rowSelectionTable={rowSelection}
              dataSource={renderMetadata}
              currentPageNo={currentPageNo}
              setCurrentPageNo={setCurrentPageNo}
            />
          )
        }
        </Col>
      </Row>
      {isShowModal && <ViewResponseModal 
                          isShowModal={isShowModal} 
                          handleClose={()=>setShowModal(false)} 
                          record={viewRecord} 
                          labelsList={labelsList}
                          handleLabelSelect={handleLabelSelect}
                          assignLabel={assignLabel}
                          handleNotes2={handleNotes2}
                          currentIndex={currentIndex} 
                          submissionsCount={submissionsCount} 
                          changeCurrentIndex={changeCurrentIndex}
                        />}
        {ellipsis &&
        <Modal
          visible={ellipsis}
          onCancel={() => setEllipsis(!ellipsis)}
          width="80vw"
          footer={null}
          bodyStyle={{
            paddingTop: 50,
          }}
        > 
      {/* <LongTextMarkdownEditor placeholder={textData}/> */}
       <Input.TextArea style={{height:'200px'}}
          className="form_description_markdown_editor"
          value={textData}
        />
      </Modal>}
      {isViewNote && 
      <Modal
        visible={isViewNote}
        onCancel={() => setViewNote(!isViewNote)}
        width="80vw"
        footer={null}
        bodyStyle={{
          paddingTop: 50,
        }}>
          <Input.TextArea
            className="form_description_markdown_editor"
            value={noteData}
          />
      </Modal>}
      {isEditNote && 
        <Modal 
          visible={isEditNote}
          onCancel={() => setEditNote(!isEditNote)}
          width="80vw"
          footer={null}
          bodyStyle={{
            paddingTop: 50,
          }}>
            <Form onFinish={onEditNoteDetail}>
            <MDEditor
              className="form_description_markdown_editor"
              placeholder="Edit Note"
              textareaProps={{
                placeholder: "Edit Note",
              }}
              value={noteData}
              onChange={(value) => {
                onFormDetailsUpdate(value);
              }}
            />
             <Button 
                type='primary' 
                htmlType='submit' 
                style={{marginLeft:'12px',marginTop:'10px'}}
              >
                Save
              </Button>
            </Form>
        </Modal>}
     </div>
  );
};

export default ResponsesList;
