import React from "react";

import { Input, message } from "antd";
import { connect, ConnectedProps } from "react-redux";
import { IElement } from "../utils/interfaces";

interface IProps extends PropsFromRedux {
  element: IElement;
  setIsModalOpen: (open: boolean) => void;
  choiceIndex?: number;
  handleImageUpload: (element: IElement) => IElement;
  updateElement: (element: IElement) => void;
}

const UploadLogo = (props: IProps) => {
  const { element, setIsModalOpen, dispatch, choiceIndex } = props;

  const getPayload = (imageData: object, imageUrl: string): any => {
    if (choiceIndex !== undefined) {
      const _choices = element.plugin_data?.choices!;
      _choices[choiceIndex].imageUrl = imageUrl;
      const choicePayload = {
        ...element,
        plugin_data: { ...element.plugin_data, choices: _choices },
        choiceImageData: imageData,
        choiceIndex: choiceIndex,
      };

      return choicePayload;
    } else {
      const questionPayload = {
        ...element,
        questionImageData: imageData,
        questionImageUrl: imageUrl,
        questionImageSize: "",
      };

      return questionPayload;
    }
  };

  const onFileChange = async (e: any) => {
    if (e.target.files.length > 0) {
      if (e.target.files[0].type.match("image")) {
        const fileSize = e.target.files[0].size;

        if (fileSize > 5000000) {
          message.error("Max Image size 5MB allowed.");
        } else {
          const imageUrl = URL.createObjectURL(e.target.files[0]);
          const _element = getPayload(e.target.files[0], imageUrl);
          const updatedElement = await props.handleImageUpload(_element);
          dispatch({
            type: choiceIndex
              ? "SET_CHOICE_IMAGE_FILE"
              : "SET_QUESTION_IMAGE_FILE",
            payload: { [updatedElement.uuid as string]: updatedElement },
          });
          props.updateElement(_element);
          setIsModalOpen(false);
        }
      } else {
        message.error("This file type is not supported.");
      }
    } else {
      message.error("Please select a file to upload");
    }
  };

  return (
    <Input
      type="file"
      onChange={(e) => onFileChange(e)}
      style={{ width: "220px" }}
    ></Input>
  );
};

interface IAction {
  type: string;
  payload: any;
}

const mapDispatch = {
  dispatch: (action: IAction) => action,
};

export const connector = connect(null, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UploadLogo);
