import React from "react";
import axios from "axios";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";

function BreadcrumbNavigator() {
   const params = useParams<any>();
  const [formName, setFormName] = React.useState<string>("");
  const { uuid } = params;
  const updateFormData =  () => {
    const url =  `${process.env.REACT_APP_SERVER_URL}/api/custom_forms/${uuid}/`;
     axios
      .get(url)
      .then((res) => {
        setFormName(res.data.title);
        console.log(res);
      })
      .catch((err: any) => { 
      });
  };
  React.useEffect(() => {
    updateFormData();
  }, []);
  return (
    <Breadcrumb className="ct_forms_breadcrumb">
      <Breadcrumb.Item>
        <Link to={`/forms/all`}>Forms / </Link>
        <Link to={`/forms/${uuid}/builder`}>{formName}</Link>
      </Breadcrumb.Item>
    </Breadcrumb>
  );
}

export default BreadcrumbNavigator;
