import React from "react";
import { Form, Select } from "antd";
import { IElement } from "../../utils/interfaces";
import AdvanceOptionsDrawer from "./ElementPreview/AdvanceOptionsDrawer";
import FormItem from "./ElementPreview/FormItem";

interface IProps {
  componentDisabled?: boolean;
  updateFieldValue: (data: { input_formats: string }) => void;
  state: IElement;
  deleteButton: React.ReactNode;
  extraProps: React.ReactNode;
}

const dateFormats = ["DD-MM-YYYY", "MM-DD-YYYY"];

export default function CTDatePicker(props: IProps) {
  if (!props.state.plugin_data) return null;
  const selectedFormat = props.state.plugin_data.input_formats;
  const advancedOptions = (
    <>
      <FormItem
        label="Date Format"
        elementStyle={{
          color: "#969DB1",
        }}
      >
        <Select
          defaultValue={selectedFormat}
          onChange={(value) => props.updateFieldValue({ input_formats: value })}
          disabled={props.componentDisabled}
        >
          {dateFormats.map((format) => (
            <Select.Option
              key={format}
              value={format}
              disabled={props.componentDisabled}
              style={{
                wordBreak: "break-word",
                background: "#969DB1",
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "24px",
                lineHeight: "32px",
                color: "#FFFFFF",
              }}
            >
              {format}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
    </>
  );

  return (
    <AdvanceOptionsDrawer
      componentDisabled={props.componentDisabled}
      deleteButton={props.deleteButton}
      advancedOptions={advancedOptions}
      state={props.state}
      extraProps={props.extraProps}
    />
  );
}
