import React, { useEffect, useState } from "react";

import { Card, message } from "antd";
import axios from "axios";

import TagsToTemplate from "./TagsToTemplate";
import TagsCRUD from "./TagsCRUD";

const TemplateTags = (props: any) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [tags, setTags] = useState<any>();

  const { formData } = props;

  const [initialTags, setInitialTags] = useState<any>();

  const [shouldReload, setShouldReload] = useState<boolean>(false);

  const getAllTags = async () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/base/tags/`;

    await axios
      .get(url)
      .then((result) => {
        setTags(result.data.data);
        return result;
      })
      .then((result: any) => {
        updateInitialTags(result.data.data);
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Could not get form data.");
        setLoading(false);
      });
  };

  const updateInitialTags = async (tags: any) => {
    const localData: any = [];

    if (formData && formData.tags.length > 0 && tags && tags.length > 0) {
      for (let i = 0; i < formData.tags.length; i++) {
        for (let j = 0; j < tags.length; j++) {
          if (formData.tags[i].tag === tags[j].id) {
            const dataToPush: any = {};
            dataToPush.label = tags[j].name;
            dataToPush.name = tags[j].name;
            dataToPush.key = parseInt(tags[j].id);
            dataToPush.value = parseInt(tags[j].id);

            localData.push(dataToPush);
          }
        }
      }
      setInitialTags(await localData);
    } else {
      setInitialTags([]);
    }
  };

  useEffect(() => {
    getAllTags();
  }, [formData]);

  useEffect(() => {
    getAllTags();
    setShouldReload(false);
  }, [shouldReload]);

  return (
    <>
      <Card title={<span>Tags </span>} style={{ margin: "10px" }}>
        <TagsToTemplate tags={tags} initialTags={initialTags} />
      </Card>

      <Card title={<span>Tags CRUD </span>} style={{ margin: "10px" }}>
        <TagsCRUD
          tags={tags}
          shouldReload={shouldReload}
          setShouldReload={setShouldReload}
        />{" "}
      </Card>
    </>
  );
};

export default TemplateTags;
