import React, { useState } from "react";
import {
  Button,
  Form,
  Input,
  Divider,
  Card,
  Slider,
  Select,
  Radio,
  Checkbox,
  DatePicker,
  TimePicker,
  Alert,
  InputNumber,
  Rate,
  Spin,
  Collapse,
  Image,
  Space,
  Row,
  Col,
} from "antd";

import { useLocation } from "react-router-dom";

import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";

import { IFormData, IElement, IChoice } from "../../core/interfaces/interfaces";

import FormElement from "./FormElement";

import FileUpload from "./FileUpload";
import LongTextMarkdownEditor from "./LongTextMarkdownEditor";
import CustomPhoneInput from "./CustomPhoneInput";
import { FALLBACK_IMAGE } from "../../../shared/constants/constants";

import "./FormSubmitFields.css";
import axios from "axios";
import VideoUpload from "./VideoUpload";
import FileUploadForVideo from "./FileUploadForVideo";
import FormPrivacyPolicy from "./FormPrivacyPolicy";
import QuestionStep from "./QuestionStep";
import ElementCard from "./ElementCard";
import CustomSwitch from "./CustomSwitch";

const qs = require("query-string");

const { Panel } = Collapse;

interface IProps {
  formData: IFormData;
  handleFormSubmit: (values: object) => void;
  handleFinishFailed: (errorInfo: object) => void;
  oriProofs: any;
  addProofs?: boolean;
  userData: any;
  setFormSubmitSuccess: any;
  handleSentConsent: any;
}

const FormSubmitFields = (props: IProps) => {
  const {
    formData,
    handleFormSubmit,
    handleFinishFailed,
    addProofs,
    oriProofs,
    setFormSubmitSuccess,
    handleSentConsent,
  } = props;

  const pathname = useLocation();
  const [switchValue, setSwitchValue] = React.useState(false);
  const [uploadedFileKey, setUploadedFileKey] = useState<any>(null);
  const [fileName, setFileName] = useState<null | string>(null);
  const [uploadedFileKeyVideo, setUploadedFileKeyVideo] = useState<any>(null);
  const [fileNameVideo, setFileNameVideo] = useState<null | string>(null);

  const data = qs.parse(pathname.search);

  const { mode } = data;

  const [currentIndex, setCurrentIndex] = useState(0);

  const elementToReturn = (element: IElement) => {
    const {
      metadata: { elementType: label, questionImageSize },
      questionImageUrl,
      plugin_data,
    } = element;
    let hasImages: any;

    switch (label) {
      case "Text":
        return (
          <FormElement
            questionImageSize={questionImageSize}
            questionImageUrl={questionImageUrl}
            plugin_data={plugin_data}
            rules={[
              {
                required: plugin_data.required,
                message: "This field is required",
              },
              {
                max: parseInt(String(plugin_data.max_length)),
                message: `Cannot be longer than ${plugin_data.max_length} characters`,
              },
            ]}
            extra={plugin_data.help_text}
          >
            <Input
              {...plugin_data}
              className="ct_forms_input_field"
              style={{
                borderBottom: "0.5px solid #272E35",
              }}
              bordered={false}
            />
          </FormElement>
        );
      case "Slider":
        return (
          <FormElement
            questionImageSize={questionImageSize}
            questionImageUrl={questionImageUrl}
            plugin_data={{ ...plugin_data, initial: plugin_data.min_value }}
            rules={[
              {
                required: plugin_data.required,
                message: "This field is required",
              },
            ]}
            extra={plugin_data.help_text}
          >
            <Slider
              marks={{
                [plugin_data.min_value]: {
                  label: plugin_data.label_start || plugin_data.min_value,
                  style: {
                    transform: "translateX(-4%)",
                    color: "black",
                  },
                },
                [plugin_data.max_value]: {
                  label: plugin_data.label_end || plugin_data.max_value,
                  style: {
                    transform: "translateX(-96%)",
                    color: "black",
                  },
                },
              }}
              step={plugin_data.step}
              max={plugin_data.max_value}
              min={plugin_data.min_value}
              tooltipVisible
            />
          </FormElement>
        );
      case "Dropdown":
        return (
          <FormElement
            questionImageSize={questionImageSize}
            questionImageUrl={questionImageUrl}
            plugin_data={plugin_data}
            rules={[
              {
                required: plugin_data.required,
                message: "This field is required",
              },
            ]}
            extra={plugin_data.help_text}
          >
            <Select style={{ fontSize: "24px" }}>
              <Select.Option style={{ fontSize: "24px" }} value="">
                Select an option
              </Select.Option>
              {element.plugin_data.choices?.map((choice: IChoice) => (
                <Select.Option
                  style={{ fontSize: "24px" }}
                  key={choice.choice_text}
                  value={choice.choice_text}
                >
                  {choice.choice_text}
                </Select.Option>
              ))}
            </Select>
          </FormElement>
        );
      case "Checkbox":
        hasImages = element.plugin_data.choices?.some(
          (choice: IChoice) => choice.imageUrl
        );
        return (
          <FormElement
            questionImageSize={questionImageSize}
            questionImageUrl={questionImageUrl}
            plugin_data={plugin_data}
            rules={[
              {
                required: plugin_data.required,
                message: "This field is required",
              },
            ]}
            extra={plugin_data.help_text}
          >
            <Checkbox.Group
              style={{
                display: "flex",
                justifyContent: "flex-start",
                flexFlow: "row wrap",
              }}
            >
              <Row
                gutter={24}
                style={{
                  width: "100%",
                }}
              >
                {plugin_data.choices?.map((choice: IChoice) => (
                  <Col
                    span={window.innerWidth <= 769 ? 24 : 12}
                    style={{ width: "100%" }}
                  >
                    <Card
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                        marginLeft: 12,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        minWidth: "100%",
                        border: "1px solid #000000",
                      }}
                    >
                      <Space direction="vertical">
                        {hasImages && (
                          <Image
                            src={choice.imageUrl || FALLBACK_IMAGE}
                            style={{
                              maxHeight: 100,
                              maxWidth: 240,
                            }}
                            alt="Choice Image"
                            fallback={FALLBACK_IMAGE}
                          />
                        )}
                        <Checkbox
                          key={choice.choice_text}
                          value={choice.choice_text}
                          style={{
                            wordBreak: "break-word",
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            fontWeight: "bold",
                            fontSize: "32px",
                            color: "#272E35",
                          }}
                        >
                          {choice.choice_text}
                        </Checkbox>
                      </Space>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </FormElement>
        );
      case "Radio":
        hasImages = element.plugin_data.choices?.some(
          (choice: IChoice) => choice.imageUrl
        );
        return (
          <FormElement
            questionImageSize={questionImageSize}
            questionImageUrl={questionImageUrl}
            plugin_data={plugin_data}
            rules={[
              {
                required: plugin_data.required,
                message: "This field is required",
              },
            ]}
            extra={plugin_data.help_text}
          >
            <Radio.Group
              style={{
                display: "flex",
                justifyContent: "flex-start",
                flexFlow: "row wrap",
              }}
            >
              <Row
                gutter={24}
                style={{
                  width: "100%",
                }}
              >
                {plugin_data.choices?.map((choice: IChoice) => (
                  <Col
                    span={window.innerWidth <= 769 ? 24 : 12}
                    style={{ width: "100%" }}
                  >
                    <Card
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                        marginLeft: 12,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        minWidth: "100%",
                        border: "1px solid #000000",
                      }}
                    >
                      <Space direction="vertical">
                        {hasImages && (
                          <Image
                            src={choice.imageUrl || FALLBACK_IMAGE}
                            style={{
                              maxHeight: 100,
                              maxWidth: 240,
                            }}
                            alt="Choice Image"
                            fallback={FALLBACK_IMAGE}
                          />
                        )}
                        <Radio
                          key={choice.choice_text}
                          value={choice.choice_text}
                          style={{
                            wordBreak: "break-word",
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            fontWeight: "bold",
                            fontSize: "32px",
                            color: "#272E35",
                          }}
                        >
                          {choice.choice_text}
                        </Radio>
                      </Space>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Radio.Group>
          </FormElement>
        );

      case "Long Text":
        return (
          <FormElement
            questionImageSize={questionImageSize}
            questionImageUrl={questionImageUrl}
            plugin_data={plugin_data}
            rules={[
              {
                required: plugin_data.required,
                message: "This field is required",
              },
              {
                max: parseInt(String(plugin_data.max_length)),
                message: `Cannot be longer than ${plugin_data.max_length} characters`,
              },
            ]}
            extra={plugin_data.help_text}
          >
            <LongTextMarkdownEditor />
          </FormElement>
        );
      case "Switch":
        return (
          <FormElement
            questionImageSize={questionImageSize}
            questionImageUrl={questionImageUrl}
            plugin_data={{ ...plugin_data, initial: false }}
            rules={[
              {
                required: plugin_data.required,
                message: "This field is required",
              },
            ]}
            extra={plugin_data.help_text}
          >
            <CustomSwitch
              switchValue={switchValue}
              setSwitchValue={setSwitchValue}
            />
          </FormElement>
        );
      case "Email":
        return (
          <FormElement
            questionImageSize={questionImageSize}
            questionImageUrl={questionImageUrl}
            plugin_data={plugin_data}
            rules={[
              {
                required: plugin_data.required,
                message: "This field is required",
              },
              {
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Invalid email",
              },
              {
                max: parseInt(String(plugin_data.max_length)),
                message: `Cannot be longer than ${plugin_data.max_length} characters`,
              },
            ]}
            extra={plugin_data.help_text}
          >
            <Input
              className="ct_forms_input_field"
              style={{
                borderBottom: "0.5px solid #272E35",
              }}
              bordered={false}
              {...plugin_data}
            />
          </FormElement>
        );
      case "Link":
        return (
          <FormElement
            questionImageSize={questionImageSize}
            questionImageUrl={questionImageUrl}
            plugin_data={plugin_data}
            rules={[
              {
                required: plugin_data.required,
                message: "This field is required",
              },
              {
                pattern:
                  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
                message: "Enter url in http://www.url.com format",
              },
              {
                max: parseInt(String(plugin_data.max_length)),
                message: `Cannot be longer than ${plugin_data.max_length} characters`,
              },
            ]}
            extra={plugin_data.help_text}
          >
            <Input
              className="ct_forms_input_field"
              style={{
                borderBottom: "0.5px solid #272E35",
              }}
              bordered={false}
              {...plugin_data}
              placeholder={plugin_data.placeholder || "http://www.url.com"}
            />
          </FormElement>
        );
      case "Password":
        return (
          <FormElement
            questionImageSize={questionImageSize}
            questionImageUrl={questionImageUrl}
            plugin_data={plugin_data}
            rules={[
              {
                required: plugin_data.required,
                message: "This field is required",
              },
              {
                max: parseInt(String(plugin_data.max_length)),
                message: `Cannot be longer than ${plugin_data.max_length} characters`,
              },
            ]}
            extra={plugin_data.help_text}
          >
            <Input.Password
              className="ct_forms_input_field"
              style={{
                borderBottom: "0.5px solid #272E35",
              }}
              bordered={false}
              autoComplete="off"
              {...plugin_data}
            />
          </FormElement>
        );

      case "DatePicker":
        return (
          <FormElement
            questionImageSize={questionImageSize}
            questionImageUrl={questionImageUrl}
            plugin_data={{
              ...plugin_data,
              initial: null,
            }}
            rules={[
              {
                required: plugin_data.required,
                message: "This field is required",
              },
            ]}
            plugin_uid={element.plugin_uid}
            format={plugin_data.input_formats}
            extra={plugin_data.help_text}
          >
            <DatePicker format={plugin_data.input_formats} {...plugin_data} />
          </FormElement>
        );

      case "TimePicker":
        return (
          <FormElement
            questionImageSize={questionImageSize}
            questionImageUrl={questionImageUrl}
            plugin_data={{
              ...plugin_data,
              initial: null,
            }}
            rules={[
              {
                required: plugin_data.required,
                message: "This field is required",
              },
            ]}
            plugin_uid={element.plugin_uid}
            extra={plugin_data.help_text}
          >
            <TimePicker format={"HH:mm"} />
          </FormElement>
        );

      case "Number":
        return (
          <FormElement
            questionImageSize={questionImageSize}
            questionImageUrl={questionImageUrl}
            plugin_data={plugin_data}
            rules={[
              {
                required: plugin_data.required,
                message: "This field is required",
              },
            ]}
            extra={plugin_data.help_text}
          >
            <InputNumber
              className="ct_forms_input_field"
              style={{
                borderBottom: "0.5px solid #272E35",
                width: "100%",
              }}
              bordered={false}
              max={plugin_data.max_value}
              min={plugin_data.min_value}
              precision={plugin_data.decimal_places}
              {...plugin_data}
            />
          </FormElement>
        );
      case "Ratings":
        return (
          <FormElement
            questionImageSize={questionImageSize}
            questionImageUrl={questionImageUrl}
            plugin_data={plugin_data}
            rules={[
              {
                required: plugin_data.required,
                message: "This field is required",
              },
            ]}
            extra={plugin_data.help_text}
          >
            <Rate
              allowHalf={plugin_data.halfAllowed}
              count={plugin_data.max_value}
            />
          </FormElement>
        );
      case "Upload":
        return (
          <>
            <FileUpload
              questionImageUrl={questionImageUrl}
              questionImageSize={questionImageSize}
              plugin_data={plugin_data}
              rules={[
                {
                  required: plugin_data.required,
                  message: "This field is required",
                },
              ]}
              extra={plugin_data.help_text}
              setIsFileUploading={setIsFileUploading}
              mode={mode}
              uploadedFileKey={uploadedFileKey}
              setUploadedFileKey={setUploadedFileKey}
              fileName={fileName}
              setFileName={setFileName}
            />
          </>
        );
      case "Phone":
        return (
          <FormElement
            questionImageSize={questionImageSize}
            questionImageUrl={questionImageUrl}
            plugin_data={plugin_data}
            rules={[
              {
                required: plugin_data.required,
                message: "This field is required",
              },
            ]}
            extra={plugin_data.help_text}
          >
            <CustomPhoneInput />
          </FormElement>
        );

      case "Video":
        return (
          <>
            <Collapse accordion>
              <Panel header="Record and Upload Video" key="1">
                <VideoUpload
                  questionImageUrl={questionImageUrl}
                  questionImageSize={questionImageSize}
                  plugin_data={plugin_data}
                  rules={[
                    {
                      required: plugin_data.required,
                      message: "This field is required",
                    },
                  ]}
                  extra={plugin_data.help_text}
                  setIsFileUploading={setIsFileUploading}
                  mode={mode}
                  uploadedFileKey={uploadedFileKeyVideo}
                  setUploadedFileKey={setUploadedFileKeyVideo}
                  fileName={fileNameVideo}
                  setFileName={setFileNameVideo}
                />
              </Panel>
              <Panel header="Upload Video" key="2">
                <FileUploadForVideo
                  questionImageUrl={questionImageUrl}
                  questionImageSize={questionImageSize}
                  plugin_data={plugin_data}
                  rules={[
                    {
                      required: plugin_data.required,
                      message: "This field is required",
                    },
                  ]}
                  extra={plugin_data.help_text}
                  setIsFileUploading={setIsFileUploading}
                  mode={mode}
                  uploadedFileKey={uploadedFileKeyVideo}
                  setUploadedFileKey={setUploadedFileKeyVideo}
                  fileName={fileNameVideo}
                  setFileName={setFileNameVideo}
                />
              </Panel>
            </Collapse>
          </>
        );

      default:
        return <p>Element not supported yet.</p>;
    }
  };

  const [isFileUploading, setIsFileUploading] = useState(false);
  const [hasReadPolicy, setHasReadPolicy] = useState(false);

  const disableSubmitButton = () => {
    if (!Object.keys(formData.elements as object).length) {
      return true;
    }
    if (mode === "preview") {
      return true;
    }
    if (isFileUploading) {
      return true;
    }
    if (isUserABot) {
      return true;
    }
    if (!hasReadPolicy) {
      return true;
    }
  };

  const onAlertClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    console.log(e, "I was closed.");
  };

  const [isUserABot, setIsUserABot] = useState<null | boolean>(null);

  const verifyCallback = async (recaptchaToken: any) => {
    await axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/base/recaptcha/`, {
        response: recaptchaToken,
      })
      .then((res) => {
        console.log("response from backend recaptcha API", res.data);
        if (res.data.score > 0.6) {
          setIsUserABot(false);
        }
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
      });
  };

  const _elements = Object.values(formData.elements as object);
  const totalElements = _elements.length;
  const currentElement = _elements[currentIndex];

  if (formData.metadata?.layout === "card") {
    return (
      <div>
        {mode === "preview" && (
          <Alert
            message="You are viewing this form in preview mode."
            type="warning"
            closable
            onClose={onAlertClose}
            style={{ marginBottom: "20px" }}
          />
        )}
        <GoogleReCaptchaProvider reCaptchaKey="6LefEmMbAAAAAJJT0n-ctfheLowDhSpdLrjKxqDF">
          <GoogleReCaptcha
            onVerify={(e) => {
              console.log("e in recaptcha", e);
              verifyCallback(e);
            }}
          />
        </GoogleReCaptchaProvider>

        {isUserABot === null && (
          <p style={{ padding: "2px", color: "red" }}>
            <Spin style={{ paddingRight: "2px" }} /> Validating Recaptcha
          </p>
        )}
        {hasReadPolicy ? (
          <div style={{}}>
            {currentElement ? (
              <QuestionStep
                currentElement={elementToReturn(currentElement)}
                element={currentElement}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                totalElements={totalElements}
                elements={_elements}
                disableSubmitButton={disableSubmitButton()}
                formUuid={formData.uuid}
                userData={props.userData}
                setFormSubmitSuccess={setFormSubmitSuccess}
                handleSentConsent={handleSentConsent}
              />
            ) : (
              <p>No data to show</p>
            )}
          </div>
        ) : (
          <Card
            size="small"
            style={{
              backgroundColor: "#f7f7f8",
              borderRadius: "10px",
              marginBottom: "10px",
            }}
          >
            <FormPrivacyPolicy
              addProofs={addProofs}
              oriProofs={oriProofs}
              hasReadPolicy={hasReadPolicy}
              setHasReadPolicy={setHasReadPolicy}
            />
          </Card>
        )}
        {isUserABot && (
          <p style={{ padding: "2px", color: "red" }}>You seem to be a bot</p>
        )}
      </div>
    );
  }

  return (
    <Form
      layout="vertical"
      onFinish={handleFormSubmit}
      onFinishFailed={handleFinishFailed}
    >
      {mode === "preview" && (
        <Alert
          message="You are viewing this form in preview mode."
          type="warning"
          closable
          onClose={onAlertClose}
          style={{ marginBottom: "20px" }}
        />
      )}
      {formData &&
        Object.values(formData.elements as object).map((element: IElement) => (
          <ElementCard
            currentElement={elementToReturn(element)}
            element={element}
          />
        ))}
      <Divider />
      <GoogleReCaptchaProvider reCaptchaKey="6LefEmMbAAAAAJJT0n-ctfheLowDhSpdLrjKxqDF">
        <GoogleReCaptcha
          onVerify={(e) => {
            console.log("e in recaptcha", e);
            verifyCallback(e);
          }}
        />
      </GoogleReCaptchaProvider>

      {isUserABot === null && (
        <p style={{ padding: "2px", color: "red" }}>
          <Spin style={{ paddingRight: "2px" }} /> Validating Recaptcha
        </p>
      )}

      <FormPrivacyPolicy
        addProofs={addProofs}
        oriProofs={oriProofs}
        hasReadPolicy={hasReadPolicy}
        setHasReadPolicy={setHasReadPolicy}
      />

      <Divider />
      <Button
        className="ct_forms_primary_button"
        style={{
          margin: "auto",
        }}
        htmlType="submit"
        type="primary"
        disabled={disableSubmitButton()}
      >
        Submit
      </Button>
      {isUserABot && (
        <p style={{ padding: "2px", color: "red" }}>You seem to be a bot</p>
      )}
    </Form>
  );
};

export default FormSubmitFields;
