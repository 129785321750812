import React, { useEffect, useState } from "react";

import { Layout, message } from "antd";

import axios from "axios";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

import AntSpinner from "../../../shared/customComponents/spinner/AntSpinner";

import { StateType } from "../../core/redux/types";

import TemplateVisibility from "../adminSettings/TemplateVisibility";
import TemplateDomains from "./TemplateDomains";
import TemplateCategories from "./TemplateCategories";
import TemplateTags from "./TemplateTags";
import FeaturedImage from "./FeaturedImage";

const FormSettings = (props: any) => {
  const [loading, setLoading] = useState(true);

  const [formData, setFormData] = useState<any>(null);

  const params = useParams<any>();

  const { uuid } = params;

  const getFormByUuid = async () => {
    setLoading(true);

    const url = `${process.env.REACT_APP_SERVER_URL}/api/templates/${uuid}/`;

    await axios
      .get(url)
      .then(async (res) => {
        console.log("---- res get form by uuid ---- ", res);
        setFormData(res.data);
        setLoading(false);
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Could not get Form data.");
        setLoading(false);
      });
  };

  useEffect(() => {
    getFormByUuid();
  }, []);

  if (loading) {
    return <AntSpinner />;
  }
  return (
    <Layout>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div style={{ width: "700px" }}>
          {props.state.auth.userData.is_staff && formData && (
            <>
              <TemplateVisibility />
              <TemplateDomains formData={formData} />
              <TemplateCategories formData={formData} />
              <TemplateTags formData={formData} getFormByUuid={getFormByUuid} />
              <FeaturedImage
                formData={formData}
                getFormByUuid={getFormByUuid}
              />
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(FormSettings);
