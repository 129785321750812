import React from "react";

import { Card, Typography, Space, Image } from "antd";

import THEMECOLORS from "../../../shared/theme/themeColors";
import { FALLBACK_IMAGE } from "../../../shared/constants/constants";

const { Title } = Typography;

const CompanyHeader = (props: any) => {
  const { userData, isLogoPresent, isCompanyNamePresent } = props;
  return (
    <Card
      style={{
        marginBottom: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: window.innerWidth > 600 ? "row" : "column",
          width: "100%",
          justifyContent:
            isLogoPresent && isCompanyNamePresent ? "left" : "center",
        }}
      >
        {isLogoPresent && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Space>
              <Image
                src={
                  userData &&
                  userData &&
                  userData.profile_picture &&
                  userData.profile_picture.thumbnail_large
                }
                alt="company logo"
                style={{ maxHeight: 100, maxWidth: 100 }}
                preview={false}
                fallback={FALLBACK_IMAGE}
              />
            </Space>
          </div>
        )}
        {isCompanyNamePresent && (
          <div
            style={{
              marginTop: window.innerWidth > 600 ? "0px" : "20px",
              wordBreak: "break-word",
              display: "flex",
              flexDirection: "row",
              justifyContent: isLogoPresent ? "left" : "center",
              marginLeft: isLogoPresent ? "20px" : "0px",
              alignItems: "center",
            }}
          >
            <Title
              level={3}
              style={{
                color: THEMECOLORS.fontColorPrimary,
                fontWeight: "bold",
                padding: "0px",
                margin: "0px",
              }}
            >
              {userData && userData && userData.company_name}
            </Title>
          </div>
        )}
      </div>
    </Card>
  );
};

export default CompanyHeader;
