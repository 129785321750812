import React from "react";

import { Card, Col, Form, Layout, Row } from "antd";
import Title from "antd/lib/typography/Title";

import UploadLogo from "../../components/profile/UploadLogo";

const ProfileLogo = () => {
  return (
    <Layout className="profile-layout">
      <div>
        <br />
        <Row
          style={{
            width: "100%",
            backgroundColor: "yellow",
          }}
        >
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <div
              style={{
                width: "auto",
              }}
            >
              <Card
                title={<Title level={4}>Company Logo</Title>}
                style={{
                  paddingBottom: "0px",
                  width: "100%",
                }}
              >
                <Form.Item>
                  <UploadLogo />
                </Form.Item>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default ProfileLogo;
