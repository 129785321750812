import React, { useEffect, useState } from "react";
import ViewResponseModal from "../../containers/formResponses/ViewResponseModal";
import { Table,Button } from "antd";
import { Link } from "react-router-dom";

const FormIndividualStats = (props: any) => {
  const [dataSource, setDataSource] = useState();
  const [viewRecord, setViewRecord] = React.useState<any>()
  const [isShowModal, setShowModal] = React.useState(false)
  
  const formUuid = props.formUuid;
  const callNewAction=(record:any,newIndex:number)=>{
    console.log('record',record,dataSource,record.id);
    setViewRecord(record);
    setShowModal(true)
    // window.history.pushState('','',`/forms/${formUuid}/responses/${record.id}`)
    window.location.pathname=`/forms/${formUuid}/responses/${record.id}`
    // window.location.replace(`/forms/${formUuid}/responses/${record.id}`)
  }
 
  useEffect(() => {
    if (props.metadata) {
      const dataSourceToSet = props.metadata;
      setDataSource(dataSourceToSet);
    }
  }, [props]);

  const columns = [
    {
      title: "Recent Visitors",
      // dataIndex: "ip",
      // key: "ip",
      render:(record:any)=>(<>{record.metadata.ip}</>)
    },
    {
      title: "Date",
      dataIndex: "created",
      key: "created",
      render: (created: any) => (
        <>
          {" "}
          {new Date(created).toLocaleDateString("en-gb", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
          {", "}
          {
            Intl.DateTimeFormat("en", {
              hour: "numeric",
              minute: "numeric",
            }).format(new Date(created)) // 2 PM
          }
        </>
      ),
    },
    {
      title: "Response",
      dataIndex: "id",
      key: "id",
      render: (id: any, record: any) => (
        // <Button type="link" 
        //     style={{
        //             textOverflow: "ellipsis",
        //             wordWrap: "normal",
        //             width: "100px",
        //             overflow: "none",
        //             textAlign: "left",
        //             alignContent: "left",
        //             left: "0px",
        //             margin: "0px",
        //             padding: "0px",
        //           }}
        //     onClick={()=>{callNewAction(record,record.id);}} >
        //       <p style={{fontSize:"15px",fontWeight:"bold"}}>
        //         <Link to={`/forms/${formUuid}/responses/${record.id}`} >
        //           View Response
        //         </Link>
        //       </p>
        //   </Button>
        
        <Link
          to={`/forms/${formUuid}/responses/${record.id}`}
          style={{ padding: "0px", margin: "0px" }}
        >
          View Responses
        </Link>
      ),
    },
    {
      title: "Location",
      dataIndex: "city",
      key: "city",
      render: (city: any, record: any) => (
        <>
          {record.metadata.city}
          {record.metadata.country && record.metadata.country !== "-" && `, ${record.metadata.country}`}
        </>
      ),
    },
    {
      title: "Browser",
      // dataIndex: "browser_info",
      // key: "browser_info",
      render:(record:any)=>(<>{record.metadata.browser_info}</>)
   
    },
  ];

  const columnsForMobile = [
    {
      title: "Recent Visitors",
      dataIndex: "ip",
      key: "ip",
    },
    {
      title: "Response",
      dataIndex: "id",
      key: "id",
      render: (id: any, record: any) => (
        <Link
          to={`/forms/${formUuid}/responses/${record.id}`}
          target="_blank"
          style={{ padding: "0px", margin: "0px" }}
        >
          View Responses
        </Link>
      ),
    },
  ];

  return (
    <>
    <Table
      dataSource={dataSource}
      columns={window.innerWidth > 500 ? columns : columnsForMobile}
      pagination={{
        position: ["bottomCenter"],
        defaultPageSize: 5,
      }}
    />
    {/* {isShowModal && <ViewResponseModal 
                          isShowModal={isShowModal} 
                          handleClose={()=>setShowModal(false)} 
                          record={viewRecord} 
                          formUuid={formUuid}
                          labelsList={labelsList}
                          handleLabelSelect={handleLabelSelect}
                          currentIndex={currentIndex} 
                          submissionsCount={submissionsCount} 
                          changeCurrentIndex={changeCurrentIndex}
                        />} */}
    </>
  );
};

export default FormIndividualStats;
