import React from "react";
import { Card } from "antd";

interface IProps {
  style?: React.CSSProperties | Object;
  children?: React.FC | object;
  title?: React.FC | object;
  actions?: React.ReactNode[];
}

export default function CTCard(props: IProps) {
  return (
    <>
      <Card {...props} />
    </>
  );
}
