import React from "react";
import { Form } from "antd";
import { IPluginData, IRule } from "../../core/interfaces/interfaces";

interface IProps {
  plugin_data: IPluginData;
  children: React.ReactNode;
  rules?: IRule[];
  plugin_uid?: string;
  format?: any;
  extra?: string;
  questionImageUrl?: string;
  questionImageSize?: string;
}

function FormElement(props: IProps) {
  const { plugin_data, rules, plugin_uid, extra } = props;

  const { name, required, initial } = plugin_data;

  return (
    <Form.Item
      name={plugin_uid ? `${name}_${plugin_uid}` : name}
      required={required}
      initialValue={initial}
      rules={rules}
      extra={extra}
      style={{ marginBottom: 0 }}
    >
      {props.children}
    </Form.Item>
  );
}

export default FormElement;
