import React from "react";

import { Button } from "antd";
import { CT_FORMS_SUPPORT_EMAIL } from "../../../shared/constants/constants";

const UpgradeButton = () => {
  return (
    <Button
      style={{
        borderRadius: "90px",
        marginLeft: "20px",
        fontSize: "12px",
        fontWeight: 900,
        lineHeight: "24px",
        backgroundColor: " #748FB5",
        color: "white",
        fontFamily: "Inter",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
      onClick={(e) => {
        window.open(`mailto:${CT_FORMS_SUPPORT_EMAIL}`);
      }}
    >
      Upgrade
    </Button>
  );
};

export default UpgradeButton;
