import React from "react";

import axios from "axios";
import { Card, Popconfirm, Button, message, Typography } from "antd";
import { Redirect } from "react-router-dom";

const { Title } = Typography;

const DeleteAccount = () => {
  const [redirectToLogin, setRedirectToLogin] = React.useState(false);

  const handleDeleteAccountConfirm = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_SERVER_URL}/auth/user/`);
      message.success("Account deleted successfully");
      localStorage.clear();
      setRedirectToLogin(true);
    } catch (err) {
      console.log("errr", err);
      message.error("Account deletion failed");
    }
  };

  return (
    <>
      <Card
        title={<Title level={4}>Delete Account</Title>}
        style={{
          paddingBottom: "0px",
          width: "100%",
        }}
      >
        <Popconfirm
          title="Are you sure to delete your account?"
          onConfirm={handleDeleteAccountConfirm}
          okText="Yes"
          cancelText="No"
        >
          <Button  type="text" danger>
            Delete Account
          </Button>
        </Popconfirm>
      </Card>
      {redirectToLogin && <Redirect to="/login" />}
    </>
  );
};

export default DeleteAccount;
