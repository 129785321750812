import React, { useEffect } from "react";

import { Row, Col, Layout } from "antd";

import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

import * as H from "history";

import ResetPasswordForm from "../../components/forgotPassword/ResetPasswordForm";
import { getCurrentSessionTokens } from "../../core/services/session";

import { StateType } from "../../../app/core/redux/types";
import { SetAuthenticated } from "../../../app/core/redux/app/actions";

import AppTitle from "../../components/appTitle/AppTitle";

const qs = require("query-string");

type AuthProps = {
  isAuthenticated: boolean;
  error?: string;
  success?: boolean;
  message?: string;
};

type StateProps = {
  auth: AuthProps;
};

type Props = {
  history: H.History;
  location: H.Location;
  setAuthenticated: () => void;
  state: StateProps;
};

const ResetPassword: React.FC<Props> = ({
  history,
  location,
  setAuthenticated,
  state,
}) => {
  const data = qs.parse(location.search);

  const { isAuthenticated } = state.auth;

  const { accessToken } = getCurrentSessionTokens();

  useEffect(() => {
    if (isAuthenticated || accessToken) {
      localStorage.clear();
      history.push(window.location.pathname);
    }
  });

  const { token } = data;

  return (
    <Layout>
      <AppTitle />
      <Row
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Col
          span={24}
          xs={24}
          sm={20}
          md={16}
          xl={10}
          xxl={10}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              margin: "0",
              marginTop: "5%",
              width: "100%",
            }}
          >
            <ResetPasswordForm token={token} auth={state.auth} />
          </div>
        </Col>
      </Row>
    </Layout>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    setAuthenticated: () => dispatch(SetAuthenticated()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
