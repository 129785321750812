import React, { useState } from "react";
import {
  Input,
  Divider,
  Slider,
  Select,
  Radio,
  Checkbox,
  Switch,
  DatePicker,
  TimePicker,
  InputNumber,
  Rate,
  Collapse,
  Image,
  Card,
  Row,
  Col,
  Space,
} from "antd";

import { IFormData, IElement, IChoice } from "../../../utils/interfaces";

import FormElement from "./FormElement";

import FileUpload from "./FileUpload";
import LongTextMarkdownEditor from "./LongTextMarkdownEditor";
import CustomPhoneInput from "./CustomPhoneInput";
import { FALLBACK_IMAGE } from "../../../constants";

// import "./FormSubmitFields.css";
import VideoUpload from "./VideoUpload";
import FileUploadForVideo from "./FileUploadForVideo";

const qs = require("query-string");

const { Panel } = Collapse;

const elementToReturn = (element: IElement) => {
  const {
    metadata: { elementType: label, questionImageSize },
    questionImageUrl,
    plugin_data,
  } = element;

  let hasImages: any;

  switch (label) {
    case "Text":
      return (
        <FormElement
          questionImageSize={questionImageSize}
          questionImageUrl={questionImageUrl}
          plugin_data={plugin_data}
          rules={[
            {
              required: plugin_data.required,
              message: "This field is required",
            },
            {
              max: parseInt(String(plugin_data.max_length)),
              message: `Cannot be longer than ${plugin_data.max_length} characters`,
            },
          ]}
          extra={plugin_data.help_text}
        >
          <Input
            className="ct_forms_input_field"
            style={{
              borderBottom: "0.5px solid black",
            }}
            bordered={false}
            {...plugin_data}
          />
        </FormElement>
      );
    case "Slider":
      return (
        <FormElement
          questionImageSize={questionImageSize}
          questionImageUrl={questionImageUrl}
          plugin_data={{ ...plugin_data, initial: plugin_data.min_value }}
          rules={[
            {
              required: plugin_data.required,
              message: "This field is required",
            },
          ]}
          extra={plugin_data.help_text}
        >
          <Slider
            marks={{
              [plugin_data.min_value]: {
                label: plugin_data.label_start || plugin_data.min_value,
                style: {
                  transform: "translateX(-4%)",
                  color: "black",
                },
              },
              [plugin_data.max_value]: {
                label: plugin_data.label_end || plugin_data.max_value,
                style: {
                  transform: "translateX(-96%)",
                  color: "black",
                },
              },
            }}
            step={plugin_data.step}
            max={plugin_data.max_value}
            min={plugin_data.min_value}
          />
        </FormElement>
      );
    case "Dropdown":
      return (
        <FormElement
          questionImageSize={questionImageSize}
          questionImageUrl={questionImageUrl}
          plugin_data={plugin_data}
          rules={[
            {
              required: plugin_data.required,
              message: "This field is required",
            },
          ]}
          extra={plugin_data.help_text}
        >
          <Select style={{ fontSize: "24px" }}>
            <Select.Option style={{ fontSize: "24px" }} value="">
              Select an option
            </Select.Option>
            {element.plugin_data.choices?.map((choice: IChoice) => (
              <Select.Option
                style={{ fontSize: "24px" }}
                key={choice.choice_text}
                value={choice.choice_text}
              >
                {choice.choice_text}
              </Select.Option>
            ))}
          </Select>
        </FormElement>
      );

    case "Checkbox":
      hasImages = element.plugin_data.choices?.some(
        (choice: IChoice) => choice.imageUrl
      );
      return (
        <FormElement
          questionImageSize={questionImageSize}
          questionImageUrl={questionImageUrl}
          plugin_data={plugin_data}
          rules={[
            {
              required: plugin_data.required,
              message: "This field is required",
            },
          ]}
          extra={plugin_data.help_text}
        >
          <Checkbox.Group
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexFlow: "row wrap",
            }}
          >
            <Row
              gutter={24}
              style={{
                width: "100%",
              }}
            >
              {element.plugin_data.choices?.map((choice: IChoice) => (
                <Col
                  span={window.innerWidth <= 769 ? 24 : 12}
                  style={{ width: "100%" }}
                >
                  <Card
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      marginLeft: 12,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      minWidth: "100%",
                      border: "1px solid #000000",
                    }}
                  >
                    <Space direction="vertical">
                      {hasImages && (
                        <Image
                          src={choice.imageUrl || FALLBACK_IMAGE}
                          style={{
                            maxHeight: 100,
                            maxWidth: 240,
                          }}
                          alt="Choice Image"
                          fallback={FALLBACK_IMAGE}
                        />
                      )}
                      <Checkbox
                        key={choice.choice_text}
                        value={choice.choice_text}
                        style={{
                          wordBreak: "break-word",
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "bold",
                          fontSize: "32px",
                          color: "#272E35",
                        }}
                      >
                        {choice.choice_text}
                      </Checkbox>
                    </Space>
                  </Card>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </FormElement>
      );

    case "Radio":
      hasImages = element.plugin_data.choices?.some(
        (choice: IChoice) => choice.imageUrl
      );
      return (
        <FormElement
          questionImageSize={questionImageSize}
          questionImageUrl={questionImageUrl}
          plugin_data={plugin_data}
          rules={[
            {
              required: plugin_data.required,
              message: "This field is required",
            },
          ]}
          extra={plugin_data.help_text}
        >
          <Radio.Group
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexFlow: "row wrap",
            }}
          >
            <Row
              gutter={24}
              style={{
                width: "100%",
              }}
            >
              {element.plugin_data.choices?.map((choice: IChoice) => (
                <Col
                  span={window.innerWidth <= 769 ? 24 : 12}
                  style={{ width: "100%" }}
                >
                  <Card
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      marginLeft: 12,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      minWidth: "100%",
                      border: "1px solid #000000",
                    }}
                  >
                    <Space direction="vertical">
                      {hasImages && (
                        <Image
                          src={choice.imageUrl || FALLBACK_IMAGE}
                          style={{
                            maxHeight: 100,
                            maxWidth: 240,
                          }}
                          alt="Choice Image"
                          fallback={FALLBACK_IMAGE}
                        />
                      )}
                      <Radio
                        key={choice.choice_text}
                        value={choice.choice_text}
                        style={{
                          wordBreak: "break-word",
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "bold",
                          fontSize: "32px",
                          color: "#272E35",
                        }}
                      >
                        {choice.choice_text}
                      </Radio>
                    </Space>
                  </Card>
                </Col>
              ))}
            </Row>
          </Radio.Group>
        </FormElement>
      );

    case "Long Text":
      return (
        <FormElement
          questionImageSize={questionImageSize}
          questionImageUrl={questionImageUrl}
          plugin_data={plugin_data}
          rules={[
            {
              required: plugin_data.required,
              message: "This field is required",
            },
            {
              max: parseInt(String(plugin_data.max_length)),
              message: `Cannot be longer than ${plugin_data.max_length} characters`,
            },
          ]}
          extra={plugin_data.help_text}
        >
          <LongTextMarkdownEditor placeholder={plugin_data.placeholder} />
        </FormElement>
      );

    case "Switch":
      return (
        <FormElement
          questionImageSize={questionImageSize}
          questionImageUrl={questionImageUrl}
          plugin_data={{ ...plugin_data, initial: false }}
          rules={[
            {
              required: plugin_data.required,
              message: "This field is required",
            },
          ]}
          extra={plugin_data.help_text}
        >
          <Switch />
        </FormElement>
      );

    case "Email":
      return (
        <FormElement
          questionImageSize={questionImageSize}
          questionImageUrl={questionImageUrl}
          plugin_data={plugin_data}
          rules={[
            {
              required: plugin_data.required,
              message: "This field is required",
            },
            {
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Invalid email",
            },
            {
              max: parseInt(String(plugin_data.max_length)),
              message: `Cannot be longer than ${plugin_data.max_length} characters`,
            },
          ]}
          extra={plugin_data.help_text}
        >
          <Input
            className="ct_forms_input_field"
            style={{
              borderBottom: "0.5px solid #272E35",
            }}
            bordered={false}
            {...plugin_data}
          />
        </FormElement>
      );

    case "Link":
      return (
        <FormElement
          questionImageSize={questionImageSize}
          questionImageUrl={questionImageUrl}
          plugin_data={plugin_data}
          rules={[
            {
              required: plugin_data.required,
              message: "This field is required",
            },
            {
              pattern:
                /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
              message: "Enter url in http://www.url.com format",
            },
            {
              max: parseInt(String(plugin_data.max_length)),
              message: `Cannot be longer than ${plugin_data.max_length} characters`,
            },
          ]}
          extra={plugin_data.help_text}
        >
          <Input
            className="ct_forms_input_field"
            style={{
              borderBottom: "0.5px solid #272E35",
            }}
            bordered={false}
            {...plugin_data}
            placeholder={plugin_data.placeholder || "http://www.url.com"}
          />
        </FormElement>
      );

    case "Password":
      return (
        <FormElement
          questionImageSize={questionImageSize}
          questionImageUrl={questionImageUrl}
          plugin_data={plugin_data}
          rules={[
            {
              required: plugin_data.required,
              message: "This field is required",
            },
            {
              max: parseInt(String(plugin_data.max_length)),
              message: `Cannot be longer than ${plugin_data.max_length} characters`,
            },
          ]}
          extra={plugin_data.help_text}
        >
          <Input.Password
            className="ct_forms_input_field"
            style={{
              borderBottom: "0.5px solid #272E35",
            }}
            bordered={false}
            autoComplete="off"
            {...plugin_data}
          />
        </FormElement>
      );

    case "DatePicker":
      return (
        <FormElement
          questionImageSize={questionImageSize}
          questionImageUrl={questionImageUrl}
          plugin_data={{
            ...plugin_data,
            initial: null,
          }}
          rules={[
            {
              required: plugin_data.required,
              message: "This field is required",
            },
          ]}
          plugin_uid={element.plugin_uid}
          format={plugin_data.input_formats}
          extra={plugin_data.help_text}
        >
          <DatePicker format={plugin_data.input_formats} {...plugin_data} />
        </FormElement>
      );

    case "TimePicker":
      return (
        <FormElement
          questionImageSize={questionImageSize}
          questionImageUrl={questionImageUrl}
          plugin_data={{
            ...plugin_data,
            initial: null,
          }}
          rules={[
            {
              required: plugin_data.required,
              message: "This field is required",
            },
          ]}
          plugin_uid={element.plugin_uid}
          extra={plugin_data.help_text}
        >
          <TimePicker format={"HH:mm"} />
        </FormElement>
      );

    case "Number":
      return (
        <FormElement
          questionImageSize={questionImageSize}
          questionImageUrl={questionImageUrl}
          plugin_data={plugin_data}
          rules={[
            {
              required: plugin_data.required,
              message: "This field is required",
            },
          ]}
          extra={plugin_data.help_text}
        >
          <InputNumber
            className="ct_forms_input_field"
            style={{
              borderBottom: "0.5px solid #272E35",
              width: "100%",
            }}
            bordered={false}
            max={plugin_data.max_value}
            min={plugin_data.min_value}
            precision={plugin_data.decimal_places}
            {...plugin_data}
          />
        </FormElement>
      );

    case "Ratings":
      return (
        <FormElement
          questionImageSize={questionImageSize}
          questionImageUrl={questionImageUrl}
          plugin_data={plugin_data}
          rules={[
            {
              required: plugin_data.required,
              message: "This field is required",
            },
          ]}
          extra={plugin_data.help_text}
        >
          <Rate
            allowHalf={plugin_data.halfAllowed}
            count={plugin_data.max_value}
          />
        </FormElement>
      );
    case "Upload":
      return (
        <>
          <FileUpload
            questionImageUrl={questionImageUrl}
            questionImageSize={questionImageSize}
            plugin_data={plugin_data}
            rules={[
              {
                required: plugin_data.required,
                message: "This field is required",
              },
            ]}
            extra={plugin_data.help_text}
          />
        </>
      );
    case "Phone":
      return (
        <FormElement
          questionImageSize={questionImageSize}
          questionImageUrl={questionImageUrl}
          plugin_data={plugin_data}
          rules={[
            {
              required: plugin_data.required,
              message: "This field is required",
            },
          ]}
          extra={plugin_data.help_text}
        >
          <CustomPhoneInput />
        </FormElement>
      );

    case "Video":
      return (
        <>
          <Collapse accordion>
            <Panel header="Record and Upload Video" key="1">
              <VideoUpload
                questionImageUrl={questionImageUrl}
                questionImageSize={questionImageSize}
                plugin_data={plugin_data}
                rules={[
                  {
                    required: plugin_data.required,
                    message: "This field is required",
                  },
                ]}
                extra={plugin_data.help_text}
              />
            </Panel>
            <Panel header="Upload Video" key="2">
              <FileUploadForVideo
                questionImageUrl={questionImageUrl}
                questionImageSize={questionImageSize}
                plugin_data={plugin_data}
                rules={[
                  {
                    required: plugin_data.required,
                    message: "This field is required",
                  },
                ]}
                extra={plugin_data.help_text}
              />
            </Panel>
          </Collapse>
        </>
      );

    default:
      return <p>Element not supported yet.</p>;
  }
};

export default elementToReturn;
