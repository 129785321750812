import React from "react";

import { Result } from "antd";

const FormSubmitSuccess = () => {
  return (
    <Result
      status="success"
      title="Your response was submitted successfully."
      subTitle="Thank you for using CT forms. You may now close this tab."
    />
  );
};

export default FormSubmitSuccess;
