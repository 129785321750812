import React from "react";

import { Table } from "antd";

const TableForDesktop = (props: any) => {
  const { columns, dataSource: data } = props;

  return (
    <>
      <div style={{ maxWidth: "90vw", overflowY: "auto" }}>
        <Table
          showHeader={true}
          tableLayout="auto"
          columns={columns}
          dataSource={data}
          pagination={{
            position: ["bottomCenter"],
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30"],
          }}
          size="middle" // changed from large to middle
          // sticky={true}
        />
      </div>
    </>
  );
};

export default TableForDesktop;
