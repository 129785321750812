import React, { useState } from "react";

import { Button, Spin } from "antd";

import { Document, Page, pdfjs } from "react-pdf";

import { BackwardOutlined, ForwardOutlined } from "@ant-design/icons";

pdfjs.GlobalWorkerOptions.workerSrc =
  "//cdn.jsdelivr.net/npm/pdfjs-dist@2.6.347/build/pdf.worker.js";

const PdfFilePreview = (props: any) => {
  const { fileSrc } = props;

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = (prop: any) => {
    const { numPages } = prop;
    setNumPages(numPages);
  };

  return (
    <div
      style={{
        maxHeight: "70vh",
        maxWidth: "100%",
        overflow: "auto",
      }}
    >
      <Document
        file={fileSrc}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={
          <div>
            <p>Please wait while we load your PDF....</p> <Spin />
          </div>
        }
      >
        <Page pageNumber={pageNumber} />
      </Document>
      <p>
        Page {pageNumber} of {numPages}
      </p>
      <span>
        <Button
          onClick={() => setPageNumber(pageNumber - 1)}
          disabled={pageNumber === 1}
        >
          <BackwardOutlined />
        </Button>
        <Button
          onClick={() => setPageNumber(pageNumber + 1)}
          disabled={pageNumber === numPages}
        >
          <ForwardOutlined />
        </Button>
      </span>
    </div>
  );
};

export default PdfFilePreview;
