import React, { useState } from "react";

import { Input, Button, Modal } from "antd";
import MDEditor from "@uiw/react-md-editor";

import { connect, ConnectedProps } from "react-redux";
import { IFormData } from "../utils/interfaces";

import "./FormHead.css";

interface IProps extends PropsFromRedux {
  onFormDetailsUpdate: any;
}

function FormHead(props: IProps) {
  const { title, description, onFormDetailsUpdate, dispatch } = props;
  const [showFullscreenEditor, setShowFullscreenEditor] = useState(false);

  return (
    <div
      style={{
        maxWidth: 800,
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        padding: 10,
        paddingLeft: 20,
        alignItems: "flex-start",
        background: "#f7f7f8",
        borderRadius: "24px 24px 0px 0px",
      }}
    >
      <Input.TextArea
        autoSize={{ minRows: 1, maxRows: 2 }}
        bordered={false}
        value={title}
        placeholder="Title"
        maxLength={50}
        style={{
          fontSize: 40,
          fontWeight: 600,
          color: "#272E35",
          wordBreak: "break-word",
        }}
        onChange={(e) => {
          onFormDetailsUpdate({ title: e.target.value });

          dispatch({
            type: "ADD_TITLE",
            payload: e.target.value,
          });
        }}
      />
      <MDEditor.Markdown
        source={description}
        style={{
          maxHeight: 200,
          width: "100%",
          overflowY: "scroll",
          textAlign: "left",
          marginLeft: "12px",
          paddingRight: "12px",
          wordBreak: "break-word",
        }}
      />
      <Button
        className="ct_forms_secondary_button"
        style={{
          marginTop: 15,
          marginLeft: 4,
        }}
        onClick={() => setShowFullscreenEditor(!showFullscreenEditor)}
      >
        Edit Description
      </Button>
      <Modal
        visible={showFullscreenEditor}
        onCancel={() => setShowFullscreenEditor(!showFullscreenEditor)}
        width="80vw"
        footer={null}
        bodyStyle={{
          paddingTop: 50,
        }}
      >
        <MDEditor
          className="form_description_markdown_editor"
          placeholder="Form Description"
          textareaProps={{
            placeholder: "Form Description",
          }}
          value={description}
          onChange={(value) => {
            onFormDetailsUpdate({
              description: value,
            });
            dispatch({
              type: "ADD_DESCRIPTION",
              payload: value,
            });
          }}
        />
      </Modal>
    </div>
  );
}

interface RootState {
  form: IFormData;
}

interface IAction {
  type: string;
  payload: any;
}

const mapState = (state: RootState) => ({
  title: state.form.title,
  description: state.form.description,
});

const mapDispatch = {
  dispatch: (action: IAction) => action,
};

export const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FormHead);
