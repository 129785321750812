import axios from "axios";
import UAParser from "ua-parser-js";

import { message } from "antd";

const getClientIP = async () => {
  try {
    const response = await axios.request({
      method: "GET",
      url: "https://find-any-ip-address-or-domain-location-world-wide.p.rapidapi.com/iplocation",
      params: { apikey: "873dbe322aea47f89dcf729dcc8f60e8" },
      headers: {
        "x-rapidapi-host":
          "find-any-ip-address-or-domain-location-world-wide.p.rapidapi.com",
        "x-rapidapi-key": "5909e1ccc1msh5004ec2f9bfa6cap11e18fjsn8cf8a3ad9203",
      },
    });

    return response.data.ip;
  } catch (error) {
    console.log(`error`, error);
    message.error("Error getting client info");
  }
};

const getAllClientDetails = async () => {
  try {
    const response = await axios.request({
      method: "GET",
      url: "https://find-any-ip-address-or-domain-location-world-wide.p.rapidapi.com/iplocation",
      params: { apikey: "873dbe322aea47f89dcf729dcc8f60e8" },
      headers: {
        "x-rapidapi-host":
          "find-any-ip-address-or-domain-location-world-wide.p.rapidapi.com",
        "x-rapidapi-key": "5909e1ccc1msh5004ec2f9bfa6cap11e18fjsn8cf8a3ad9203",
      },
    });

    return response.data;
  } catch (error) {
    console.log(`error`, error);
    message.error("Error getting client info");
  }
};

const getClientDeviceDetails = () => {
  const details = new UAParser();

  const deviceDetails = details.getResult();

  const { browser, os, device } = deviceDetails;

  const returnObject = {
    browser_info: `${browser.name} ${browser.major}`,
    os_info: `${os.name} ${os.version}`,
    device_info: `${device.vendor} ${device.model}`,
  };

  return returnObject;
};

export { getClientIP, getClientDeviceDetails, getAllClientDetails };
