import React from "react";
import { Input, InputNumber } from "antd";
import { IElement } from "../../utils/interfaces";
import AdvanceOptionsDrawer from "./ElementPreview/AdvanceOptionsDrawer";
import FormItem from "./ElementPreview/FormItem";

interface IProps {
  dispatch: ({ type, payload }: { type: string; payload: object }) => void;
  state: IElement;
  componentDisabled?: boolean;
  updateFieldValue: (fieldValue: object) => void;
  deleteButton: React.ReactNode;
  extraProps: React.ReactNode;
}

export default function CTSlider(props: IProps) {
  if (!props.state.plugin_data) return null;
  const advancedOptions = (
    <>
      <FormItem label={"Start Text"}>
        <Input
          key={"label_start"}
          value={props.state.plugin_data.label_start}
          maxLength={12}
          disabled={props.componentDisabled}
          onChange={(e) =>
            props.updateFieldValue({ label_start: e.target.value })
          }
        />
      </FormItem>
      <FormItem label={"End Text"}>
        <Input
          key={"label_end"}
          value={props.state.plugin_data.label_end}
          maxLength={12}
          disabled={props.componentDisabled}
          onChange={(e) =>
            props.updateFieldValue({ label_end: e.target.value })
          }
        />
      </FormItem>
      <FormItem label={"Minimum Value"} elementStyle={{ width: "100%" }}>
        <InputNumber
          key={"min_value"}
          value={props.state.plugin_data["min_value"]}
          max={props.state.plugin_data.max_value - 1}
          min={0}
          disabled={props.componentDisabled}
          onChange={(value) => {
            if (value !== null)
              props.updateFieldValue({ min_value: parseInt(value) });
          }}
        />
      </FormItem>
      <FormItem label={"Maximum Value"} elementStyle={{ width: "100%" }}>
        <InputNumber
          key={"max_value"}
          value={props.state.plugin_data["max_value"]}
          max={5000} // With reference to django-fobi
          min={props.state.plugin_data.min_value + 1}
          disabled={props.componentDisabled}
          onChange={(value) => {
            if (value !== null)
              props.updateFieldValue({ max_value: parseInt(value) });
          }}
        />
      </FormItem>
      <FormItem label={"Number of steps"} elementStyle={{ width: "100%" }}>
        <InputNumber
          key={"step"}
          value={props.state.plugin_data["step"]}
          max={
            props.state.plugin_data.max_value -
            props.state.plugin_data.min_value
          }
          min={1}
          disabled={props.componentDisabled}
          onChange={(value) => {
            if (value !== null)
              props.updateFieldValue({ step: parseInt(value) });
          }}
        />
      </FormItem>
    </>
  );
  return (
    <AdvanceOptionsDrawer
      componentDisabled={props.componentDisabled}
      deleteButton={props.deleteButton}
      advancedOptions={advancedOptions}
      state={props.state}
      extraProps={props.extraProps}
    />
  );
}
