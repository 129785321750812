import React, { useEffect, useState } from "react";

import { Avatar, Button, Dropdown, Menu, Layout } from "antd";
import Title from "antd/lib/typography/Title";
import { UserOutlined } from "@ant-design/icons";

import { connect, useDispatch } from "react-redux";

import { Link, useLocation } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

import { GetUserData, LogoutUser } from "../../../auth/core/redux/actions";
import { StateType } from "../../core/redux/types";
import THEMECOLORS from "../../../shared/theme/themeColors";
import AddFormModal from "../forms/addFormModal/AddFormModal";

import PrivacyPolicyBar from "./PrivacyPolicyBar";
import UpgradeButton from "./UpgradeButton";

const { Header } = Layout;

const Navbar = (props: any) => {
  const dispatch = useDispatch();

  const location = useLocation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [isAddFormModalOpen, setIsAddFormModalOpen] = useState<boolean>(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    console.log("Logout user was called");
    handleClose();
    dispatch(LogoutUser());
  };

  const [userData, setUserData] = useState<any>();

  useEffect(() => {
    if (props.state?.auth?.userData) {
      console.log("userdata ---> ", props.state.auth.userData);
      setUserData(props.state.auth.userData);
    } else {
      props.getUserData();
    }
  }, [props]);

  const menu = (
    <Menu>
      <Menu.Item>
        <Link to="/account/settings">Settings</Link>
      </Menu.Item>
      <Menu.Item danger onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  const adminMenu = (
    <Menu>
      <Menu.Item danger onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  const NavMenuForAll = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <Link
        to="/dashboard"
        style={{
          textDecoration: "none",
          color: "white",
          paddingRight: "20px",
          paddingTop: "3px",
        }}
      >
        <Title
          style={{
            color: location.pathname.includes("/dashboard")
              ? THEMECOLORS.selected
              : "black",
          }}
          level={5}
        >
          Dashboard
        </Title>
      </Link>
      <Button
        type="primary"
        className="ct_forms_primary_button"
        onClick={() => setIsAddFormModalOpen(true)}
      >
        Create Form
      </Button>
      <UpgradeButton />
      {userData && userData.is_staff && (
        <Button
          type="ghost"
          style={{
            width: "250px",
            height: "30px",
            textTransform: "capitalize",
            marginLeft: "30px",
            color: "white",
            backgroundColor: "Red",
          }}
        >
          <b> ADMIN MODE</b>
        </Button>
      )}
    </div>
  );

  const NavForAdmin = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
      }}
    >
      <Link
        to="/dashboard"
        style={{
          textDecoration: "none",
          color: "white",
          paddingRight: "20px",
          paddingTop: "3px",
        }}
      >
        <Title
          style={{
            color: location.pathname.includes("/dashboard")
              ? THEMECOLORS.selected
              : "black",
          }}
          level={5}
        >
          Dashboard
        </Title>
      </Link>
      <Button
        type="primary"
        style={{
          width: "150px",
          height: "30px",
          textTransform: "none",
          marginLeft: "30px",
        }}
        onClick={() => setIsAddFormModalOpen(true)}
      >
        {props.state.auth.userData?.is_staff ? "Add Template" : "Create Form"}
      </Button>
    </div>
  );

  return (
    <>
      <Header
        style={{
          paddingTop: "15px",
          backgroundColor: "#f0f2f5",
          boxShadow: "0 2px 8px #f0f1f2",
          height: "80px",
        }}
      >
        <Layout
          style={{
            float: "right",
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#f0f2f5",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {userData && userData.is_staff === false && (
            <Dropdown overlay={menu}>
              {userData &&
              userData.profile_picture &&
              userData.profile_picture.thumbnail_small ? (
                <img
                  src={userData.profile_picture.thumbnail_small}
                  alt="profile"
                  style={{
                    height: "50px",
                    width: "50px",
                    // border: "1px solid gray",
                    padding: "2px",
                    borderRadius: "25px",
                  }}
                />
              ) : (
                <Avatar
                  style={{ marginLeft: "20px" }}
                  icon={<UserOutlined style={{ fontSize: "10px" }} />}
                />
              )}
            </Dropdown>
          )}
          {userData && userData.is_staff && (
            <Dropdown overlay={adminMenu}>
              <Button
                type="primary"
                style={{
                  width: "100px",
                  height: "30px",
                  textTransform: "capitalize",
                  marginLeft: "30px",
                  color: "white",
                  backgroundColor: "Red",
                }}
              >
                <b> ADMIN</b>
              </Button>
            </Dropdown>
          )}
        </Layout>

        <Layout
          style={{
            float: "left",
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#f0f2f5",
            alignItems: "center",
            paddingLeft: "10px",
            paddingTop: "3px",
          }}
        >
          {userData && userData.is_staff ? NavForAdmin : NavMenuForAll}
        </Layout>
      </Header>

      {userData && !userData?.is_verified && !userData.is_staff && (
        <PrivacyPolicyBar />
      )}

      {isAddFormModalOpen && (
        <AddFormModal
          isModalOpen={isAddFormModalOpen}
          setModalOpen={setIsAddFormModalOpen}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getUserData: () => dispatch(GetUserData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
