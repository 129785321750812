import React, { useEffect, useState } from "react";

import { Button, Col, Modal, Row, Space, Spin, Tooltip } from "antd";

import axios from "axios";

import { Link, useParams } from "react-router-dom";
import TableForDesktop from "./TableForDesktop";

import { DownloadOutlined, EyeOutlined, SyncOutlined } from "@ant-design/icons";
import TableForMobile from "./TableForMobile";
import AntSpinner from "../../../shared/customComponents/spinner/AntSpinner";

import FilePreview from "./FilePreview";

import "./uploaded-files.css";

const UploadedFiles = () => {
  const params = useParams<any>();

  const { uuid } = params;

  const [filesData, setFilesData] = useState<any>();

  const [loading, setLoading] = useState<boolean>(true);

  const [loadMoreSpinner, setLoadMoreSpinner] = useState<boolean>(false);

  const [isMoreFilesAvailable, setIsMoreFilesAvailable] =
    useState<boolean>(true);

  const [nextToken, setNextToken] = useState<null | string>(null);

  const columns = [
    {
      title: "Name",
      dataIndex: "key",
      ellipsis: true,
      width: "200px",
      render: (key: React.ReactNode, record: any) => (
        <p
          style={{
            maxWidth: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {record.metadata.hasOwnProperty("file_name")
            ? record.metadata.file_name
            : key}
        </p>
      ),
    },
    {
      title: "Type",
      dataIndex: "content_type",
      key: "content_type",
      width: "100px",
      render: (content_type: any) => <p>{content_type}</p>,
    },
    {
      title: "File Size",
      dataIndex: "content_length",
      key: "content_length",
      width: "80px",
      render: (content_length: any) => {
        const fileSizeInMB =
          content_length && !isNaN(content_length)
            ? content_length / 1024 / 1024
            : 0;
        const fileSizeInKB =
          content_length && !isNaN(content_length) ? content_length / 1024 : 0;
        return fileSizeInMB >= 1 ? (
          <p>{fileSizeInMB.toFixed(2)} MB</p>
        ) : (
          <p>{fileSizeInKB.toFixed(2)} KB</p>
        );
      },
    },
    {
      title: "Last Updated on",
      dataIndex: "last_modified",
      key: "last_modified",
      width: "100px",
      //   sorter: {
      //     compare: (a: { updated: any }, b: { updated: moment.MomentInput }) =>
      //       moment(a.updated).unix() - moment(b.updated).unix(),
      //   },
      render: (updated: any) => (
        <p>
          {" "}
          {new Date(updated).toLocaleDateString("en-gb", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
          {", "}
          {
            Intl.DateTimeFormat("en", {
              hour: "numeric",
              minute: "numeric",
            }).format(new Date(updated)) // 2 PM
          }
        </p>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "100px",
      render: (text: any, record: any) => (
        <Space size="middle">
          <Button
            onClick={() => {
              resolveAWSFilePathForDownload(record.key);
            }}
          >
            <DownloadOutlined />
          </Button>

          <Tooltip title="Preview File">
            <Button
              onClick={() => {
                resolveAWSFilePath(record.key);
                setActiveFile(record);
              }}
            >
              <EyeOutlined />
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const columnsForMobile = [
    {
      title: "Name",
      dataIndex: "key",
      ellipsis: true,
      width: "200px",
      render: (key: React.ReactNode, record: any) => (
        <p
          style={{
            maxWidth: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {record.metadata.hasOwnProperty("file_name")
            ? record.metadata.file_name
            : key}
        </p>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "100px",
      render: (text: any, record: any) => (
        <Space size="middle">
          <Button onClick={() => resolveAWSFilePathForDownload(record.key)}>
            <DownloadOutlined />
          </Button>

          <Tooltip title="Preview File">
            <Button
              onClick={() => {
                resolveAWSFilePath(record.key);
                setActiveFile(record);
              }}
            >
              <EyeOutlined />
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const [activeAWSURL, setActiveAWSURL] = useState<any>();

  const [activeFile, setActiveFile] = useState<any>();

  const resolveAWSFilePath = async (media_key: string) => {
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/api/base/get-s3-url/?key=${media_key}`
      )
      .then(async (result1) => {
        console.log("image URL", result1.data.url);

        setActiveAWSURL(result1.data.url);

        setIsModalVisible(true);
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
      });
  };

  const resolveAWSFilePathForDownload = async (media_key: string) => {
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/api/base/get-s3-url/?key=${media_key}`
      )
      .then(async (result1) => {
        console.log("image URL", result1.data.url);

        setActiveAWSURL(result1.data.url);
        window.open(result1.data.url, "_blank");
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
      });
  };

  const getUploadedFiles = async () => {
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/${uuid}/uploads/?size=10`)
      .then((res) => {
        console.log("---> uploaded files---->", res.data);
        if (res.data.next_page_token == null) {
          setIsMoreFilesAvailable(false);
        }
        setFilesData(res.data.data);
        setNextToken(res.data.next_page_token);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };

  const getMoreUploadedFiles = async () => {
    setLoadMoreSpinner(true);
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/api/${uuid}/uploads/?size=10&next_page_token=${nextToken}`
      )
      .then((res) => {
        const newData = filesData.concat(res.data.data);

        if (res.data.next_page_token == null) {
          setIsMoreFilesAvailable(false);
        }

        setFilesData(newData);
        setNextToken(res.data.next_page_token);

        setLoading(false);
        setLoadMoreSpinner(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        setLoadMoreSpinner(false);
      });
  };

  useEffect(() => {
    getUploadedFiles();
  }, []);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      {loading ? (
        <AntSpinner />
      ) : (
        <Row>
          <Col
            span={24}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              onClick={() => getUploadedFiles()}
              type="primary"
              style={{ margin: "5px ", marginBottom: "10px" }}
            >
              {" "}
              Refresh <SyncOutlined />
            </Button>
          </Col>
          <Col
            span={24}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {window.innerWidth < 769 ? (
              <TableForMobile
                columns={columnsForMobile}
                dataSource={filesData}
              />
            ) : (
              <TableForDesktop
                columns={columns}
                dataSource={filesData}
                loading={loading}
              />
            )}
          </Col>
          <Col
            span={24}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isMoreFilesAvailable && (
              <Button
                style={{ margin: "5px", width: "100px", marginBottom: "10px" }}
                onClick={() => {
                  getMoreUploadedFiles();
                }}
                disabled={loadMoreSpinner}
              >
                {loadMoreSpinner ? <Spin /> : "Load More"}
              </Button>
            )}
          </Col>
        </Row>
      )}
      <Modal
        title="File Preview"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <FilePreview activeAWSURL={activeAWSURL} activeFile={activeFile} />
      </Modal>
    </div>
  );
};

export default UploadedFiles;
