import React from "react";
import { Menu, Dropdown, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { CTComponents } from "../CTComponents";

import "./NavBar.css";

interface IProps {
  addAnswerType: (label: string) => void;
}

const NavBar = (props: IProps) => {
  return (
    <nav className="navbar">
      <Dropdown
        trigger={["click"]}
        placement="topLeft"
        overlay={
          <Menu style={{ display: "flex", flexDirection: "column" }}>
            {Object.keys(CTComponents).map((label: string, index) => (
              <Menu.Item key={label} onClick={() => props.addAnswerType(label)}>
                <span>{label}</span>
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <Button
          style={{
            background: "#FF6E30",
            color: "white",
            height: "50px",
            width: "50px",
          }}
          shape="circle"
        >
          <PlusOutlined style={{ fontSize: "25px" }} />
        </Button>
      </Dropdown>
    </nav>
  );
};
export default NavBar;
