import React, { useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { Drawer, Typography,Button,DatePicker,TimePicker,message,Divider,Input,Form,Tag,Select,Image } from "antd";
import {ChromePicker} from 'react-color'
import moment from 'moment'
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { FALLBACK_IMAGE } from "../../../shared/constants/constants";
import MDEditor from "@uiw/react-md-editor";
import ChoiceResponse from "../formBuilderPage/ChoiceResponse";
import FileUploadTypeResponse from "../formBuilderPage/FileUploadTypeResponse";

const {Title}=Typography
const {TextArea} = Input
const {Option} = Select
const ViewResponseModal =(props:any)=>{
    const {isShowModal,handleClose,record,formUuid,assignLabel,handleNotes2}=props
    const [loading, setLoading] = React.useState(false);

    const [form]=Form.useForm()
    const [labelsList, setlabelsList] = React.useState<any>()
  
    let { uuid } = useParams<any>();
    const [isTextarea,setTextArea]=useState(record?.notes?record.notes:'')
    const isMobileView = window.innerWidth < 1200;

    const getLabelsData=()=>{
          axios
            .get(
                `${process.env.REACT_APP_SERVER_URL}/api/${formUuid===undefined?uuid:formUuid}/labels`
            )
            .then( (result1) => {
                setlabelsList(result1.data.data);
            })
            .catch((err: any) => {
                console.log("errors could not be get", err);
            });
        }
    
    const handleNotes=()=>{
      handleNotes2(record.id,isTextarea)
    }
  const handleLabelSelect=(id:number,values:any)=>{
    const labels=values.map((item:any,idx:number)=>{
      return {form_label:item}
    })
    assignLabel(id,labels )
  }
 
  React.useEffect(() => {
    getLabelsData()
  }, [])

  React.useEffect(() => {
    const getS3FilePath = async (media_key: string, field: any) => {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/base/get-s3-url/?key=${media_key}`
        );
        console.log(`result from AWS`, result);
        if (field.questionImageKey) {
          field.questionImageUrl = result.data.url;
        } else {
          field.imageUrl = result.data.url;
        }
      } catch (error) {
        console.log("errors could not be get", error);
      }
    };

    setLoading(true);
    const promises: any = [];
    record.data.forEach((element: any) => {
      if (element.element_metadata.hasOwnProperty("questionImageKey")) {
        promises.push(
          getS3FilePath(element.element_metadata.questionImageKey, element)
        );
      }
      if (element.element_metadata.elementType === "Radio") {
        if (element.response.imageKey) {
          promises.push(
            getS3FilePath(element.response.imageKey, element.response)
          );
        }
      }
      if (element.element_metadata.elementType === "Checkbox") {
        element.response.forEach((choice: any) => {
          if (choice.imageKey) {
            promises.push(getS3FilePath(choice.imageKey, choice));
          }
        });
      }
    });

    const allPromises = Promise.all(promises).then((values) => {
      console.log(`values`, values);
      setLoading(false);
    });
  }, []);
  const elementToReturn = (dataItem: any) => {
    let dataToReturn = null;
    if (dataItem) {
      if (dataItem.element_metadata.elementType === "Long Text") {
        return <MDEditor.Markdown source={dataItem.response} />;
      }
      if (
        ["Dropdown", "Checkbox", "Radio"].includes(
          dataItem.element_metadata.elementType
        )
      ) {
        if (Array.isArray(dataItem.response)) {
          return dataItem.response.map((choice: any) => {
            return (
              <ChoiceResponse
                choice={choice}
                elementType={dataItem.element_metadata.elementType}
              />
            );
          });
        } else {
          return (
            <ChoiceResponse
              choice={dataItem.response}
              elementType={dataItem.element_metadata.elementType}
            />
          );
        }
      }

      console.log("dataItem here --->", dataItem);
      // boolean
      if (dataItem.element_metadata.elementType === "Switch") {
        dataToReturn = dataItem.response;
      }
      // date
      else if (dataItem.element_metadata.elementType === "DatePicker") {
        dataToReturn = (
          <DatePicker
            value={moment(dataItem.response)}
            format={dataItem.element_metadata.dateFormat}
            disabled
          />
        );
      }
      // time
      else if (dataItem.element_metadata.elementType === "TimePicker") {
        dataToReturn = (
          <TimePicker
            value={moment(dataItem.response, "HH:mm:ss")}
            format={"HH:mm"}
            disabled
          />
        );
      }
      // url or email
      else if (
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(
          dataItem.response
        )
      ) {
        // email
        if (
          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
            dataItem.response
          )
        ) {
          dataToReturn = <p>{dataItem.response.toString()}</p>;
        }
        // url
        else {
          dataToReturn = (
            <a
              href={dataItem.response.toString()}
              target="_blank"
              rel="noreferrer"
              key={dataItem.response.toString()}
            >
              {dataItem.response.toString()}
            </a>
          );
        }
      }
      // upload
      else if (
        dataItem.element_metadata.elementType === "Upload" ||
        dataItem.element_metadata.elementType === "Video"
      ) {
        return (dataToReturn = (
          <div style={{ height: "100%", width: "100%", overflow: "none" }}>
            <FileUploadTypeResponse fileData={dataItem.response} />
          </div>
        ));
      }
      
      // pretty much everything else
      else {
        dataToReturn = dataItem.response.toString();
      }
      return dataToReturn;
    }

    if (dataToReturn) {
      return dataToReturn;
    }
  };
  
  const tagElement=record.labels!==undefined && record.labels.map((item:any)=> {return item.form_label})
      return (
        <Drawer
            title={
              <span
                style={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: 24,
                  color: "#FF6E30",
                  borderBottom: "none",
                }}
              >
              </span>
            }
            placement="right"
            onClose={() => handleClose(false)}
            visible={isShowModal}
            drawerStyle={{
              background: "#fff",
            }}
            headerStyle={{
              background: "#fff",
            }}
            width={isMobileView?"fullWidth":"680px"}
          >
          <div>
            <Typography.Paragraph
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "bolder",
                fontSize: "12px",
                lineHeight: "16px",
                textAlign: "left",
                letterSpacing: "0.1em",
                color: "#748FB5",
              }}
            >
            {/* {!record[0]?.msg &&  */}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "20px",
                height: "60px",
              }}
            >
              {!record[0]?.msg && record.metadata &&
              record.metadata.form_submitter_email ? (
                <Button type="link" style={{ height: "100%" }}>
                  <b style={{ fontSize: "16px" }}>
                      {console.log(record.id)}
                    {record.metadata.form_submitter_email}
                  </b>
                </Button>
              ) : (
                <Button type="link" style={{ height: "100%" }}>
                  <b style={{ fontSize: "16px" }}>
                    Submission ID {record.id}
                  </b>
                </Button>
              )}
            </div>
            {/* } */}
            <>
                <Divider/>
                  <Form 
                    form={form}
                    onFinish={handleNotes}>
                    <Title level={3} style={{marginLeft:'20px'}}>Labels</Title>
                    <Form.Item name='labelName' style={{marginLeft:'20px'}}>
                      {labelsList !== undefined && 
                        <Select
                          mode="multiple"
                          style={{ marginTop:'10px',width:'200px' }}
                          placeholder="Labels"
                          defaultValue={tagElement}
                          onChange={(values)=>handleLabelSelect(record.id,values)}
                          > 
                          {Object.values(labelsList).map((item:any,idx:number)=>{
                            return  <Option value={item.id} label={item.name}>
                                      <Tag color={item.color_hex}>{item.name}</Tag>
                                    </Option>
                            }
                          )}
                        </Select>
                      }
                    </Form.Item>
                    <Divider/>
                    <Title  level={3} style={{marginLeft:"20px"}}>Notes</Title>
                      {/* <Title level={5} style={{marginLeft:"20px"}}>{record.notes}</Title> */}
                    <Form.Item name='isTextarea'>
                      <TextArea rows={4} value={isTextarea} onChange={(e)=>setTextArea(e.target.value)}  style={{marginLeft:'12px'}}/>
                      <Button 
                        type='primary' 
                        htmlType='submit' 
                        style={{marginLeft:'12px',marginTop:'10px'}}
                      >
                        Save
                      </Button>
                    </Form.Item>
                  </Form>
                </>
               {!record[0]?.msg? record.data.map((dataItem:any,index:number)=>{
                 console.log('dataItem',dataItem)
                  return(
                    <>
                      <Divider />
                       {Boolean(dataItem.element_metadata.questionImageKey) && (
                      <Image
                        src={dataItem.imageUrl}
                        height={dataItem.element_metadata.questionImageSize}
                        width={dataItem.element_metadata.questionImageSize}
                        alt="Question Image"
                        fallback={FALLBACK_IMAGE}
                      />
                    )}
                        <Title  level={3} style={{marginLeft:"20px"}}>{dataItem.label}</Title>
                        {dataItem.element_metadata.elementType === "Email"?
                          <Title level={5} style={{marginLeft:"20px"}}>
                            <a href={`mailto:${dataItem.response}`} target='_blank' rel="noreferrer">{dataItem.response}</a>
                          </Title>
                        :
                        <Title level={5} style={{marginLeft:"20px"}}>
                          {elementToReturn(dataItem) !== null &&
                            elementToReturn(dataItem)
                          }
                        </Title>
                        }
                      </>
                    )
                }):
                <Title level={3} style={{marginLeft:"20px"}}>{record[0].msg}</Title>
              }
              
            </Typography.Paragraph>
          </div>
        </Drawer>
    )
}
export default ViewResponseModal