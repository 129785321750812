import React, { useState, useEffect } from "react";

import { Checkbox, Form, Card, Button, message } from "antd";

import axios from "axios";
import { useParams } from "react-router-dom";

import AntSpinner from "../../../shared/customComponents/spinner/AntSpinner";
import { StateType } from "../../core/redux/types";
import { connect } from "react-redux";

const TemplateVisibility = (props: any) => {
  const [formData, setFormData] = useState<any>();

  const [formName, setFormName] = useState<string>("");
  const [formDescription, setFormDescription] = useState<string>("");
  const [isFormPublic, setIsFormPublic] = useState<boolean>(true);

  const [isLoginRequired, setIsLoginRequired] = useState<boolean>(false);

  let { uuid } = useParams<any>();

  const [loading, setLoading] = useState(true);

  console.log("uuid", uuid);

  const getFormByUuid = async (setLoading: any) => {
    const url = props.state.auth.userData.is_staff
      ? `${process.env.REACT_APP_SERVER_URL}/api/templates/${uuid}/`
      : `${process.env.REACT_APP_SERVER_URL}/api/custom_forms/${uuid}/`;

    await axios
      .get(url)
      .then((res) => {
        console.log("---- res ---- ", res);
        setFormData(res.data);

        setFormName(res.data.title);
        setFormDescription(res.data.description);
        setIsFormPublic(res.data.is_public);
        setIsLoginRequired(res.data.login_required);

        setLoading(false);
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Could not get Form data.");
        setLoading(false);
      });
  };

  const updateFormData = async (setLoading: any) => {
    const url = props.state.auth.userData.is_staff
      ? `${process.env.REACT_APP_SERVER_URL}/api/templates/${uuid}/`
      : `${process.env.REACT_APP_SERVER_URL}/api/custom_forms/${uuid}/`;

    await axios
      .patch(url, {
        uuid: uuid,
        name: uuid,
        title: formName,
        description: formDescription,
        is_public: isFormPublic, // default is true
        login_required: isLoginRequired,
      })
      .then((res) => {
        console.log("---- res ---- ", res);
        setFormData(res.data);

        setFormName(res.data.title);
        setFormDescription(res.data.description);
        setIsFormPublic(res.data.is_public);
        setIsLoginRequired(res.data.login_required);
        message.success("Form updated successfully.");

        setLoading(false);
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Could not update Form.");
        setLoading(false);
      });
  };

  const handleSave = () => {
    updateFormData(setLoading);
  };

  useEffect(() => {
    getFormByUuid(setLoading);
  }, []);

  if (loading) {
    return <AntSpinner />;
  }

  return (
    <Card title="Visibility" style={{ margin: "10px" }}>
      <Form name="basic" layout="vertical">
        {/* <Form.Item
          help="Checking this will allow only registered users to fill the form"
          style={{ marginBottom: "10px" }}
        >
          <Checkbox
            value={isLoginRequired}
            onChange={(e) => setIsLoginRequired(e.target.checked)}
            defaultChecked={isLoginRequired}
          >
            Login required to fill the form
          </Checkbox>
        </Form.Item> */}
        <Form.Item
          help="Publishing the template will make it visible to all users"
          style={{ marginBottom: "10px" }}
        >
          <Checkbox
            value={isFormPublic}
            onChange={(e) => setIsFormPublic(e.target.checked)}
            defaultChecked={isFormPublic}
          >
            Publish the Template
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={handleSave}>
            Save Details
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(TemplateVisibility);
