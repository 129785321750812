import React, { useEffect, useState } from "react";

import { Form } from "antd";

import RecordVideo from "./RecordVideo";

const VideoUpload = (props: any) => {
  const [uploadedFileKey, setUploadedFileKey] = useState<any>(null);

  const [fileExtenstion, setFileExtenstion] = useState<any>(null);

  const [fileName, setFileName] = useState<any>(null);

  const [fileSize, setFileSize] = useState<any>(null);

  const [fileType, setFileType] = useState<any>(null);

  const { plugin_data, rules, extra } = props;

  const { name, required } = plugin_data;

  useEffect(() => {
    console.log("upload file key was changed to --->", uploadedFileKey);
    console.log(
      "other file details ---> ",
      fileName,
      fileSize,
      fileType,
      fileExtenstion
    );
  }, [fileExtenstion, fileName, fileSize, fileType, uploadedFileKey]);

  return (
    <>
      <Form.Item
        name={name}
        required={required}
        rules={rules}
        style={{ margin: "0px", padding: "0px" }}
        extra={extra}
      ></Form.Item>
      <RecordVideo
        uploadedFileKey={uploadedFileKey}
        setUploadedFileKey={setUploadedFileKey}
        setFileExtenstion={setFileExtenstion}
        setFileName={setFileName}
        setFileSize={setFileSize}
        setFileType={setFileType}
      />
    </>
  );
};

export default VideoUpload;
