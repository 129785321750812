import React, { useEffect, useState } from "react";

import { Form, Image } from "antd";

import RandomInput from "./RandomInput";

import RecordVideo from "../../containers/formSubmission/RecordVideo";

const VideoUpload = (props: any) => {
  const [fileExtenstion, setFileExtenstion] = useState<any>(null);

  const [fileSize, setFileSize] = useState<any>(null);

  const [fileType, setFileType] = useState<any>(null);

  const {
    plugin_data,
    rules,
    extra,
    uploadedFileKey,
    setUploadedFileKey,
    fileName,
    setFileName,
  } = props;

  const { name, required } = plugin_data;

  useEffect(() => {
    console.log("upload file key was changed to --->", uploadedFileKey);
    console.log(
      "other file details ---> ",
      fileName,
      fileSize,
      fileType,
      fileExtenstion
    );
  }, [fileExtenstion, fileName, fileSize, fileType, uploadedFileKey]);

  return (
    <>
      <Form.Item
        name={name}
        required={required}
        rules={rules}
        style={{ margin: "0px", padding: "0px" }}
        extra={extra}
      >
        <RandomInput
          uploadedFileKey={uploadedFileKey}
          fileExtenstion={fileExtenstion}
          setFileExtenstion={setFileExtenstion}
          fileName={fileName}
          setFileName={setFileName}
          fileType={fileType}
          setFileType={setFileType}
          fileSize={fileSize}
        />
      </Form.Item>

      <RecordVideo
        uploadedFileKey={uploadedFileKey}
        setUploadedFileKey={setUploadedFileKey}
        setFileExtenstion={setFileExtenstion}
        setFileName={setFileName}
        fileName={fileName}
        setFileSize={setFileSize}
        setFileType={setFileType}
      />
    </>
  );
};

export default VideoUpload;
