import React from "react";
import { Image, Radio, Checkbox } from "antd";
import axios from "axios";
import { FALLBACK_IMAGE } from "../../../shared/constants/constants";

interface IChoiceResponse {
  choice_text: any;
  imageKey?: string;
  imageUrl?: string;
}

// interface IProps {
//   // choice: IChoiceResponse;
//   elementType: string;
// }

const ChoiceResponseForMultipleCheckbox = ( choice:any, elementType:string ) => {
  const [imageUrl2, setimageUrl2] = React.useState<any>()
   React.useEffect(() => {
      const getS3FilePath = async (media_key: string) => {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/base/get-s3-url/?key=${media_key}`
        );
        setimageUrl2(result.data.url)
        // if (field.questionImageKey) {
        //   field.questionImageUrl = result.data.url;
        // } else {
        //   field.imageUrl = result.data.url;
        // }
      } catch (error) {
        console.log("errors could not be get", error);
      }
    }
      const promises: any = [];
      choice.choice.forEach((element: any) => {
        if (element.imageKey) {
          promises.push(getS3FilePath(element.imageKey));
        }
      })
      
  },[])
  console.log('choice', choice.choice.length,  choice.choice,elementType);
//    var _elementTypes: any

 return(
   
     choice.choice.map((item:any)=>{
      // getS3FilePath(item.imageKey)
       return(
         <><Checkbox checked>{item.choice_text}</Checkbox>
      <p
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        wordBreak: "break-word",
      }}
    >
      {/* {_elementTypes[elementType]} */}
      {Boolean(item.imageUrl) && (
        <Image
          src={imageUrl2}
          style={{ maxHeight: 200, maxWidth: 200 }}
          alt="Choice Image"
          fallback={FALLBACK_IMAGE}
        />
      )}
    </p>
    </> 
       )
     }
        // console.log('item',item.choice_text)
        
   )
 )
  
};

export default ChoiceResponseForMultipleCheckbox;
