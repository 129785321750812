import React, { useEffect, useState } from "react";

import { Button, message, Select } from "antd";
import axios from "axios";
import { useParams } from "react-router-dom";

const { Option } = Select;

const TagsToTemplate = (props: any) => {
  const { tags, initialTags } = props;

  const params = useParams<any>();

  const { uuid } = params;

  const [selectedTags, setSelectedTags] = useState<any>([]);

  useEffect(() => {
    setSelectedTags(initialTags);
  }, [props]);

  const handleChange = (value: any) => {
    setSelectedTags(value);
  };

  const handleSubmit = async () => {
    const finalData: any = [];

    await selectedTags.map((tag: any) => {
      const localData: any = tag;
      localData.tag = parseInt(tag.key);

      finalData.push(localData);
    });

    setTagsToTemplate(await finalData);
  };

  const setTagsToTemplate = async (dataToSet: any) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/templates/${uuid}/`;

    await axios
      .put(url, { tags: dataToSet })
      .then((res: any) => {
        console.log("---- res ---- ", res);
        message.success("Form details were updated.");
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Form details update failed.");
      });
  };

  return (
    <div>
      {selectedTags && initialTags && (
        <>
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select tags"
            defaultValue={initialTags}
            labelInValue
            onChange={handleChange}
          >
            {tags &&
              tags.length > 0 &&
              tags.map((tag: any) => (
                <Option
                  key={tag.id}
                  value={tag.id}
                  label={tag.name}
                  title={tag.name}
                >
                  {tag.name}
                </Option>
              ))}
          </Select>
          <Button
            onClick={() => handleSubmit()}
            type="primary"
            style={{ margin: "20px" }}
          >
            Save Details
          </Button>
        </>
      )}
    </div>
  );
};

export default TagsToTemplate;
