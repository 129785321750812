import React from "react";

import { Form, Input, message } from "antd";

import getBlobDuration from "get-blob-duration";

import { VIDEO_UPLOAD } from "../../../constants";

const FileUploadForVideo = (props: any) => {
  const { plugin_data, rules, extra } = props;

  const { name, required } = plugin_data;

  const onFileChange = async (e: any) => {
    console.log("file details --->", e.target.files[0]);

    if (e.target.files.length > 0) {
      if (
        e.target.files[0].type.match("video/mp4") ||
        e.target.files[0].type.match("video/quicktime") ||
        e.target.files[0].type.match("video/3gpp")
      ) {
        const duration = await getBlobDuration(e.target.files[0]);

        console.log((await duration) + " seconds");

        if (duration >= VIDEO_UPLOAD.MAX_DURATION_VIDEO_ALLOWED) {
          message.error("Max video duration allowed is 1 minute.");
        } else {
        }
      } else {
        message.error("This file type is not supported.");
      }
    } else {
      message.error("Please select a file to upload");
    }
  };

  return (
    <>
      <Form.Item
        name={name}
        required={required}
        rules={rules}
        style={{ margin: "0px", padding: "0px" }}
        extra={extra}
      ></Form.Item>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: "18px",
        }}
      >
        <Input
          type="file"
          onChange={(e) => onFileChange(e)}
          style={{ width: "220px" }}
        ></Input>
      </div>
      <p style={{ color: "red", fontSize: "10px" }}>
        Max video duration allowed is 1 minute. <br />
        Supported file extenstions include - mp4, quicktime (.mov), 3gp
      </p>
    </>
  );
};

export default FileUploadForVideo;
