import React, { useEffect, useState } from "react";

const CountDownTimer = (props: any) => {
  const { timer, setIsRecording, stopRecording, onComplete } = props;

  const [seconds, setSeconds] = useState(timer);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds: number) => seconds - 1);
    }, 1000);

    if (seconds < 0) {
      clearInterval(interval);
      onComplete();
      stopRecording();
      setIsRecording(false);
    }

    return () => clearInterval(interval);
  }, [seconds]);

  return <div>{seconds} seconds remaining</div>;
};

export default CountDownTimer;
