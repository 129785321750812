import React, { useEffect, useState } from "react";

import { Avatar, Button, Divider, Dropdown, Menu } from "antd";
import Title from "antd/lib/typography/Title";
import { UserOutlined } from "@ant-design/icons";

import { connect, useDispatch } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { Link } from "react-router-dom";

import { GetUserData, LogoutUser } from "../../../auth/core/redux/actions";
import THEMECOLORS from "../../../shared/theme/themeColors";
import AddFormModal from "../forms/addFormModal/AddFormModal";

import PrivacyPolicyBar from "./PrivacyPolicyBar";
import { StateType } from "../../core/redux/types";

const CTLogo = require("../../../shared/assets/images/ct-forms.svg").default;

const NavbarMobile = (props: any) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [userData, setUserData] = useState<any>();

  const [isAddFormModalOpen, setIsAddFormModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (props.state?.auth?.userData) {
      setUserData(props.state.auth.userData);
    } else {
      props.getUserData();
    }
  }, [props]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    console.log("Logout user was called");
    handleClose();
    dispatch(LogoutUser());
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <Link to="/account/settings">Settings</Link>
      </Menu.Item>
      <Menu.Item danger onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div
        style={{
          paddingLeft: "10px",
          paddingRight: "10px",
          paddingTop: "10px",
          display: "flex",
          flexDirection: "row",
          alignContent: "centre",
          justifyContent: "space-evenly",
          backgroundColor: "#f0f2f5",
        }}
      >
        <div
          style={{
            marginLeft: "1rem",
            color: "black",
            fontSize: "1rem",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Link to="/dashboard">
            <Title level={4} style={{ color: THEMECOLORS.fontColorPrimary }}>
              <img src={CTLogo} alt="logo" />
            </Title>
          </Link>
        </div>
        <div>
          <Button
            type="primary"
            style={{
              // width: "150px",
              // height: "30px",
              // textTransform: "none",
              marginLeft: "20px",
              marginRight: "20px",
            }}
            onClick={() => setIsAddFormModalOpen(true)}
          >
            {props.state.auth.userData?.is_staff
              ? "Add Template"
              : "Create Form"}
          </Button>
        </div>
        <div>
          <Dropdown overlay={menu}>
            {userData &&
            userData.profile_picture &&
            userData.profile_picture.thumbnail_small ? (
              <img
                src={userData.profile_picture.thumbnail_small}
                alt="profile"
                style={{
                  height: "40px",
                  width: "40px",
                  border: "1px solid gray",
                  padding: "2px",
                  borderRadius: "20px",
                }}
              />
            ) : (
              <Avatar
                style={{ marginLeft: "20px" }}
                icon={<UserOutlined style={{ fontSize: "20px" }} />}
              />
            )}
          </Dropdown>
        </div>
      </div>
      {userData && !userData?.is_verified && <PrivacyPolicyBar />}
      {isAddFormModalOpen && (
        <AddFormModal
          isModalOpen={isAddFormModalOpen}
          setModalOpen={setIsAddFormModalOpen}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getUserData: () => dispatch(GetUserData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavbarMobile);
