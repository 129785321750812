import { Table } from "antd";
import React from "react";

const TableForDesktop = (props: any) => {
  const { columns,bordered, dataSource: data, loading,expandable,rowClassName } = props;

  return (
    <>
      <div style={{ maxWidth: "80vw" }}>
        <Table
          rowClassName={rowClassName}
          showHeader={true}
          tableLayout="fixed"
          columns={columns}
          dataSource={data}
          bordered={bordered}
          pagination={false}
          expandable={expandable}
          loading={loading}
          size="middle" // changed from large to middle
          // sticky={true}
        />
      </div>
    </>
  );
};

export default TableForDesktop;
