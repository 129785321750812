import React, { useEffect } from "react";

import { connect } from "react-redux";

import { Route, Redirect } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

import { GetUserData } from "../../../auth/core/redux/actions";

const PrivateRoute = (props: any) => {
  const { component: Component, ...rest } = props;

  // const { isAuthenticated } = props.state.auth;
  // TODO: Why is this not working?

  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    async function fetchData() {
      // You can await here
      await props.getUserData();
      // ...
    }
    fetchData();
  }, []);

  return (
    <>
      {" "}
      {props.state && props.state.auth && props.state.auth.userData && (
        <Route
          {...rest}
          render={(props) =>
            accessToken ? <Component {...props} /> : <Redirect to="/login" />
          }
        />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getUserData: () => dispatch(GetUserData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
