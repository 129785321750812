import React from "react";
import { Dropdown, Menu, Button } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import { IElement } from "../utils/interfaces";
import { connect, ConnectedProps } from "react-redux";
import PLUGIN_DATA_JSON from "../constants/plugin_data.json";
import { CTComponents } from "./CTComponents";

const PLUGIN_DATA: { [key: string]: object } = { ...PLUGIN_DATA_JSON };

interface IProps extends PropsFromRedux {
  elementTypeLabels: string[];
  element: IElement;
  componentDisabled: boolean;
  updateElement: (element: IElement) => void;
}

function ElementTypeDropdown(props: IProps) {
  const { dispatch } = props;

  const getPluginData = (elementTypeLabel: string) => {
    const groupedElements = ["Dropdown", "Radio", "Checkbox"];

    const pluginData: { [key: string]: any } = {
      name: props.element.uuid,
      label: props.element.plugin_data?.label,
      required: props.element.plugin_data?.required,
      help_text: props.element.plugin_data?.help_text,
    };
    if (groupedElements.includes(elementTypeLabel)) {
      pluginData["choices"] = props.element.plugin_data?.choices || [
        { choice_text: "choice1" },
      ];
    }

    return pluginData;
  };
  const menu = (
    <Menu>
      {props.elementTypeLabels.map(
        (elementTypeLabel: string, index: number) => {
          const plugin_uid = CTComponents[elementTypeLabel].plugin_uid;
          const data = {
            id: props.element.id || null,
            plugin_uid,
            uuid: props.element.uuid,
            label: elementTypeLabel,
            position: props.element.position,
            plugin_data: {
              ...PLUGIN_DATA[plugin_uid],
              ...getPluginData(elementTypeLabel),
            },
            questionImageKey: props.element.questionImageKey,
            questionImageUrl: props.element.questionImageUrl,
            questionImageSize: props.element.questionImageSize,
            metadata: {
              ...props.element.metadata,
              elementType: elementTypeLabel,
            },
          };
          return (
            <Menu.Item
              onClick={() => {
                dispatch({
                  type: "CHANGE_SELECTED_TYPE",
                  payload: data,
                });
                props.updateElement(data as IElement);
              }}
              key={index}
            >
              {elementTypeLabel}
            </Menu.Item>
          );
        }
      )}
    </Menu>
  );
  return (
    <Dropdown
      overlay={menu}
      placement="bottomLeft"
      trigger={["click"]}
      disabled={props.componentDisabled}
    >
      <Button className="ct_forms_secondary_button">
        <CaretDownOutlined />
        {props.element.label}
      </Button>
    </Dropdown>
  );
}

interface IAction {
  type: string;
  payload: any;
}

const mapDispatch = {
  dispatch: (action: IAction) => action,
};

export const connector = connect(null, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ElementTypeDropdown);
