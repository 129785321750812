import React from "react";
import { Select } from "antd";
import Choice from "./Choice";
import { IElement } from "../../utils/interfaces";

interface IProps {
  componentDisabled?: boolean;
  updateFieldValue: (choices: object) => void;
  handleImageDelete: (imageKey: string) => number;
  handleImageUpload: (element: IElement) => IElement;
  updateElement: (element: IElement) => void;
  state: IElement;
  deleteButton: React.ReactNode;
  extraProps: React.ReactNode;
}

export default function CTSelect(props: IProps) {
  return (
    <>
      <Choice disabled={props.componentDisabled} {...props} noImage={true}>
        <Select open={false} disabled style={{ marginBottom: 5 }} />
      </Choice>
    </>
  );
}
