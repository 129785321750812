import React, { useState } from "react";
import { Form, Drawer, Button, Typography, Card } from "antd";

import { IElement } from "../../../utils/interfaces";
import elementsToReturn from "./elementsToReturn";

interface IProps {
  state: IElement;
  componentDisabled?: boolean;
  deleteButton: React.ReactNode;
  advancedOptions: React.ReactNode;
  extraProps: React.ReactNode;
}

function AdvanceOptionsDrawer(props: IProps) {
  const [showOptions, setShowOptions] = useState(false);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Card
        size="small"
        style={{ marginTop: "10px", padding: 10, borderRadius: "24px" }}
      >
        <Form style={{ textAlign: "left" }}>
          {elementsToReturn(props.state)}
        </Form>
      </Card>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexFlow: "row wrap",
        }}
      >
        <Button
          style={{
            fontSize: 16,
            fontWeight: "bold",
            padding: 0,
            paddingLeft: 10,
          }}
          type="link"
          onClick={() => setShowOptions(!showOptions)}
          disabled={props.componentDisabled}
        >
          Advanced Options
        </Button>
        <div>{props.deleteButton}</div>
      </div>
      <Drawer
        title={
          <span
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: 24,
              color: "#FF6E30",
              borderBottom: "none",
            }}
          >
            {props.state.metadata.elementType}
          </span>
        }
        placement="left"
        onClose={() => setShowOptions(false)}
        visible={showOptions}
        drawerStyle={{
          background: "#2C353D",
        }}
        headerStyle={{
          background: "#2C353D",
        }}
      >
        <div>
          <Typography.Paragraph
            style={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "bolder",
              fontSize: "12px",
              lineHeight: "16px",
              textAlign: "left",
              letterSpacing: "0.1em",
              textTransform: "uppercase",
              color: "#748FB5",
            }}
          >
            Properties
          </Typography.Paragraph>
          {props.extraProps}
          {props.advancedOptions}
        </div>
      </Drawer>
    </div>
  );
}

export default AdvanceOptionsDrawer;
