import React from "react";
import "react-phone-input-2/lib/style.css";
import { IElement } from "../../utils/interfaces";
import AdvanceOptionsDrawer from "./ElementPreview/AdvanceOptionsDrawer";

interface IProps {
  state: IElement;
  componentDisabled?: boolean;
  deleteButton: React.ReactNode;
  extraProps: React.ReactNode;
}

function CTPhone(props: IProps) {
  return (
    <AdvanceOptionsDrawer
      componentDisabled={props.componentDisabled}
      deleteButton={props.deleteButton}
      advancedOptions={<></>}
      state={props.state}
      extraProps={props.extraProps}
    />
  );
}

export default CTPhone;
