import { Dispatch } from "redux";
import { LoginDispatchTypes, Types } from "./types";

import axios from "axios";

import { message } from "antd";
import { getClientDeviceDetails, getClientIP } from "../services/clientInfo";

import { CONSENT_TYPES } from "../../../shared/constants/constants";

const {
  LOGIN_USER,
  LOGOUT_USER,
  RESET_PASSWORD,
  SET_ACCESS_TOKEN,
  FORGOT_PASSWORD,
  CLEAR_STATE,
  ACCEPT_POLICY_SUCCESS,
  SET_AUTHENTICATED,
  SET_USERDATA,
  SET_LOGIN_ERROR,
  SET_REGISTER_ERROR,
  REGISTER_SUCCESS,
} = Types;

export const LoginUser =
  (username: string, password: string, rememberMe: boolean) =>
  async (dispatch: Dispatch<LoginDispatchTypes>) => {
    try {
      dispatch({
        type: LOGIN_USER,
        payload: {
          username,
          password,
          rememberMe,
        },
      });
      await axios
        .post(`${process.env.REACT_APP_SERVER_URL}/auth/login/`, {
          email: username,
          password,
        })
        .then(async (response) => {
          const { access_token: accessToken, refresh_token: refreshToken } =
            response.data;
        
          await axios
            .get(`${process.env.REACT_APP_SERVER_URL}/auth/user/`, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            })
            .then((response: any) => {
              const userData = response.data;

              dispatch({
                type: SET_USERDATA,
                payload: {
                  userData,
                },
              });

              // dispatch({
              //   type: SET_ADMIN_MODE,
              //   payload: {
              //     admin: userData.is_staff
              //   }
              // })
            })
            .catch((err) => console.log("--- erro", err));

          dispatch({
            type: SET_AUTHENTICATED,
            payload: {
              isAuthenticated: true,
              accessToken,
              refreshToken,
              rememberMe,
            },
          });
        })
        .catch((err) => {
          dispatch({
            type: SET_LOGIN_ERROR,
            payload: {
              error: "Invalid email or password",
            },
          });
        });
    } catch (e) {}
  };

export const LogoutUser =
  () => async (dispatch: Dispatch<LoginDispatchTypes>) => {
    try {
      dispatch({
        type: LOGOUT_USER,
      });
      dispatch({
        type: SET_AUTHENTICATED,
        payload: {
          isAuthenticated: false,
        },
      });
    } catch (e) {}
  };

export const SetAuthenticated =
  (isAuthenticated: boolean) =>
  async (dispatch: Dispatch<LoginDispatchTypes>) => {
    try {
      dispatch({
        type: SET_AUTHENTICATED,
        payload: {
          isAuthenticated,
        },
      });
    } catch (e) {}
  };

export const GetNewToken =
  (refreshToken: string, setShouldReload: any) =>
  async (dispatch: Dispatch<LoginDispatchTypes>) => {
    console.log("==== get new token api was called ====", refreshToken);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // let history = useHistory();x
    await axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/v1/login/refresh/`, {
        refresh: refreshToken,
      })
      .then((response: any) => {
        const { access: accessToken } = response.data;
        dispatch({
          type: SET_ACCESS_TOKEN,
          payload: {
            accessToken,
          },
        });
        message.info("Login info updated.");
        window.location.href = "/dashboard";
        // history.push("/dashboard");
        setShouldReload(true);
        console.log("==== got new data ===");
      })
      .catch((err) => {
        localStorage.clear();

        window.location.href = "/login";
        message.info("Logging out");
        console.log("refresh token api failed");
      });
  };

export const ForgotPassword =
  (email: string) => async (dispatch: Dispatch<LoginDispatchTypes>) => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/forgot-password/`, {
        email,
      })
      .then((response: any) => {
        dispatch({
          type: FORGOT_PASSWORD,
          payload: {
            success: true,
            message: "Password reset link sent. Check your email.",
          },
        });
        message.success("Password reset link sent. Check your email.");
      })
      .catch((err) => {
        dispatch({
          type: FORGOT_PASSWORD,
          payload: {
            success: false,
            message: "Password reset failed. Please retry.",
          },
        });
        message.error("Password reset failed. Please retry.");
      });
  };

export const ClearState =
  () => async (dispatch: Dispatch<LoginDispatchTypes>) => {
    dispatch({
      type: CLEAR_STATE,
      payload: {},
    });
  };

export const ResetPassword =
  (token: string, password: string) =>
  async (dispatch: Dispatch<LoginDispatchTypes>) => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/reset-password/`, {
        token,
        password,
      })
      .then((response: any) => {
        if (response.data.status === "success") {
          dispatch({
            type: RESET_PASSWORD,
            payload: {
              success: true,
              message: "Password successfully reset.",
            },
          });
        } else {
          dispatch({
            type: RESET_PASSWORD,
            payload: {
              success: false,
              message: "Some error occurred. Please retry ",
            },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: RESET_PASSWORD,
          payload: {
            success: false,
            message: "Some error occurred. Please retry ",
          },
        });
      });
  };

export const AcceptPrivacyPolicy =
  (policyAccept: boolean) => async (dispatch: Dispatch<LoginDispatchTypes>) => {
    if (policyAccept === true) {
      dispatch({
        type: ACCEPT_POLICY_SUCCESS,
        payload: {
          policyAccept: true,
        },
      });
    }
  };

interface RegisterData {
  email: string;
  password1: string;
  password2: string;
  privacy_policy_accepted: boolean;
  terms_and_conditions_accepted: boolean;
}

export const RegisterUser =
  (registerData: RegisterData, setLoading: any) =>
  async (dispatch: Dispatch<LoginDispatchTypes>) => {
    let consentProofs: { data: any };

    let consentDataToSent: any;

    const getClientDetailsAndConsentData = async () => {
      const clientDetails = getClientDeviceDetails();

      // TODO: client IP is not getting submitted
      const ipAddress = await getClientIP();

      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}​/api/users/consent/proofs/`)
        .then(async (response: any) => {
          consentProofs = await response.data.data.map((response: any) => {
            response.version = response.latest_version;
            return response;
          });

          console.log("consent proofs -----> ", consentProofs);

          consentDataToSent = {
            ip_address: await ipAddress,
            consent_type: CONSENT_TYPES.REGISTRATION,
            proofs: consentProofs,
            ...clientDetails,
          };
        })
        .catch((err) => console.log("--- erro", err));
    };

    const submitConsent = async () => {
      await axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}​/api/users/consent/`,
          consentDataToSent
        )
        .then((response: any) => {
          console.log(
            "----> Response from submit consent  ----> ",
            response.data
          );
          consentProofs = response.data;
        })
        .catch((err) => console.log("--- erro", err));
    };

    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/auth/registration/`,
        registerData
      )
      .then(async (response) => {
        console.log("response of axios", response.data);

        const { access_token: accessToken, refresh_token: refreshToken } =
          response.data;

        dispatch({
          type: REGISTER_SUCCESS,
          payload: {
            accessToken,
            refreshToken,
            success: "Registeration success",
          },
        });

        message.success("Registeration successful.");

        dispatch({
          type: SET_AUTHENTICATED,
          payload: {
            isAuthenticated: true,
            accessToken,
            refreshToken,
          },
        });

        setLoading(false);

        getClientDetailsAndConsentData().then(() => {
          submitConsent();
        });
      })
      .catch((err: any) => {
        console.log("error in axios API  -> ", err.response.data);

        const errorsList: any[] = [];

        for (const [key, value] of Object.entries(err.response.data)) {
          console.log(`${key}: ${value}`);
          message.error(`${value}`);
          errorsList.push(value);
        }

        // TODO: OTP error message change
        dispatch({
          type: SET_REGISTER_ERROR,
          payload: {
            error: errorsList,
          },
        });

        setLoading(false);
      });
  };

export const GetUserData =
  () => async (dispatch: Dispatch<LoginDispatchTypes>) => {
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/auth/user/`, {})
      .then((response: any) => {
        const userData = response.data;

        dispatch({
          type: SET_USERDATA,
          payload: {
            userData,
          },
        });
      })
      .catch((err) => console.log("--- erro", err));
  };
