import React, { useEffect, useState } from "react";

import { Button, Image, Modal } from "antd";

import {
  FileOutlined,
  FileImageOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";

import axios from "axios";

import PdfFilePreview from "../drive/PdfFilePreview";

const FileUploadTypeResponse = (props: any) => {
  console.log("===== props here --", props.fileData);

  const { fileData } = props;

  const { key, contentType, fileSize } = props.fileData;

  const [activeAWSURL, setActiveAWSURL] = useState<any>();

  const [activeFile, setActiveFile] = useState<any>();

  const resolveAWSFilePath = async (key: string) => {
    console.log("file key recieved --->", key);
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/api/base/get-s3-url/?key=${key}`
      )
      .then(async (result1) => {
        console.log("image URL", result1.data.url);

        setActiveAWSURL(result1.data.url);
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
      });
  };

  useEffect(() => {
    if (props.fileData) {
      resolveAWSFilePath(key);
    }
  }, [key]);

  const fileIconToReturn = () => {
    switch (contentType) {
      case "image/png":
      case "image/jpg":
      case "image/jpeg":
      case "image/ico":
      case "image/gif":
        return <FileImageOutlined style={{ paddingRight: "2px" }} />;
      case "application/pdf":
        return <FilePdfOutlined style={{ paddingRight: "2px" }} />;
      default:
        return <FileOutlined style={{ paddingRight: "2px" }} />;
    }
  };

  const fileSizeInMB =
    fileSize && !isNaN(fileSize) ? fileSize / 1024 / 1024 : 0;

  const fileSizeInKB = fileSize && !isNaN(fileSize) ? fileSize / 1024 : 0;

  const fileSizeToReturn =
    fileSizeInMB >= 1 ? (
      <>{fileSizeInMB.toFixed(2)} MB</>
    ) : (
      <>{fileSizeInKB.toFixed(2)} KB</>
    );

  const fileDetails = (
    <p style={{ fontSize: "10px" }}>
      {" "}
      File Type: {fileData.contentType}, File Size: {fileSizeToReturn} <br />
    </p>
  );

  const fileNameAndDownload = (
    <p style={{ margin: "0px" }}>
      <a href={activeAWSURL} target="_blank" rel="noreferrer">
        {fileIconToReturn()}
        {fileData.fileName}{" "}
      </a>
    </p>
  );

  const addingSupportTag = (
    <span
      style={{
        background: "lightgrey",
        fontSize: "12px",
        fontWeight: "lighter",
      }}
    >
      We are still adding in-site support for this file type. Till then you can
      <a
        href={activeAWSURL}
        target="_blank"
        rel="noreferrer"
        style={{ marginLeft: "0.5px", marginRight: "0.5px" }}
      >
        {" "}
        download{" "}
      </a>
      the file.{" "}
    </span>
  );

  const fileRender = () => {
    switch (contentType) {
      case "image/png":
      case "image/jpg":
      case "image/jpeg":
      case "image/ico":
      case "image/gif":
        return (
          <div style={{ wordBreak: "break-word" }}>
            {fileNameAndDownload}
            {fileDetails}

            <br />
            <Image src={activeAWSURL} alt="file" />
          </div>
        );
      case "application/pdf":
        return (
          <div style={{ wordBreak: "break-word" }}>
            {fileNameAndDownload}
            {fileDetails}
            <Button onClick={() => setIsModalVisible(true)}>
              Preview file
            </Button>
            <a
              href={activeAWSURL}
              target="_blank"
              rel="noreferrer"
              style={{ marginLeft: "5px" }}
            >
              <Button>Download File</Button>
            </a>
          </div>
        );
      case "video/mp4":
      case "video/quicktime":
      case "video/3gpp":
        return (
          <div
            style={{ maxWidth: "400px", maxHeight: "400px", overflow: "auto" }}
          >
            <video src={activeAWSURL} controls loop width="320" height="240" />
          </div>
        );
      default:
        return (
          <div style={{ wordBreak: "break-word" }}>
            {fileNameAndDownload}
            {fileDetails}
            {addingSupportTag}
          </div>
        );
    }
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {activeAWSURL && fileRender()}{" "}
      <Modal
        title="File Preview"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        style={{
          width: "100%",
          height: "100%",
          maxWidth: "100vw",
          maxHeight: "70vh",
          backgroundColor: "transparent",
        }}
      >
        <PdfFilePreview fileSrc={activeAWSURL} />
      </Modal>
    </>
  );
};

export default FileUploadTypeResponse;
