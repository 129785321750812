import React from "react";

import { Image } from "antd";

import { FALLBACK_IMAGE } from "../../../shared/constants/constants";

import PdfFilePreview from "./PdfFilePreview";

const FilePreview = (props: any) => {
  const { activeAWSURL, activeFile } = props;
  const contentType=activeFile.content_type?activeFile.content_type:activeFile.contentType
  const returnFilePreview = () => {
    switch (contentType) {
      case "image/png":
         return (
          <Image
            src={activeAWSURL}
            alt=" File Preview"
            fallback={FALLBACK_IMAGE}
            style={{
              height: "100%",
              width: "100%",
              overflow: "none",
            }}
          />
        );
      case "image/jpg":
      case "image/jpeg":
      case "image/ico":
      case "image/gif":
        return (
          <Image
            src={activeAWSURL}
            alt=" File Preview"
            fallback={FALLBACK_IMAGE}
            style={{
              height: "100%",
              width: "100%",
              overflow: "none",
            }}
          />
        );

      case "application/pdf":
        return <PdfFilePreview fileSrc={activeAWSURL} />;

      case "video/mp4":
      case "video/quicktime":
      case "video/3gpp":
        return (
          <div
            style={{ maxWidth: "400px", maxHeight: "400px", overflow: "auto" }}
          >
            <video src={activeAWSURL} controls loop width="320" height="240" />
          </div>
        );

      default:
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <p>File Preview is not available</p>
          </div>
        );
    }
  };

  return <div>{returnFilePreview()}</div>;
};

export default FilePreview;
