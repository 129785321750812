import React from "react";

import { IElement } from "../../utils/interfaces";
import AdvanceOptionsDrawer from "./ElementPreview/AdvanceOptionsDrawer";

interface IProps {
  state: IElement;
  componentDisabled?: boolean;
  deleteButton: React.ReactNode;
  extraProps: React.ReactNode;
}

export default function CTUpload(props: IProps) {
  if (!props.state.plugin_data) return null;

  return (
    <>
      <AdvanceOptionsDrawer
        componentDisabled={props.componentDisabled}
        deleteButton={props.deleteButton}
        advancedOptions={<></>}
        state={props.state}
        extraProps={props.extraProps}
      />
    </>
  );
}
