import React from "react";

import { Pie } from "@ant-design/charts";

interface Iprops {
  usedData: any;
  totalData: any;
}

const DataUsage = (props: Iprops) => {
  const { usedData, totalData } = props;

  var data = [
    {
      type: "used",
      value: usedData,
    },
    {
      type: "remaining",
      value: totalData.toFixed(2) - usedData.toFixed(2),
    },
  ];

  var config = {
    appendPadding: 10,
    data: data,
    angleField: "value",
    colorField: "type",
    radius: 0.9,
    label: {
      type: "inner",
      offset: "-30%",
      content: function content(_ref: any) {
        var percent = _ref.percent;
        return "".concat((percent * 100).toFixed(0), "%");
      },
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [{ type: "element-active" }],
  };

  const styleForMobile = {
    width: "250px",
    height: "250px",
  };

  const styleForWeb = {
    height: "300px",
    display: "flex",
    justifyContent: "center",
  };

  return (
    <div>
      <div style={window.innerWidth > 500 ? styleForWeb : styleForMobile}>
        <Pie {...config} autoFit />
      </div>
      <p>
        {" "}
        used <b> {usedData.toFixed(2)} Mb , </b> remaining{" "}
        <b> {totalData.toFixed(2) - usedData.toFixed(2)} Mb </b>
      </p>
      <p>
        out of total <b>{totalData} Mb</b>
      </p>
    </div>
  );
};

export default DataUsage;
