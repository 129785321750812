import { createStore, combineReducers } from "redux";
import { formDataReducer } from "./reducers";

const rootReducer = combineReducers({ form: formDataReducer });

export const store = createStore(rootReducer);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {form: FormState}
export type AppDispatch = typeof store.dispatch;
