import React from "react";
import { Button, Radio } from "antd";
import Choice from "./Choice";
import { IElement } from "../../utils/interfaces";

interface IProps {
  componentDisabled?: boolean;
  updateFieldValue: (choices: object) => void;
  handleImageDelete: (imageKey: string) => number;
  handleImageUpload: (element: IElement) => IElement;
  updateElement: (element: IElement) => void;
  state: IElement;
  deleteButton: React.ReactNode;
  extraProps: React.ReactNode;
}

export default function CTRadio(props: IProps) {
  return (
    <>
      <Choice disabled={props.componentDisabled} {...props}>
        <Radio checked={false} disabled style={{ margin: "0px" }} />
      </Choice>
    </>
  );
}
