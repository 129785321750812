import React, { useState } from "react";

import { Button, Form, Input, message, Popconfirm } from "antd";
import axios from "axios";

import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const TagsCRUD = (props: any) => {
  const [newTag, setNewTag] = useState<string>("");

  const { tags, setShouldReload } = props;

  const [mode, setMode] = useState<"ADD" | "EDIT">("ADD");

  const [tagToEdit, setTagToEdit] = useState<any>(null);

  const handleAdd = async () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/base/tags/`;

    await axios
      .post(url, {
        is_active: true,
        name: newTag,
      })
      .then((result) => {
        console.log("result ---> ", result.data.data);
        message.success("Tag was added.");
        setNewTag("");
        setShouldReload(true);
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Tag could not be added.");
      });
  };

  const handleEdit = async () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/base/tags/${tagToEdit.id}/`;

    await axios
      .put(url, {
        is_active: true,
        name: newTag,
      })
      .then((result) => {
        console.log("result ---> ", result.data.data);
        message.success("Tag was added.");
        setNewTag("");
        setTagToEdit(null);
        setMode("ADD");
        setShouldReload(true);
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Tag could not be added.");
      });
  };

  const confirmDelete = async (tag: any) => {
    console.log("--- data to delete -- ", tag);

    const url = `${process.env.REACT_APP_SERVER_URL}/api/base/tags/${tag.id}/`;

    await axios
      .delete(url)
      .then((result) => {
        console.log("result ---> ", result.data.data);
        message.success("Tag was deleted.");
        setShouldReload(true);
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Tag could not be deleted.");
      });
  };

  return (
    <>
      {tags &&
        tags.length > 0 &&
        tags.map((tag: any) => (
          <>
            <Button style={{ backgroundColor: "whitesmoke", margin: "10px" }}>
              {tag.name}
              <EditOutlined
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  setMode("EDIT");
                  setNewTag(tag.name);
                  setTagToEdit(tag);
                }}
              />
              <Popconfirm
                title="Are you sure to delete this Tag?"
                onConfirm={() => confirmDelete(tag)}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined style={{ marginLeft: "10px" }} />
              </Popconfirm>
            </Button>
          </>
        ))}
      <Form style={{ marginTop: "10px" }}>
        <Form.Item>
          <Input
            name="tag"
            value={newTag}
            onChange={(e) => {
              setNewTag(e.target.value);
            }}
            maxLength={20}
          />
          <label>Tag Name</label>
        </Form.Item>
        <Form.Item>
          {mode === "ADD" && <Button onClick={() => handleAdd()}>Add</Button>}
          {mode === "EDIT" && (
            <Button onClick={(tag) => handleEdit()}>Save</Button>
          )}
        </Form.Item>
      </Form>
    </>
  );
};

export default TagsCRUD;
