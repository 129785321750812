import React, { useState } from "react";


import RecordView from "./RecordView";

const RecordVideo = (props) => {

  const {
    setUploadedFileKey,
    setFileExtenstion,
    setFileName,
    setFileSize,
    setFileType,
  } = props;

  const [fileUploading, setFileUploading] = useState(false);

  return (
    <>
      <RecordView
        setFinalAwsKey={setUploadedFileKey}
        setUploadedFileKey={setUploadedFileKey}
        setFileExtenstion={setFileExtenstion}
        setFileName={setFileName}
        setFileSize={setFileSize}
        setFileType={setFileType}
        fileUploading={fileUploading}
        setFileUploading={setFileUploading}
      />
    </>
  );
};

export default RecordVideo;
