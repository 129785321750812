import React from "react";
import { useRef, useEffect, useState } from "react";

import { ReactMediaRecorder } from "react-media-recorder";
import { Button, Card, Spin } from "antd";

import CountDownTimer from "./CountDownTimer";
import { VIDEO_UPLOAD } from "../../../shared/constants";

const VideoPreview = (props) => {
  const { stream } = props;

  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef && videoRef.current && videoRef.current !== null && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);
  if (!stream) {
    return null;
  }
  return <video ref={videoRef} width={500} height={500} autoPlay />;
};

const RecordView = (props) => {
  console.log("props in record view ----> ", props);

  const {
    setFinalAwsKey,
    setFileExtenstion,
    setFileName,
    setFileSize,
    setFileType,
    onFileUpload,
    resolveAWSFilePathForDownload,
    fileUploading,
    setFileUploading,
    handleFileDelete,
  } = props;

  const [awsMediaKey, setAwsMediaKey] = useState(null);

  const [isRecording, setIsRecording] = useState(false);

  const [metadata, setMetadata] = useState(null);

  useEffect(() => {
    setFinalAwsKey(awsMediaKey);
  }, [awsMediaKey, setAwsMediaKey, setFinalAwsKey]);

  const handleClearRecording = (clearBlobUrl) => {
    clearBlobUrl();
    setMetadata(null);
    setAwsMediaKey(null);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <ReactMediaRecorder
        video
        render={({
          status,
          startRecording,
          stopRecording,
          clearBlobUrl,
          mediaBlobUrl,
          previewStream,
        }) => (
          <Card
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              border: "none",
              padding: "0px",
              margin: "0px",
            }}
          >
            <p style={{ width: "100%" }}>
              <b>Recording status</b>: {status}
            </p>

            <div style={{ padding: "2px" }}>
              <Button
                onClick={() => {
                  startRecording().then(() => {
                    setIsRecording(true);
                  });
                }}
                style={{ margin: "2px" }}
                disabled={isRecording || mediaBlobUrl}
              >
                Start Recording
              </Button>

              <Button
                onClick={() => {
                  stopRecording();
                  setIsRecording(false);
                }}
                style={{ margin: "2px" }}
                disabled={!isRecording}
              >
                Stop Recording
              </Button>
            </div>

            <div style={{ padding: "2px", paddingBottom: "5px" }}>
              {isRecording && (
                <CountDownTimer
                  timer={VIDEO_UPLOAD.MAX_DURATION_VIDEO_ALLOWED}
                  setIsRecording={setIsRecording}
                  stopRecording={stopRecording}
                  onComplete={() => {
                    stopRecording();
                  }}
                />
              )}

              {mediaBlobUrl && (
                <Button
                  onClick={() => handleClearRecording(clearBlobUrl)}
                  style={{ margin: "2px" }}
                >
                  Clear Recording
                </Button>
              )}
            </div>

            <div>
              {" "}
              {mediaBlobUrl && (
                <video
                  src={mediaBlobUrl}
                  controls
                  autoPlay
                  loop
                  width="320"
                  height="240"
                />
              )}
              {isRecording && (
                <div
                  style={{ maxWidth: "80vw", width: "100%", overflowX: "none" }}
                >
                  <VideoPreview
                    stream={previewStream}
                    style={{ margin: "0px", padding: "0px" }}
                  />
                </div>
              )}
            </div>

            <div style={{ padding: "10px" }}>
              {awsMediaKey ? (
                <Button
                  onClick={() => {
                    handleFileDelete(awsMediaKey, () =>
                      handleClearRecording(clearBlobUrl)
                    );
                  }}
                >
                  Remove
                </Button>
              ) : (
                mediaBlobUrl && (
                  <>
                    {" "}
                    <Button
                      onClick={() => {
                        if (mediaBlobUrl) {
                          onFileUpload(
                            mediaBlobUrl,
                            setAwsMediaKey,
                            setFileExtenstion,
                            setFileName,
                            setFileSize,
                            setFileType,
                            setFileUploading
                          );
                        } else {
                          alert("no recording found");
                        }
                      }}
                      style={{ margin: "2px" }}
                      disabled={fileUploading || !mediaBlobUrl}
                    >
                      Upload recorded Video{" "}
                    </Button>{" "}
                    {fileUploading && <Spin />}
                  </>
                )
              )}

              {awsMediaKey && !isRecording && (
                <Button
                  onClick={() => {
                    resolveAWSFilePathForDownload(awsMediaKey);
                  }}
                  style={{ margin: "2px" }}
                >
                  Download recording
                </Button>
              )}
            </div>
          </Card>
        )}
      />
    </div>
  );
};
export default RecordView;
