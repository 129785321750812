import React, { useEffect, useState } from "react";

import { Checkbox } from "antd";
import { CT_FORM_TERMS } from "../../../shared/constants/constants";

const FormPrivacyPolicy = (props: any) => {
  const { addProofs, setHasReadPolicy, oriProofs } = props;

  const [mainPolicy, setMainPolicy] = useState<boolean>(false);

  const [policy1, setPolicy1] = useState<boolean>(false);

  useEffect(() => {
    if (oriProofs.length === 0) {
      if (mainPolicy) {
        setHasReadPolicy(true);
      } else {
        setHasReadPolicy(false);
      }
    } else if (oriProofs.length >= 1 && !addProofs) {
      if (mainPolicy) {
        setHasReadPolicy(true);
      } else {
        setHasReadPolicy(false);
      }
    } else if (oriProofs.length >= 1 && addProofs) {
      if (mainPolicy && policy1) {
        setHasReadPolicy(true);
      } else {
        setHasReadPolicy(false);
      }
    }
  }, [mainPolicy, oriProofs, policy1, setHasReadPolicy]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Checkbox
        checked={mainPolicy}
        onChange={(e) => {
          setMainPolicy(e.target.checked);
        }}
        defaultChecked={false}
      >
        I have read the{" "}
        <a
          href={CT_FORM_TERMS.TERMS_AND_CONDITIONS}
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Use{" "}
        </a>{" "}
        and{" "}
        <a
          href={CT_FORM_TERMS.PRIVACY_POLICY}
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Privacy Policy
        </a>
      </Checkbox>

      {addProofs && oriProofs && oriProofs.length > 0 && (
        <>
          {" "}
          <Checkbox
            checked={policy1}
            onChange={(e) => {
              setPolicy1(e.target.checked);
            }}
            defaultChecked={false}
          >
            I have read and accepted{" "}
            <a
              href={oriProofs[0].url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {oriProofs[0].name} {"  "}
            </a>
            {"  "}
            {oriProofs.length > 1 && (
              <span>
                and {"  "}
                <a
                  href={oriProofs[1].url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  {oriProofs[1].name}
                </a>{" "}
              </span>
            )}
          </Checkbox>
        </>
      )}
    </div>
  );
};

export default FormPrivacyPolicy;
