import React from "react";

import { Button, Divider } from "antd";
import TemplateElementsPreview from "./TemplateElementsPreview";

const PreviewTemplate = (props: any) => {
  const { templateToPreview, setTemplateToPreview, handleUseTemplate } = props;

  return (
    <>
      <Button
        type="text"
        onClick={() => {
          setTemplateToPreview(null);
          console.log("button was clicked");
        }}
      >
        Back to templates
      </Button>{" "}
      <Divider />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <h1> {templateToPreview.title} </h1>
        <Button
          type="primary"
          onClick={() => {
            handleUseTemplate(templateToPreview.uuid);
          }}
        >
          Use Template
        </Button>
      </div>
      <Divider />
      <TemplateElementsPreview template={templateToPreview} />
    </>
  );
};

export default PreviewTemplate;
