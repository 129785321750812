import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  Col,
  Layout,
  message,
  Row,
  Statistic,
  Typography,
} from "antd";

import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { Redirect } from "react-router-dom";
import axios from "axios";

import { GetUserData } from "../../../auth/core/redux/actions";
import { StateType } from "../../core/redux/types";

import AdminDashboard from "./AdminDashboard";
import UserDashboard from "./UserDashboard";

const Dashboard = (props: any) => {
  const [userData, setUserData] = useState<any>();

  useEffect(() => {
    if (props.state?.auth?.userData) {
      console.log("userdata ---> ", props.state.auth.userData);
      setUserData(props.state.auth.userData);
    } else {
      props.getUserData();
    }
  }, [props]);

  const [redirectToForm, setRedirectToForm] = useState(false);
  const [formPath, setFormPath] = useState("");

  const [loading, setLoading] = useState<boolean>(false);

  const [formStats, setFormsStats] = useState<any>(null);

  const checkIfRedirect = () => {
    const pathToRedirect = localStorage.getItem("redirect-path");
    if (pathToRedirect) {
      setFormPath(pathToRedirect);
      localStorage.removeItem("redirect-path");
      setRedirectToForm(true);
    }
  };

  useEffect(() => {
    checkIfRedirect();
  }, []);

  return (
    <>
      {userData && userData.is_staff ? <AdminDashboard /> : <UserDashboard />}
    </>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getUserData: () => dispatch(GetUserData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
