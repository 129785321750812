import { Table } from "antd";
import React, { useEffect } from "react";

const TableForDesktop = (props: any) => {
  const { columns, dataSource: data, setCurrentPageNo, currentPageNo,rowSelectionTable,rowClassName } = props;
  const isMobileView = window.innerWidth < 768;
  useEffect(() => {
    console.log("reloading form submission page ---> ");
  }, [currentPageNo]);

  return (
    <>
      <div
        style={{
          width: "100%",
          marginBottom: "20px",
        }}
      ></div>
      {data && (
        <Table
        rowClassName={rowClassName}
          showHeader={true}
          tableLayout="auto"
          columns={columns}
          rowSelection={rowSelectionTable}
          dataSource={data}
          bordered={true}
          scroll={{ x:'100%',y:480 }} 
          pagination={{
            position: ["bottomCenter"],
            defaultPageSize: 10,
            current: currentPageNo,
          }}
          // scroll={{ x: 1500, y: 300 }}
          size="large"
          onChange={(e) => {
            setCurrentPageNo(e.current);
          }}
        />
      )}
    </>
  );
};

export default TableForDesktop;
