import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

interface IProps {
  value?: string;
  onChange?: (value: string) => void;
}

const CustomPhoneInput = (props: IProps) => {
  return (
    <PhoneInput
      enableSearch
      value={props.value}
      onChange={(value) => props.onChange!(value)}
      inputStyle={{
        width: "99%",
      }}
    />
  );
};

export default CustomPhoneInput;
