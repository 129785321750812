import React from "react";

import { Col, Input, Row, Button, Form, Card } from "antd";
import { LockOutlined } from "@ant-design/icons";

import Title from "antd/lib/typography/Title";

const ChangePasswordForm = (props: any) => {
  const {
    onFinish,
    onFinishFailed,
    password1,
    password2,
    setPassword1,
    setPassword2,
    apiError,
  } = props;

  const styles = { width: "100%" };
  return (
    <Card
      title={<Title level={4}>Change Password</Title>}
      style={{
        paddingBottom: "0px",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "20px",
          paddingBottom: "0px",
          width: "100%",
        }}
      >
        <Form
          name="basic"
          initialValues={{ pxember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          style={{
            width: "100%",
          }}
        >
          <Row>
            <Col style={{ width: "100%" }}>
              <Form.Item
                label={password1 ? "Enter Password:" : ""}
                rules={[
                  { required: true, message: "Please input your Password!" },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="Enter Password"
                  onChange={(e) => {
                    setPassword1(e.target.value);
                  }}
                  maxLength={50}
                  value={password1}
                  style={styles}
                />
              </Form.Item>
            </Col>

            <Col style={{ width: "100%" }}>
              <Form.Item
                label={password2 ? "Enter Password again:" : ""}
                rules={[
                  { required: true, message: "Please re-enter your Password!" },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Enter Password again"
                  onChange={(e) => {
                    setPassword2(e.target.value);
                  }}
                  maxLength={50}
                  value={password2}
                  style={styles}
                />
              </Form.Item>
            </Col>

            {apiError && apiError.length > 0 && (
              <Col style={{ width: "100%", maxWidth: "100%" }}>
                <Form.Item
                  name="pxember"
                  valuePropName="checked"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  {apiError.map((err: any) => (
                    <p style={{ color: "red", padding: "2px", margin: "0px" }}>
                      {err}
                    </p>
                  ))}
                </Form.Item>
              </Col>
            )}
          </Row>

          <div
            style={{
              paddingBottom: "40px",
            }}
          >
            <Button
              className="ct_forms_primary_button"
              type="primary"
              htmlType="submit"
            >
              Save Details
            </Button>
          </div>
        </Form>
      </div>
    </Card>
  );
};

export default ChangePasswordForm;
