import React, { useEffect, useState } from "react";

import axios from "axios";
import moment from "moment";
import { Button, Form, message, Space, Spin } from "antd";

import ElementCard from "./ElementCard";
import {
  getAllClientDetails,
  getClientDeviceDetails,
} from "../../../auth/core/services/clientInfo";

const QuestionStep = (props: any) => {
  const {
    totalElements,
    setCurrentIndex,
    currentIndex,
    currentElement,
    disableSubmitButton,
    formUuid,
    element,
    userData,
    setFormSubmitSuccess,
  } = props;
  const [submissionId, setSubmissionId] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    props.handleSentConsent();
  }, []);

  const isFirstElement = currentIndex === 0;
  const isLastElement = currentIndex === totalElements - 1;

  const handleNext = () => {
    setCurrentIndex(currentIndex + 1);
  };

  const handlePrevious = () => {
    setCurrentIndex(currentIndex - 1);
  };

  let baseUrl = `${process.env.REACT_APP_SERVER_URL}/api/${formUuid}/submit/`;

  const finishSubmission = async (submissionId: string) => {
    if (!submissionId) {
      setFormSubmitSuccess(true);
      return;
    }
    const url = baseUrl + `done/?submission_id=${submissionId}`;
    if (!loading) setLoading(true);
    const deviceDetails: any = await getClientDeviceDetails();
    const clientDetails: any = await getAllClientDetails();
    axios
      .post(url, {
        metadata: {
          form_submitter_email: userData?.email,
          ...deviceDetails,
          ...clientDetails,
        },
      })
      .then((response) => {
        console.log(`response`, response);
        setFormSubmitSuccess(true);
      })
      .catch((error) => {
        console.log(`error`, error);
        message.error("Error in final submission");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const clearResponse = (elementUuid: string) => {
    if (!loading) setLoading(true);

    const url = `${baseUrl}?submission_id=${submissionId}&element_uuid=${elementUuid}`;

    axios
      .delete(url,{headers: {
          Authorization:
           
            `Bearer ${localStorage.getItem("tempAccessToken")}`,
        },})
      .then((res) => {
        if (isLastElement) {
          finishSubmission(submissionId);
        } else {
          handleNext();
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(`err`, err);
        message.error("Error in submission");
        setLoading(false);
      });
  };

  const handleSubmit = async (formSubmissionData: any) => {
    const answer = Object.values(formSubmissionData)[0];
    if (answer === "" || answer === undefined || answer === null) {
      if (!submissionId) {
        if (isLastElement) {
          finishSubmission(submissionId);
        } else {
          handleNext();
          setLoading(false);
        }
        return;
      } else {
        const elementUuid = Object.keys(formSubmissionData)[0];
        clearResponse(elementUuid);
        return;
      }
    }

    for (let key in formSubmissionData) {
      if (formSubmissionData[key] === "") {
        if (element.elementType === "Switch") {
          formSubmissionData[key] = false;
        } else {
          delete formSubmissionData[key];
        }
      } else if (typeof formSubmissionData[key] === "number") {
        formSubmissionData[key] = String(formSubmissionData[key]);
      } else if (moment.isMoment(formSubmissionData[key])) {
        const newKey = key.split("_");
        if (newKey[1] === "date") {
          const dateToSend = formSubmissionData[key].format("YYYY-MM-DD");
          formSubmissionData[newKey[0]] = dateToSend;

          delete formSubmissionData[key];
        } else if (newKey[1] === "time") {
          const timeToSend = formSubmissionData[key].format("HH:mm");
          formSubmissionData[newKey[0]] = timeToSend;
          delete formSubmissionData[key];
        }
        console.log("date sent-> ", newKey, key, formSubmissionData[key]);
      }
    }
    let url = baseUrl;
    if (submissionId) {
      url = baseUrl + `?submission_id=${submissionId}`;
    }
    setLoading(true);
    const mode = localStorage.getItem("tempAccessToken") ? "anon" : "user";

    axios
      .post(url, formSubmissionData, {
        headers: {
          Authorization:
            mode === "anon" &&
            `Bearer ${localStorage.getItem("tempAccessToken")}`,
        },
      })
      .then((response) => {
        console.log(`response`, response);
        if (response.data.submission_id) {
          setSubmissionId(response.data.submission_id);
        }
        if (isLastElement) {
          finishSubmission(response.data.submission_id);
        } else {
          handleNext();
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(`error`, error);
        message.error("Error in submission");
        setLoading(false);
      });
  };

  const handleFinishFailed = (submissionError: any) => {
    console.log(`submissionError`, submissionError);
  };

  return (
    <div>
      <></>
      <div style={{ textAlign: "left" }}>
        <h3 style={{ opacity: 0.75 }}>Total Questions: {totalElements}</h3>
      </div>
      <Form
        layout="vertical"
        onFinish={handleSubmit}
        onFinishFailed={handleFinishFailed}
        style={{ textAlign: "left" }}
      >
        <ElementCard currentElement={currentElement} element={element} />
        {loading ? (
          <Spin />
        ) : (
          <Space>
            {!isFirstElement && (
              <Button
                className="ct_forms_secondary_button"
                disabled={disableSubmitButton}
                onClick={handlePrevious}
              >
                Previous
              </Button>
            )}
            <Button
              className="ct_forms_primary_button"
              type="primary"
              htmlType="submit"
              disabled={disableSubmitButton}
            >
              {isLastElement ? "Submit" : "Next"}
            </Button>
          </Space>
        )}
      </Form>
    </div>
  );
};

export default QuestionStep;
