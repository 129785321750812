import React from "react";

import { InputNumber, Switch, Form } from "antd";
import { IElement } from "../../utils/interfaces";
import AdvanceOptionsDrawer from "./ElementPreview/AdvanceOptionsDrawer";
import FormItem from "./ElementPreview/FormItem";

interface IProps {
  value?: string;
  placeholder?: string;
  style?: object;
  max_length?: number;
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  dispatch: ({ type, payload }: { type: string; payload: object }) => void;
  state: IElement;
  componentDisabled?: boolean;
  updateFieldValue: (fieldValue: object) => void;
  deleteButton: React.ReactNode;
  extraProps: React.ReactNode;
}

export default function CTRatings(props: IProps) {
  if (!props.state.plugin_data) return null;
  const advancedOptions = (
    <>
      <div style={{ display: "flex", flexFlow: "wrap", alignItems: "center" }}>
        <FormItem label="Number of stars" elementStyle={{ width: "100%" }}>
          <InputNumber
            min={1}
            max={10}
            value={props.state.plugin_data.max_value || 10}
            disabled={props.componentDisabled}
            onChange={(value) => {
              if (value !== null)
                props.updateFieldValue({ max_value: parseInt(value) });
            }}
          />
        </FormItem>
        <FormItem
          label={"Allow Half Stars"}
          elementStyle={{
            marginLeft: "5px",
            background: props.state.plugin_data.halfAllowed
              ? "#FF6E30"
              : "#969DB1",
          }}
        >
          <Switch
            defaultChecked={props.state.plugin_data.halfAllowed || false}
            disabled={props.componentDisabled}
            onChange={(value) => props.updateFieldValue({ halfAllowed: value })}
          />
        </FormItem>
      </div>
    </>
  );
  return (
    <AdvanceOptionsDrawer
      componentDisabled={props.componentDisabled}
      deleteButton={props.deleteButton}
      advancedOptions={advancedOptions}
      state={props.state}
      extraProps={props.extraProps}
    />
  );
}
