import React, { useEffect, useState } from "react";

import { Card, message } from "antd";
import axios from "axios";

import CategoryToTemplate from "./CategoryToTemplate";

const TemplateCategories = (props: any) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [category, setCategory] = useState<any>();

  const { formData } = props;

  const [initialCategory, setInitialCategory] = useState<any>();

  const getAllTags = async () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/base/categories/`;

    await axios
      .get(url)
      .then((result) => {
        setCategory(result.data.data);
        return result;
      })
      .then((result: any) => {
        updateInitialCategory(result.data.data);
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Could not get form data.");
        setLoading(false);
      });
  };

  const updateInitialCategory = async (category: any) => {
    const localData: any = [];
    if (
      formData &&
      formData.categories.length > 0 &&
      category &&
      category.length > 0
    ) {
      for (let i = 0; i < formData.categories.length; i++) {
        for (let j = 0; j < category.length; j++) {
          if (formData.categories[i].category === category[j].id) {
            const dataToPush: any = {};
            dataToPush.label = category[j].name;
            dataToPush.name = category[j].name;
            dataToPush.key = parseInt(category[j].id);
            dataToPush.value = parseInt(category[j].id);
            localData.push(dataToPush);
          }
        }
      }
      setInitialCategory(await localData);
    } else {
      setInitialCategory([]);
    }
  };

  useEffect(() => {
    getAllTags();
  }, []);

  return (
    <Card title={<span>Categories </span>} style={{ margin: "10px" }}>
      <CategoryToTemplate
        category={category}
        initialCategory={initialCategory}
      />
    </Card>
  );
};

export default TemplateCategories;
