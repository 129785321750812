import React from "react";

import { Switch } from "antd";

interface IProps {
  onChange?: any;
  value?: boolean;
  switchValue: boolean;
  setSwitchValue: any;
}

function CustomSwitch(props: IProps) {
  React.useEffect(() => {
    props.onChange(props.switchValue);
  }, [props]);

  const handleChange = (value: boolean) => {
    props.setSwitchValue(value);
    props.onChange(props.switchValue);
  };

  return <Switch onChange={handleChange} checked={props.switchValue} />;
}

export default CustomSwitch;
