import React, { useEffect, useState } from "react";

import { Layout, Card, Typography, message, Divider } from "antd";

import axios, { AxiosError } from "axios";
import { connect, useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { AnyAction } from "redux";

import FormSubmitFields from "../../components/formSubmission/FormSubmitFields";

import { LogoutUser } from "../../../auth/core/redux/actions";

import THEMECOLORS from "../../../shared/theme/themeColors";

import moment from "moment";
import FormSubmitSuccess from "./FormSubmitSuccess";
import MDEditor from "@uiw/react-md-editor";
import CompanyFooter from "../../components/companyFooter/CompanyFooter";
import ElementSpinner from "../../../shared/customComponents/spinner/ElementSpinner";
import { CONSENT_TYPES } from "../../../shared/constants/constants";
import {
  getClientDeviceDetails,
  getClientIP,
} from "../../../auth/core/services/clientInfo";

const { Text, Title } = Typography;

interface Iproof {
  name: string;
  version: string;
  id?: number;
  url: string;
}

const FormSubmitIframe = (props: any) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { pathname } = useLocation();

  const [redirectTo, setRedirectTo] = useState<string>();

  const [userData, setUserData] = useState<any>();

  const [formData, setFormData] = useState<any>();

  const [formCreatorData, setFormCreatorData] = useState<any>();

  const [elementData, setElementData] = useState<any>();

  const [formSubmitSuccess, setFormSubmitSuccess] = useState<boolean>(false);

  const [isLogoPresent, setIsLogoPresent] = useState<boolean>(false);

  const [isCompanyNamePresent, setIsCompanyNamePresent] =
    useState<boolean>(false);

  const [oriProofs, setOriProofs] = useState<Iproof[]>([]);

  const params = useParams<any>();

  const { uuid } = params;

  const getFormByUuid = async (setLoading: any) => {
    setLoading(true);

    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/custom_forms/${uuid}/`)
      .then(async (res) => {
        console.log("---- res get form by uuid ---- ", res);
        await setFormData(res.data);

        await setFormCreatorData(res.data.user);

        await getElementsByFormUuid(setLoading, res.data);
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Could not get Form data.");
        setLoading(false);
      });
  };

  const getAllProofs = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/${uuid}/proofs/`, {
        headers: {
          Authorization:
            mode === "anon" &&
            `Bearer ${localStorage.getItem("tempAccessToken")}`,
        },
      })
      .then((res) => {
        console.log("---- res in get proofs---- ", res);
        setOriProofs(res.data.data);
        // setLoading(false);
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        setLoading(false);
      });
  };

  const getElementsByFormUuid = async (setLoading: any, formData: any) => {
    if (!loading) setLoading(true);
    console.log("*********** FETCHING FORM ELEMENTS ********************");
    const getS3FilePath = async (media_key: string, field: any) => {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/base/get-s3-url/?key=${media_key}`
        );
        console.log(`result from AWS`, result);
        if (field.questionImageKey) {
          field.questionImageUrl = result.data.url;
        } else {
          field.imageUrl = result.data.url;
        }
      } catch (error) {
        console.log("errors could not be get", error);
        message.error("Could not get form response data.");
      }
    };

    if (formData) {
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/${uuid}/elements/?size=50`
        )
        .then((result1) => {
          console.log("---- result from get elements API ---- ", result1);

          const elementData = result1.data.data;

          console.log("element Data ", elementData);

          let dictionary;
          dictionary = Object.assign(
            {},
            ...elementData.map((x: any) => ({
              [x.plugin_data.name]: x,
            }))
          );
          const promises = [];

          for (var element of elementData) {
            element.label = element.metadata.elementType;
            element.uuid = element.plugin_data.name;

            if (element.metadata.hasOwnProperty("questionImageKey")) {
              element.questionImageKey = element.metadata.questionImageKey;
              promises.push(getS3FilePath(element.questionImageKey, element));
            }
            if (element.metadata.hasOwnProperty("questionImageSize")) {
              element.questionImageSize = element.metadata.questionImageSize;
            }
            if (["Checkbox", "Radio"].includes(element.metadata.elementType)) {
              for (const choice of element.plugin_data.choices) {
                if (choice.imageKey) {
                  promises.push(getS3FilePath(choice.imageKey, choice));
                }
              }
            }
          }

          const allPromises = Promise.all(promises).then((values) => {
            setLoading(false);
          });

          console.log("converted dictionary ", dictionary);

          const newData = formData;

          newData.elements = dictionary;
          newData.elementIds = Object.values(newData.elements);

          setFormData(newData);
        })
        .catch((err: any) => {
          console.log("errors could not be get", err);
          message.error("Could not get form elements.");
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getFormByUuid(setLoading);
    getAllProofs();
  }, []);

  const handleFormSubmit = async (formSubmissionData: any) => {
    for (let key in formSubmissionData) {
      if (formSubmissionData[key] === "") {
        delete formSubmissionData[key];
      } else if (typeof formSubmissionData[key] === "number") {
        formSubmissionData[key] = String(formSubmissionData[key]);
      } else if (moment.isMoment(formSubmissionData[key])) {
        const newKey = key.split("_");

        if (newKey[1] === "date") {
          const dateToSend = formSubmissionData[key].format("YYYY-MM-DD");
          formSubmissionData[newKey[0]] = dateToSend;

          delete formSubmissionData[key];
        } else if (newKey[1] === "time") {
          const timeToSend = formSubmissionData[key].format("HH:mm");
          formSubmissionData[newKey[0]] = timeToSend;
          delete formSubmissionData[key];
        }
        console.log("date sent-> ", newKey, key, formSubmissionData[key]);
      }
    }

    console.log("sent data -->", formSubmissionData);

    const formSubmissionPayload = {
      ...formSubmissionData,
    };

    if (userData && userData.email) {
      formSubmissionPayload.metadata = { form_submitter_email: userData.email };
    }

    setLoading(true);
    message.info("Submitting form...");

    console.log("Submitting form... ---->", formSubmissionPayload);
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/${uuid}/saved-data/`,
        formSubmissionPayload,
        {
          headers: {
            Authorization:
              mode === "anon" &&
              `Bearer ${localStorage.getItem("tempAccessToken")}`,
          },
        }
      )
      .then((res) => {
        console.log(`Form submission res.data`, res.data);
        message.success("Form submitted successfully");
        setFormSubmitSuccess(true);
        handleSentConsent();
        redirectToDashboard();
      })
      .catch((err: AxiosError) => {
        console.log("Error while submitting form", err);
        console.log("Error data", err?.response?.data);
        message.error("Error while submitting form");
      });
  };

  const handleSentConsent = async () => {
    let consentProofs: { data: any };

    let consentDataToSent: any;

    const clientDetails = getClientDeviceDetails();

    const ipAddress = await getClientIP();

    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}​/api/users/consent/proofs/`, {
        headers: {
          Authorization:
            mode === "anon" &&
            `Bearer ${localStorage.getItem("tempAccessToken")}`,
        },
      })
      .then(async (response: any) => {
        consentProofs = await response.data.data.map((response: any) => {
          response.version = response.latest_version;
          return response;
        });

        console.log("consent proofs -----> ", consentProofs);

        consentDataToSent = {
          ip_address: await ipAddress,
          consent_type: CONSENT_TYPES.SUBMISSION_CT_FORMS,
          proofs: consentProofs,
          ...clientDetails,
        };

        // submit consent of ct-forms
        submitConsent(consentDataToSent);
      })
      .catch((err) => console.log("--- erro", err));
  };

  const submitConsent = async (consentDataToSent: any) => {
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}​/api/users/consent/`,
        consentDataToSent,
        {
          headers: {
            Authorization:
              mode === "anon" &&
              `Bearer ${localStorage.getItem("tempAccessToken")}`,
          },
        }
      )
      .then((response: any) => {
        console.log(
          "----> Response from submit consent  ----> ",
          response.data
        );
        // consentProofs = response.data;
      })
      .catch((err) => console.log("--- erro", err));

    if (oriProofs.length > 0) {
      consentDataToSent.proofs = oriProofs;
      consentDataToSent.consent_type = CONSENT_TYPES.SUBMISSION_USER_DEFINED;

      await axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}​/api/users/consent/`,
          consentDataToSent
        )
        .then((response: any) => {
          console.log(
            "----> Response from submit consent  ----> ",
            response.data
          );
          // consentProofs = response.data;
        })
        .catch((err) => console.log("--- erro", err));
    }
  };

  const handleFinishFailed = (errorInfo: any) => {
    console.log("Form submission failed", errorInfo);
  };

  const redirectToDashboard = () => {
    setTimeout(() => {
      setRedirectTo("dashboard");
      setLoading(false);
    }, 3 * 1000);
  };

  console.log("USER DATA --->? ", userData);

  useEffect(() => {
    if (
      formCreatorData &&
      formCreatorData.profile_picture &&
      formCreatorData.profile_picture.thumbnail_large
    ) {
      setIsLogoPresent(true);
    }

    if (formCreatorData && formCreatorData && formCreatorData.company_name) {
      setIsCompanyNamePresent(true);
    }
  }, [formCreatorData]);

  const [mode, setMode] = useState<any>("user");

  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      if (localStorage.getItem("tempAccessToken")) {
        setMode("anon");
      } else {
        registerAnonymousUser();
      }
    }
  }, []);

  const registerAnonymousUser = async () => {
    setLoading(true);

    await axios
      .post(`${process.env.REACT_APP_SERVER_URL}/auth/registration/`, {})
      .then(async (response) => {
        console.log("response of axios", response.data);

        const { access_token: accessToken, refresh_token: refreshToken } =
          response.data;

        localStorage.setItem("tempAccessToken", accessToken);
        localStorage.setItem("tempRefreshToken", refreshToken);

        setMode("anon");

        message.success("Anonymous User Registeration successful.");

        setLoading(false);
      })
      .catch((err: any) => {
        console.log("error in axios API  -> ", err.response.data);
        setLoading(false);
      });
  };

  return (
    <ElementSpinner loading={loading}>
      {formSubmitSuccess ? (
        <FormSubmitSuccess />
      ) : (
        <>
          <Layout
            style={{
              maxWidth: "1000px",
              width: "100%",
              overflow: "none",
              paddingTop: "20px",
              backgroundColor: "white",
            }}
          >
            <>
              {formData && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Layout
                    style={{
                      maxWidth: "800px",
                      paddingTop: "20px",
                      padding: "20px",
                      backgroundColor: "white",
                    }}
                  >
                    <Card
                      style={{
                        textAlign: "left",
                        paddingBottom: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      <Title
                        level={2}
                        style={{
                          color: THEMECOLORS.fontColorPrimary,
                        }}
                      >
                        {formData.title}
                      </Title>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <MDEditor.Markdown source={formData.description} />
                      </div>
                    </Card>

                    {formData && formData.elements && (
                      <FormSubmitFields
                        formData={formData}
                        handleFormSubmit={handleFormSubmit}
                        handleFinishFailed={handleFinishFailed}
                        oriProofs={oriProofs}
                        addProofs={formData.add_proofs}
                        userData={userData}
                        handleSentConsent={handleSentConsent}
                        setFormSubmitSuccess={setFormSubmitSuccess}
                      />
                    )}
                    <CompanyFooter />
                  </Layout>
                </div>
              )}
            </>
          </Layout>
          {redirectTo && <Redirect to={`/${redirectTo}/`} />}
        </>
      )}
    </ElementSpinner>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    //
  };
};

const mapStateToProps = (state: any) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormSubmitIframe);
